import {
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { PasswordField } from "components/auth/PasswordField";
import { GeoComplyContext, GeoContextType } from "context/geoComplyContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link as RouteLink, useHistory } from "react-router-dom";
import APIService from "services/API";
import { UserLogin } from "../../models";
import macOS from "./images/macOS.svg";
import windows from "./images/windows.svg";
import { CA, CM, US } from "country-flag-icons/react/3x2";
import { FaChevronDown, FaChevronLeft } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import ReCaptcha from "./ReCaptcha";
import { languageState, orgState } from "store/Store";
import { useRecoilValue } from "recoil";
import { strings, LanguageKey } from "utils/languageStrings";

interface LoginFormProps {
  admin: boolean;
  validateLoginToken?: boolean;
}

export const LoginForm = (props: LoginFormProps) => {
  const {
    getGeoComply,
    geoComplyData,
    geoComplyError,
    geoLocationRetrieved,
    setGeoComplyData,
    tempJwtToken,
    setTempJwtToken,
  }: GeoContextType = GeoComplyContext();
  const history = useHistory();
  const toast = useToast();
  const [downloadModal, setDownloadModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState<string>("");
  const [emailInput, setEmailInput] = useState(true);
  const [countryCode, setCountryCode] = useState("+237");
  const [country, setCountry] = useState("CM");
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const org = useRecoilValue(orgState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>("");
  const [recaptchaSuccessful, setRecaptchaSuccessful] = useState(false);
  const { register, handleSubmit, setValue } = useForm<UserLogin>({
    mode: "onChange",
  });

  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [recaptchaEnabled, setRecaptchaEnabled] = useState(false);

  useEffect(() => {
    parseInt(process.env.REACT_APP_MFA_ENABLED!) === 1
      ? setMfaEnabled(true)
      : setMfaEnabled(false);
    parseInt(process.env.REACT_APP_RECAPTCHA_ENABLED!) === 1
      ? setRecaptchaEnabled(true)
      : setRecaptchaEnabled(false);
  }, []);

  const onSubmit = (data: UserLogin) => {
    setIsLoading("Logging in...");
    let userLogin: UserLogin = {
      password: "",
    };

    if (emailInput) {
      userLogin = {
        id: data.id,
        password: data.password,
      };
    } else {
      userLogin = {
        phoneNo: countryCode + data.phoneNo,
        password: data.password,
      };
    }

    if (mfaEnabled) {
      mutateMFA(userLogin);
    } else if (recaptchaEnabled) {
      const userLoginRecaptcha = {
        ...userLogin,
        recaptcha_token: recaptchaToken,
      };
      mutate(userLoginRecaptcha);
    } else {
      mutate(userLogin);
    }
  };

  /* MFA Disabled */
  const { mutate } = useMutation(APIService.login, {
    onSuccess: (data) => {
      setTempJwtToken(data.access_token);
      setIsLoading("Checking Location...");
      if (!!parseInt(process.env.REACT_APP_ENABLE_GEO!)) {
        checkLocation();
      } else {
        completeLogin(data.access_token);
      }
    },
    onError: () => {
      console.log("error");
      setIsLoading("");

      toast({
        title: "Error",
        description: "Invalid email/password",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setValue("password", "");
    },
  });

  /* MFA Enabled */
  const { mutate: mutateMFA } = useMutation(APIService.loginMFA, {
    onSuccess: (data) => {
      setIsLoading("Checking Location...");
      if (!!parseInt(process.env.REACT_APP_ENABLE_GEO!)) {
        if (data.access_token) {
          setTempJwtToken(data.access_token);
          checkLocation();
        } else {
          history.push({
            pathname: "/mfa",
            state: data,
          });
        }
      } else if (data.role) {
        completeLogin(data.access_token);
      } else {
        history.push({
          pathname: "/mfa",
          state: data,
        });
      }
    },
    onError: () => {
      console.log("error");
      setIsLoading("");

      toast({
        title: "Error",
        description: "Invalid email/password",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setValue("password", "");
    },
  });

  const completeLogin = (access_token?: string) => {
    setIsLoading("");
    window.localStorage.loggedIn = true;
    window.localStorage.loginTime = new Date();
    window.localStorage.jwt = access_token ? access_token : tempJwtToken;
    if (!props.validateLoginToken) {
      history.push("/");
    }
    window.location.reload();
    setTempJwtToken("");
  };

  const checkLocation = () => {
    console.log("Checking location...");
    getGeoComply("Login", setDownloadModal);
    // Get geo comply will have 20 seconds to provide a response,
    // Otherwise the error toast will be displayed
    const setTimeoutId = setTimeout(() => {
      if (!geoComplyData) {
        console.log("geocomply error msg: ", geoComplyError);
        toast({
          title: "Error",
          description: geoComplyError
            ? `Invalid region: ${geoComplyError}`
            : "Timeout getting geo-packet",
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
        setIsLoading("");
      }
    }, 20000);
    setTimeoutID(setTimeoutId);
  };

  useEffect(() => {
    /** Use effect catches geoComplyData when location check is complete.
     * Timeout is cleared, and navigation is approved
     *  */
    if (geoComplyData) {
      timeoutID && clearTimeout(timeoutID);
      completeLogin();
      setGeoComplyData(null);
    }
  }, [geoComplyData]);

  useEffect(() => {
    /** Use effect catches geoLocationRetrieved when location check is complete
     * If location is invalid timeout is cleared, and error toast is displayed
     */
    if (geoLocationRetrieved && geoComplyError !== "" && !geoComplyData) {
      timeoutID && clearTimeout(timeoutID);
      setIsLoading("");
      toast({
        title: "Error",
        description: geoComplyError
          ? `Invalid region: ${geoComplyError}`
          : "Timeout getting geo-packet",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });
    }
  }, [geoLocationRetrieved]);

  var countryIcon: JSX.Element = <CM width="20px" height="30px" />;

  /* Country selector */
  switch (country) {
    case "CM":
      countryIcon = <CM width="20px" height="30px" />;
      break;
    case "CA":
      countryIcon = <CA width="20px" height="30px" />;
      break;
    case "US":
      countryIcon = <US width="20px" height="30px" />;
      break;

    default:
      countryIcon = <CM width="20px" height="30px" />;
      break;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="6" px="2">
          <FormErrorMessage>{errorMessage}</FormErrorMessage>

          {emailInput ? (
            <FormControl id="email" isInvalid={errorMessage != ""}>
              <InputGroup>
                <Input
                  required
                  tabIndex={1}
                  outline="1px solid lightgray"
                  h="53px"
                  placeholder={strings[language].login.emailPlaceholder}
                  _placeholder={{ opacity: 1, color: "gray.500" }}
                  color="black"
                  backgroundColor="#FAFAFA"
                  {...register("id")}
                />
                <InputRightAddon
                  h="53px"
                  backgroundColor="#FAFAFA"
                  outline="1px solid lightgray"
                  cursor="pointer"
                  children={
                    <>
                      <Text mr={2}>
                        <FaChevronLeft />
                      </Text>
                      <BsFillTelephoneFill />
                    </>
                  }
                  onClick={() => setEmailInput(false)}
                />
              </InputGroup>
            </FormControl>
          ) : (
            <FormControl id="phone" isInvalid={errorMessage != ""}>
              <Flex>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<FaChevronDown />}
                    h="53px"
                    backgroundColor="#FAFAFA"
                    borderEndRadius="none"
                    outline="1px solid lightgray"
                  >
                    <Text mr={2}>{countryIcon}</Text>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setCountryCode("+1");
                        setCountry("US");
                      }}
                    >
                      <US width="20px" height="30px" />
                      <Text mx={2}>United States (+1)</Text>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setCountryCode("+1");
                        setCountry("CA");
                      }}
                    >
                      <CA width="20px" height="30px" />
                      <Text mx={2}>Canada (+1)</Text>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setCountryCode("+237");
                        setCountry("CM");
                      }}
                    >
                      <CM width="20px" height="30px" />
                      <Text mx={2}>Cameroon (+237)</Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
                <InputGroup>
                  <InputLeftElement
                    pt="13px"
                    color="black"
                    children={countryCode}
                  />
                  <Input
                    required
                    tabIndex={1}
                    type="tel"
                    outline="1px solid lightgray"
                    borderRadius="none"
                    h="53px"
                    placeholder={strings[language].profile.phoneNumber}
                    _placeholder={{ opacity: 1, color: "gray.500" }}
                    color="black"
                    backgroundColor="#FAFAFA"
                    {...register("phoneNo")}
                  />
                  <InputRightAddon
                    h="53px"
                    backgroundColor="#FAFAFA"
                    outline="1px solid lightgray"
                    cursor="pointer"
                    children={
                      <>
                        <Text mr={2}>
                          <FaChevronLeft />
                        </Text>
                        <MdEmail />
                      </>
                    }
                    onClick={() => setEmailInput(true)}
                  />
                </InputGroup>
              </Flex>
            </FormControl>
          )}
          <PasswordField minLength={6} {...register("password")} />
          {recaptchaEnabled && (
            <Center>
              <ReCaptcha
                recaptchaToken={recaptchaToken}
                setRecaptchaToken={setRecaptchaToken}
                recaptchaSuccessful={recaptchaSuccessful}
                setRecaptchaSuccessful={setRecaptchaSuccessful}
              />
            </Center>
          )}
          <HStack>
            <Spacer />
            <Link
              mb="2"
              maxW="md"
              fontWeight="medium"
              display="inline"
              justifyContent="end"
              color={org?.primaryColor}
              opacity="0.6"
              textDecoration="underline"
              as={RouteLink}
              to="/forgot-password"
            >
              {strings[language].login.forgotPassword}
            </Link>
          </HStack>
          <Button
            type="submit"
            colorScheme="blue"
            color="white"
            h="53"
            size="lg"
            fontSize="md"
            bgColor={org?.primaryColor}
            tabIndex={4}
            isLoading={!!isLoading}
            loadingText={isLoading}
            isDisabled={!recaptchaSuccessful && recaptchaEnabled}
          >
            {strings[language].navbar.login}
          </Button>
        </Stack>
      </form>
      <Modal isOpen={downloadModal} onClose={() => setDownloadModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader textAlign="center">
            No Location Software Detected
          </ModalHeader>
          <Divider mb="10px" />
          <ModalBody textAlign="center">
            <Text>
              We've detected you do not have the required location software to
              run this application. Please install the downloaded software and
              refresh to try again.
            </Text>
            <Spacer h="20px" />
            <Text>
              If the download did not automatically start, please click on your
              OS below to begin the download.
            </Text>
            <Spacer h="20px" />
            <Flex justify={"space-around"}>
              <Flex
                direction={"column"}
                _hover={{ opacity: "0.6" }}
                transition="0.2s"
                onClick={() => {
                  window.open(
                    "https://stg-ums.geocomply.net/installer/url?id=" +
                      process.env.REACT_APP_GEOCOMPLY_INSTALLER_ID +
                      "&os=win&version=" +
                      "3.1.1.3",
                    "_self"
                  );
                }}
              >
                <Image src={windows} cursor="pointer" />
                <Text fontSize={"12px"} mt="3px">
                  Windows
                </Text>
              </Flex>
              <Flex
                direction={"column"}
                transition="0.2s"
                _hover={{ opacity: "0.6" }}
                onClick={() => {
                  window.open(
                    "https://stg-ums.geocomply.net/installer/url?id=" +
                      process.env.REACT_APP_GEOCOMPLY_INSTALLER_ID +
                      "&os=mac&version=" +
                      "3.1.1.3",
                    "_self"
                  );
                }}
              >
                <Image src={macOS} cursor="pointer" />
                <Text fontSize={"12px"} mt="3px">
                  Mac OS
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button w="100%" onClick={() => history.push("/")}>
              Refresh Page
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
