import {
  Box,
  Center,
  Flex,
  Link,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BetSlip } from "components/dashboard/betslip/BetSlip";
import { MobileBetSlip } from "components/dashboard/betslip/MobileBetSlip";
import { Footer } from "components/shared/Footer";
import { Bet, OddsChangeData } from "models/Bets";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { strings, LanguageKey } from "../../utils/languageStrings";
import {
  fantasyBetsStore,
  fantasyEnabledState,
  languageState,
  orgState,
} from "store/Store";
import OddsChangeModal from "components/dashboard/betslip/OddsChangeModal";
import { FantasyBetSlip } from "components/fantasy/FantasyBetSlip";
import { FantasyMobileBetSlip } from "components/fantasy/FantasyMobileBetSlip";

interface HelpProps {
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

export const Help = (props: HelpProps) => {
  const org = useRecoilValue(orgState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const fantasyEnabled = useRecoilValue(fantasyEnabledState);
  const fantasyBets = useRecoilValue(fantasyBetsStore);
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const [bets, setBets] = useState<Bet[]>([]);
  const [oddsChangeData, setOddsChangeData] = useState<OddsChangeData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Get current selected bets
  var betArr: [];
  var stringBets = window.localStorage.getItem("bets");
  stringBets !== null ? (betArr = JSON.parse(stringBets!)) : (betArr = []);

  const { mutate: placeBet } = useMutation(API.placeBet, {
    onSuccess: (data) => {
      setBets([]);

      toast({
        title: `Bet successfully placed!`,
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
    onError: (error: any) => {
      if (error.response.status == "409") {
        setOddsChangeData(error.response.data);
        onOpen();
      }

      toast({
        title: `Error placing bet`,
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
  });

  useEffect(() => {
    setBets(betArr);
  }, []);

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Box
          h="100vh"
          style={{ position: "relative", overflowY: "auto" }}
          width={{ base: "100%", lg: "calc(200% - 335px)" }}
        >
          <Box minH="100vh">
            <Box p="4" pt="0">
              <Flex
                pl="4"
                mx="-4"
                alignItems="center"
                h="5.2rem"
                boxShadow="inset 0px 4px 4px #00000029, 0px 4px 4px #00000029"
              >
                <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                  {strings[language].navbar.help}
                </Text>
              </Flex>
            </Box>

            <Center>
              <Box p="6" maxWidth="1200px" width="100%" mt="20">
                <Box p="8" backgroundColor="white">
                  <Text color="black" textAlign={"center"} fontSize="18px">
                    {strings[language].support.support}{" "}
                    <Link
                      href="mailto:support@betbooth.com"
                      _hover={{ textDecoration: "underline" }}
                      color="blue"
                    >
                      support@betbooth.com
                    </Link>
                  </Text>
                </Box>
                {/* <SimpleGrid columns={3} spacing={5}>
            <Box p="8" backgroundColor="gray.800">
              <Link
                display="block"
                href="#"
                color={org?.primaryColor}
                fontWeight="bold"
                fontSize="20"
              >
                Login Problems{" "}
                <BsChevronRight
                  style={{ display: "inline", paddingTop: "4px" }}
                />
              </Link>

              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Username / Password
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Having trouble logging in?
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Multifactor Authentication
              </Link>
            </Box>

            <Box p="8" backgroundColor="gray.800">
              <Link
                display="block"
                href="#"
                color={org?.primaryColor}
                fontWeight="bold"
                fontSize="20"
              >
                Deposits{" "}
                <BsChevronRight
                  style={{ display: "inline", paddingTop: "4px" }}
                />
              </Link>

              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Making a Deposit
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Payment Methods
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Declined Deposit
              </Link>
            </Box>

            <Box p="8" backgroundColor="gray.800">
              <Link
                display="block"
                href="#"
                color={org?.primaryColor}
                fontWeight="bold"
                fontSize="20"
              >
                Account Verification{" "}
                <BsChevronRight
                  style={{ display: "inline", paddingTop: "4px" }}
                />
              </Link>

              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Verifying Documents
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Verification
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Verification Timeframes
              </Link>
            </Box>

            <Box p="8" backgroundColor="gray.800">
              <Link
                display="block"
                href="#"
                color={org?.primaryColor}
                fontWeight="bold"
                fontSize="20"
              >
                Withdrawals{" "}
                <BsChevronRight
                  style={{ display: "inline", paddingTop: "4px" }}
                />
              </Link>

              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Making a Withdrawal
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Withdrawal Timeframes
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Withdrawal Fees
              </Link>
            </Box>

            <Box p="8" backgroundColor="gray.800">
              <Link
                display="block"
                href="#"
                color={org?.primaryColor}
                fontWeight="bold"
                fontSize="20"
              >
                Cash Out{" "}
                <BsChevronRight
                  style={{ display: "inline", paddingTop: "4px" }}
                />
              </Link>

              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                What is Cash Out?
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Auto Cash Out
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Partial Cash Out
              </Link>
            </Box>

            <Box p="8" backgroundColor="gray.800">
              <Link
                display="block"
                href="#"
                color={org?.primaryColor}
                fontWeight="bold"
                fontSize="20"
              >
                Responsible Gambling{" "}
                <BsChevronRight
                  style={{ display: "inline", paddingTop: "4px" }}
                />
              </Link>

              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Overview
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Stay in Control
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Support and Advice
              </Link>
            </Box>

            <Box p="8" backgroundColor="gray.800">
              <Link
                display="block"
                href="#"
                color={org?.primaryColor}
                fontWeight="bold"
                fontSize="20"
              >
                About BetBooth{" "}
                <BsChevronRight
                  style={{ display: "inline", paddingTop: "4px" }}
                />
              </Link>

              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Version 1.0.0
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Terms and Conditions
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Privacy Policy
              </Link>
            </Box>

            <Box p="8" backgroundColor="gray.800">
              <Link
                display="block"
                href="#"
                color={org?.primaryColor}
                fontWeight="bold"
                fontSize="20"
              >
                Further Information{" "}
                <BsChevronRight
                  style={{ display: "inline", paddingTop: "4px" }}
                />
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Wagering Rules and Content
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Player Protection Information
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Wagering Displays and Information
              </Link>
              <Link display="block" href="#" color="white" fontSize="18" mt="2">
                Results Display
              </Link>
            </Box>

            <Box></Box>

            <Box p="8" width="300%" backgroundColor="gray.800">
              <Center>
                <Link
                  display="block"
                  href="#"
                  color={org?.primaryColor}
                  fontWeight="bold"
                  fontSize="20"
                >
                  Need More Help?
                </Link>
              </Center>

              <Center>
                <Flex wrap="wrap">
                  <Center>
                    <Box
                      mt="10"
                      maxWidth="400px"
                      cursor="pointer"
                      borderRadius="6px"
                      p="10"
                      isFullWidth={true}
                      background="gray.800"
                      color="white"
                      fontSize="xx-large"
                      fontWeight="300"
                      border="2px solid white"
                      transition="0.3s"
                      _hover={{
                        background: "white",
                        color: "black",
                        animation: "",
                      }}
                    >
                      <Text>Chat with an Expert</Text>
                      <Center>
                         <Image
                          src="https://cdn-icons-png.flaticon.com/512/1682/1682308.png"
                          width="60px"
                          height="60px"
                        /> 
                      </Center>
                    </Box>
                  </Center>

                  <Center>
                    <Box
                      ml="5"
                      mt="10"
                      maxWidth="400px"
                      cursor="pointer"
                      borderRadius="6px"
                      p="10"
                      isFullWidth={true}
                      background="gray.800"
                      color="white"
                      fontSize="xx-large"
                      fontWeight="300"
                      border="2px solid white"
                      transition="0.3s"
                      _hover={{
                        background: {org?.primaryColor},
                        color: "black",
                        animation: "",
                      }}
                    >
                      <Text>Email Support</Text>
                      <Center>
                        <Image
                          src="https://cdn-icons-png.flaticon.com/512/1682/1682308.png"
                          width="60px"
                          height="60px"
                        />
                      </Center>
                    </Box>
                  </Center>
                </Flex>
              </Center>
            </Box>
          </SimpleGrid> */}
              </Box>
            </Center>
          </Box>

          <Box mb="55px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          left="100%"
          zIndex={1}
          hideBelow="lg"
        >
          {!fantasyEnabled ? (
            <BetSlip
              placeBetLoading={placeBetLoading}
              setPlaceBetLoading={setPlaceBetLoading}
              bets={bets}
              setBets={setBets}
              placeBet={placeBet}
            />
          ) : (
            <FantasyBetSlip
              placeBetLoading={placeBetLoading}
              setPlaceBetLoading={setPlaceBetLoading}
              placeBet={placeBet}
            />
          )}
        </Box>
      </SimpleGrid>
      {!fantasyEnabled ? (
        <MobileBetSlip
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
          collapseSlip={props.collapseSlip}
          setCollapseSlip={props.setCollapseSlip}
        />
      ) : (
        <FantasyMobileBetSlip
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          placeBet={placeBet}
          collapseSlip={props.collapseSlip}
          setCollapseSlip={props.setCollapseSlip}
        />
      )}
      {oddsChangeData && (
        <OddsChangeModal
          isOpen={isOpen}
          onClose={onClose}
          oddsChangeData={oddsChangeData}
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
        />
      )}
    </Box>
  );
};
