import { UserProfileDTO } from "models";
import { BettingLineDTO } from "models/BettingLine";
import { SportDTO } from "models/Sports";
import { atom } from "recoil";
import { LanguageKey } from "../utils/languageStrings";
import { OrgDTO } from "models/Orgs";

export const userState = atom<UserProfileDTO | null>({
  key: "user",
  default: null,
});
export const sportsState = atom<SportDTO[] | null>({
  key: "sports",
  default: null,
});
export const betsState = atom({ key: "bets", default: 0 });
export const loginTime = atom({
  key: "loginTime",
  default: window.localStorage.loginTime,
});
export const leagueIdState = atom({ key: "leagueId", default: 0 });
export const modalHandler = atom({ key: "modalHandler", default: false });
export const tcAccepted = atom<boolean>({ key: "tcAccepted", default: false });
export const languageState = atom<LanguageKey>({
  key: "language",
  default: "en",
});
export const orgState = atom<OrgDTO | null>({ key: "org", default: null });
export const oddsDisplayState = atom({
  key: "oddsDisplay",
  default: "American",
});
export const layoutState = atom({
  key: "layout",
  default: "American",
});
export const denominationSymbolState = atom({
  key: "denominationSymbol",
  default: "$",
});
export const fantasyEnabledState = atom<boolean>({
  key: "fantasyEnabled",
  default: false,
});
export const fantasyBetsStore = atom<BettingLineDTO[]>({
  key: "fantasyBets",
  default: [],
});
