import {
  Box,
  Text,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useMutation } from "react-query";
import API from "services/API";
import { useRecoilValue } from "recoil";
import {
  languageState,
  fantasyBetsStore,
  fantasyEnabledState,
  orgState,
} from "store/Store";
import { strings, LanguageKey } from "../../utils/languageStrings";
import { BetSlip } from "components/dashboard/betslip/BetSlip";
import { Bet, OddsChangeData } from "models/Bets";
import { Footer } from "components/shared/Footer";
import { MobileBetSlip } from "components/dashboard/betslip/MobileBetSlip";
import { Transactions } from "./Transactions";
import OddsChangeModal from "components/dashboard/betslip/OddsChangeModal";
import { FantasyBetSlip } from "components/fantasy/FantasyBetSlip";
import { FantasyMobileBetSlip } from "components/fantasy/FantasyMobileBetSlip";
import { Profile } from "./Profile";
import { DepositMethod } from "./DepositMethod";

interface AccountProps {
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

export const Account = (props: AccountProps) => {
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const [bets, setBets] = useState<Bet[]>([]);
  const language = useRecoilValue<LanguageKey>(languageState);
  const org = useRecoilValue(orgState);
  const fantasyEnabled = useRecoilValue(fantasyEnabledState);
  const fantasyBets = useRecoilValue(fantasyBetsStore);
  const [oddsChangeData, setOddsChangeData] = useState<OddsChangeData>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  // Get current selected bets
  var betArr: [];
  var stringBets = window.localStorage.getItem("bets");
  stringBets !== null ? (betArr = JSON.parse(stringBets!)) : (betArr = []);

  const tabProps = {
    color: "white",
    bg: org?.secondaryColor,
    borderRadius: "md",
  };

  const { mutate: placeBet } = useMutation(API.placeBet, {
    onSuccess: (data) => {
      setBets([]);

      toast({
        title: `Bet successfully placed!`,
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
    onError: (error: any) => {
      if (error.response.status == "409") {
        setOddsChangeData(error.response.data);
        onOpen();
      }

      toast({
        title: `Error placing bet`,
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
  });

  useEffect(() => {
    setBets(betArr);
  }, []);

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Box
          pb="0"
          h="100vh"
          style={{ position: "relative", overflowY: "auto" }}
          width={{ base: "100%", lg: "calc(200% - 335px)" }}
        >
          <Box minH="100vh">
            <Flex pl="4" alignItems="center" h="5rem">
              <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                {strings[language].navbar.account}
              </Text>
            </Flex>

            {window.localStorage.loggedIn !== "true" && (
              <Box mb="5">
                <Box p="10" w="100%" background="white">
                  <Text color={org?.primaryColor} align="center">
                    Log In to View Account
                  </Text>
                </Box>
              </Box>
            )}

            {window.localStorage.loggedIn === "true" && (
              <Tabs isLazy isFitted color="black" variant="unstyled">
                <TabList borderRadius="md" bg="white" w="100%">
                  <Tab
                    _selected={tabProps}
                    color={org?.secondaryColor}
                    border={`1px solid ${org?.secondaryColor}`}
                    borderRadius="md"
                    m="1"
                    w="30%"
                  >
                    {strings[language].account.addFunds}
                  </Tab>
                  <Tab
                    _selected={tabProps}
                    color={org?.secondaryColor}
                    border={`1px solid ${org?.secondaryColor}`}
                    borderRadius="md"
                    m="1"
                  >
                    {strings[language].account.accountInfo}
                  </Tab>
                  <Tab
                    _selected={tabProps}
                    color={org?.secondaryColor}
                    border={`1px solid ${org?.secondaryColor}`}
                    borderRadius="md"
                    m="1"
                  >
                    {strings[language].account.transactionHistory}
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <DepositMethod />
                  </TabPanel>
                  <TabPanel>
                    <Profile />
                  </TabPanel>
                  <TabPanel maxW="100%" p="0">
                    <Transactions />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </Box>
          <Box mb="60px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          left="100%"
          zIndex={1}
          hideBelow="lg"
        >
          {!fantasyEnabled ? (
            <BetSlip
              placeBetLoading={placeBetLoading}
              setPlaceBetLoading={setPlaceBetLoading}
              bets={bets}
              setBets={setBets}
              placeBet={placeBet}
            />
          ) : (
            <FantasyBetSlip
              placeBetLoading={placeBetLoading}
              setPlaceBetLoading={setPlaceBetLoading}
              placeBet={placeBet}
            />
          )}
        </Box>
      </SimpleGrid>
      {!fantasyEnabled ? (
        <MobileBetSlip
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
          collapseSlip={props.collapseSlip}
          setCollapseSlip={props.setCollapseSlip}
        />
      ) : (
        <FantasyMobileBetSlip
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          placeBet={placeBet}
          collapseSlip={props.collapseSlip}
          setCollapseSlip={props.setCollapseSlip}
        />
      )}
      {oddsChangeData && (
        <OddsChangeModal
          isOpen={isOpen}
          onClose={onClose}
          oddsChangeData={oddsChangeData}
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
        />
      )}
    </Box>
  );
};
