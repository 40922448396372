import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Spacer,
  Stack,
  Switch,
  Tab,
  TabList,
  Tabs,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { GeoComplyContext, GeoContextType } from "context/geoComplyContext";
import { Bet, PlaceBetTicketDTO, SportBettingTypes } from "models/Bets";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { TiWarning } from "react-icons/ti";
import { UseMutateFunction } from "react-query";
import { useRecoilValue } from "recoil";
import {
  denominationSymbolState,
  languageState,
  oddsDisplayState,
  orgState,
  userState,
} from "store/Store";
import { relativeTime } from "utils/dateCalcs";
import { currencyFormatter } from "utils/formatters";
import { LanguageKey, strings } from "../../../utils/languageStrings";
import {
  calcPayout,
  calcRisk,
  globalOddsFormatter,
} from "../../../utils/oddsCalc";
import ConfirmBetModal from "./ConfirmBetModal";

interface BetSlipProps {
  placeBetLoading?: boolean;
  setPlaceBetLoading?: Dispatch<SetStateAction<boolean>>;
  bets: Bet[];
  setBets: Dispatch<SetStateAction<Bet[]>>;
  placeBet?: UseMutateFunction<any, unknown, PlaceBetTicketDTO, unknown>;
}
export enum BetType {
  parlay = "Parlay",
  straight = "Straight",
}

export const BetSlip = (props: BetSlipProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: oddsInfoIsOpen,
    onOpen: oddsInfoOnOpen,
    onClose: oddsInfoOnClose,
  } = useDisclosure();
  const cancelRef: any = React.useRef();
  const [confirmInput, setConfirmInput] = useState<boolean>(false);
  const [acceptOddsChange, setAcceptOddsChange] = useState(false);
  const [parlayRisk, setParlayRisk] = useState<string>("");
  const [parlayWin, setParlayWin] = useState<string>("");
  const [betType, setBetType] = useState<BetType>();
  const [isStraight, setIsStraight] = useState(true);
  const [dirtyBets, setDirtyBets] = useState<string[]>([]);
  const [dirtyParlay, setDirtyParlay] = useState(false);
  const [invalidParlay, setInvalidParlay] = useState(false);
  const [emptyBets, setEmptyBets] = useState<string[]>([]);
  const [straightBetsInvalid, setStraightBetsInvalid] = useState(false);
  const org = useRecoilValue(orgState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const user = useRecoilValue(userState);
  const toast = useToast();
  const {
    getGeoComply,
    geoComplyData,
    geoComplyError,
    setGeoComplyData,
  }: GeoContextType = GeoComplyContext();
  const [locationLoading, setLocationLoading] = useState("");
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();

  var showParlay = false;
  var parlayDisabled = false;
  var parlayOdds = 0;
  var betConfirm: JSX.Element[] = [];

  const tabProps = {
    color: "white",
    bg: org?.primaryColor,
  };

  const addDirtyBet = (bettingLineId: string) => {
    setDirtyBets((prevDirtyBets) => [...prevDirtyBets, bettingLineId]);
  };

  const removeDirtyBet = (bettingLineId: string) => {
    setDirtyBets((prevDirtyBets) =>
      prevDirtyBets.filter((bet) => bet !== bettingLineId)
    );
  };

  const checkIfBetIsDirty = (bettingLineId: string) => {
    return dirtyBets.includes(bettingLineId);
  };

  const checkEmptyBets = (props: BetSlipProps) => {
    const tempEmptyBets: string[] = [];

    props.bets.forEach((bet) => {
      if (bet.riskAmount === 0 || !bet.riskAmount) {
        tempEmptyBets.push(bet.bettingLineId);
      }
    });

    if (tempEmptyBets.length > 0) {
      setEmptyBets(tempEmptyBets);
    }
  };

  const removeFromEmptyBets = (bettingLineId: string) => {
    setEmptyBets((prevEmptyBets) =>
      prevEmptyBets.filter((betId) => betId !== bettingLineId)
    );
  };

  const checkLocation = () => {
    console.log("Checking location...");
    setLocationLoading("Checking Location...");
    getGeoComply("Place Bet");
    // If location cannot be found, close modal and
    const setTimeoutId = setTimeout(() => {
      console.log(geoComplyData);
      if (!geoComplyData) {
        onClose();
        console.log("geocomply error msg: ", geoComplyError);
        toast({
          title: "Error",
          description: geoComplyError
            ? `Invalid region: ${geoComplyError}`
            : "Timeout getting geo-packet",
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
        setLocationLoading("");
        setConfirmInput(false);
        setGeoComplyData(null);
      }
    }, 120000);
    setTimeoutID(setTimeoutId);
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setStraightBetsInvalid(
      props.bets.filter(
        (bet) =>
          bet.riskAmount < 5 ||
          bet.riskAmount > (user?.betLimit ?? 0) ||
          !bet.riskAmount
      ).length
        ? true
        : false
    );
  }, [props.bets]);

  if (props.bets.length > 1) {
    showParlay = true;

    for (const i in props.bets) {
      if (parlayOdds === 0) {
        parlayOdds = props.bets[i].odds;
      } else {
        parlayOdds = parlayOdds * props.bets[i].odds;
      }

      // Disable the parlay if bet is in the same event but
      // on different teams
      for (const j in props.bets) {
        if (
          props.bets[i].event.id === props.bets[j].event.id &&
          props.bets[i].bettingLineId != props.bets[j].bettingLineId
        ) {
          parlayDisabled = true;
          break;
        }
      }
    }
  }
  // const isParlay = +parlayRisk > 0 && showParlay && !parlayDisabled;

  betConfirm = props.bets.map((bet) => {
    return (
      <Tr key={bet.bettingLineId}>
        <Td>
          <Box>
            <Text color="#000000">{bet.category}</Text>
            <Text fontSize="12" color="gray">
              {capitalizeFirstLetter(bet.bettingType)}
            </Text>
          </Box>
        </Td>
        <Td>
          <Text color={org?.primaryColor}>
            {globalOddsFormatter(oddsDisplay, bet.odds)}
          </Text>
        </Td>
        {betType === BetType.straight && (
          <Td>
            <Text color="#000000">
              {denominationSymbol + currencyFormatter.format(bet.riskAmount)}
            </Text>
          </Td>
        )}
        {betType === BetType.straight && (
          <Td>
            <Text color="#000000">
              {denominationSymbol + currencyFormatter.format(bet.winAmount)}
            </Text>
          </Td>
        )}
      </Tr>
    );
  });

  let betList: JSX.Element[] = [];
  betList = props.bets.map((bet) => {
    var betType = "";
    if (bet.bettingType === SportBettingTypes.moneyline) {
      betType = "Money Line";
    } else if (bet.bettingType === SportBettingTypes.spread) {
      betType = "Spread";
    } else if (bet.bettingType === SportBettingTypes.total) {
      betType = "Totals";
    }

    return (
      <Box
        background="#FFFFFF"
        border="1px solid #00000029"
        pb="2"
        key={bet.bettingLineId}
        zIndex={1}
      >
        <Flex align="center" bg="#F6F6F6" h="10">
          <IconButton
            float="right"
            color="#DD0D0D"
            aria-label="Remove Bet"
            icon={<AiOutlineMinusCircle />}
            onClick={() => {
              var newBets = props.bets;
              const index = props.bets.findIndex((betItem) => {
                return betItem.bettingLineId === bet.bettingLineId;
              });

              if (index > -1) {
                newBets.splice(index, 1);
              }

              removeFromEmptyBets(bet.bettingLineId);
              removeDirtyBet(bet.bettingLineId);
              props.setBets([...newBets]);
              const betString = JSON.stringify([...newBets]);
              window.localStorage.setItem("bets", betString);
            }}
          />
          <Flex justify="space-between">
            <SimpleGrid columns={2}>
              <Flex>
                <Image
                  src={`/icons/${bet.sport?.toLowerCase()}.svg`}
                  h="40px"
                  ml="-4"
                  display="inline-block"
                  filter="invert(39%) sepia(10%) saturate(4628%) hue-rotate(357deg) brightness(93%) contrast(77%)"
                />
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color="#575757"
                  minW="200px"
                  height="30px"
                  mt="7px"
                  noOfLines={1}
                >
                  {bet.category}
                </Text>
              </Flex>
              <Text
                fontSize="md"
                fontWeight="bold"
                color={org?.primaryColor}
                pl="16"
                textAlign="center"
                mt="7px"
              >
                {globalOddsFormatter(oddsDisplay, bet.odds)}
              </Text>
            </SimpleGrid>
          </Flex>
        </Flex>
        <Box pl={6} pt={1}>
          <Text fontSize="sm" fontWeight="semibold" color="#919191">
            {bet.bettingType.toUpperCase()}
          </Text>
        </Box>
        <Box px="6">
          <SimpleGrid columns={2} pb="1">
            <Text
              fontSize="sm"
              fontWeight="semibold"
              color="#919191"
              noOfLines={1}
            >
              {bet.event.name}
            </Text>
            <Text textAlign="end" fontSize="sm" color="#919191">
              {relativeTime(bet.event.startTime, language)}
            </Text>
          </SimpleGrid>

          {isStraight && (
            <SimpleGrid columns={2}>
              <Box pr="2">
                <Flex>
                  <Stack spacing={0}>
                    <Text fontSize="xs" color="#000000">
                      {strings[language].betSlip.wager}
                    </Text>
                    <InputGroup>
                      <NumberInput
                        id={bet.bettingLineId}
                        borderColor="#D3D3D3"
                        background="transparent"
                        _hover={{
                          border: "1px solid #D3D3D3",
                          borderTop: "transparent",
                        }}
                        color="#000000"
                        errorBorderColor="red.500"
                        isInvalid={
                          ((bet.riskAmount > (user?.betLimit ?? 1000) ||
                            bet.riskAmount < 5) &&
                            checkIfBetIsDirty(bet.bettingLineId)) ||
                          emptyBets.includes(bet.bettingLineId)
                        }
                        value={+bet.riskAmount.toFixed(2) || ""}
                        onBlur={() => addDirtyBet(bet.bettingLineId)}
                        onFocus={() => {
                          if (emptyBets.includes(bet.bettingLineId)) {
                            removeFromEmptyBets(bet.bettingLineId);
                          }
                        }}
                        onChange={(e) => {
                          var newBets = props.bets;
                          const index = props.bets.findIndex((betItem) => {
                            return betItem.bettingLineId === bet.bettingLineId;
                          });

                          if (index > -1) {
                            newBets[index].riskAmount = +e;
                            newBets[index].winAmount = calcPayout(
                              +e,
                              newBets[index].odds
                            );
                          }

                          props.setBets([...newBets]);
                        }}
                      >
                        <NumberInputField
                          fontSize="xl"
                          fontWeight="bold"
                          borderRadius="2px"
                          placeholder="0.00"
                          _placeholder={{
                            color: "gray",
                            fontWeight: "normal",
                          }}
                        />
                      </NumberInput>
                    </InputGroup>
                  </Stack>
                </Flex>
              </Box>

              <Box pl="2">
                <Flex>
                  <Stack spacing={0}>
                    <Text fontSize="xs" color="#000000">
                      {strings[language].betSlip.win}
                    </Text>
                    <InputGroup>
                      <NumberInput
                        id={bet.bettingLineId}
                        borderColor="#D3D3D3"
                        background="transparent"
                        color="#000000"
                        errorBorderColor="red.500"
                        isInvalid={
                          ((bet.riskAmount > (user?.betLimit ?? 1000) ||
                            bet.riskAmount < 5) &&
                            checkIfBetIsDirty(bet.bettingLineId)) ||
                          emptyBets.includes(bet.bettingLineId)
                        }
                        _hover={{
                          border: "1px solid #D3D3D3",
                        }}
                        onBlur={() => addDirtyBet(bet.bettingLineId)}
                        onFocus={() => {
                          if (emptyBets.includes(bet.bettingLineId)) {
                            removeFromEmptyBets(bet.bettingLineId);
                          }
                        }}
                        value={+bet.winAmount.toFixed(2) || ""}
                        onChange={(e) => {
                          var newBets = props.bets;
                          const index = props.bets.findIndex((betItem) => {
                            return betItem.bettingLineId === bet.bettingLineId;
                          });

                          if (index > -1) {
                            newBets[index].riskAmount = calcRisk(
                              +e,
                              newBets[index].odds
                            );
                            newBets[index].winAmount = +e;
                          }

                          props.setBets([...newBets]);
                        }}
                      >
                        <NumberInputField
                          fontSize="xl"
                          fontWeight="bold"
                          borderRadius="2px"
                          placeholder="0.00"
                          _placeholder={{
                            color: "gray",
                            fontWeight: "normal",
                          }}
                        />
                      </NumberInput>
                    </InputGroup>
                  </Stack>
                </Flex>
              </Box>
            </SimpleGrid>
          )}
          {emptyBets.includes(bet.bettingLineId) && isStraight && (
            <Box w="100%" bg="red.400" mt="1">
              <Text textAlign="center">
                {strings[language].betSlip.emptyStraightBetsWarning}
              </Text>
            </Box>
          )}
          {bet.riskAmount < 5 &&
            checkIfBetIsDirty(bet.bettingLineId) &&
            isStraight &&
            !emptyBets.includes(bet.bettingLineId) && (
              <Box w="100%" bg="red.400" mt="1">
                <Text textAlign="center">
                  {`${strings[language].betSlip.minimumWagerWarning}(${denominationSymbol}5)`}
                </Text>
              </Box>
            )}
          {bet.riskAmount > (user?.betLimit ?? 1000) && isStraight && (
            <Box w="100%" bg="red.400" mt="1">
              <Text textAlign="center">
                {`${strings[language].betSlip.maximumWagerWarning}(${
                  denominationSymbol + (user?.betLimit ?? 1000)
                })`}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  });
  //}

  return (
    <Box
      style={{
        width: "100%",
        boxShadow: "0px 3px 6px #00000029",
      }}
      height={{ md: "97vh", "2xl": "100vh" }}
      background="#FFFFFF"
      maxW="min(536px, 100%)"
      position="sticky"
    >
      <Flex background="#646879" p="5" h="16">
        <Text color="#fff" fontSize="lg" fontWeight="bold">
          {`${strings[language].betSlip.betSlip} (${props.bets.length})`}
        </Text>
        <Spacer />
      </Flex>

      <Box
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Tabs isLazy isFitted color="black" variant="unstyled">
          <TabList bg="white" w="100%">
            <Tab
              _selected={tabProps}
              color={org?.primaryColor}
              border={`1px solid ${org?.primaryColor}`}
              onClick={() => setIsStraight(true)}
            >
              {strings[language].betSlip.straight}
            </Tab>
            <Tab
              _selected={tabProps}
              color={org?.primaryColor}
              border={`1px solid ${org?.primaryColor}`}
              onClick={() => setIsStraight(false)}
            >
              {strings[language].betSlip.parlay}
            </Tab>
          </TabList>
        </Tabs>
        <div>
          <Box
            style={{ overflowY: "auto" }}
            position="relative"
            maxHeight={
              isStraight ? "calc(100vh - 280px)" : "calc(100vh - 450px)"
            }
            height="100%"
          >
            {betList}
            {betList.length !== 0 && (
              <Box
                py="2"
                bg="#F6F6F6"
                textAlign="center"
                alignContent="center"
                cursor="pointer"
                color="#DD0D0D"
                onClick={() => {
                  props.setBets([]);
                  setDirtyBets([]);
                  setEmptyBets([]);
                  setParlayRisk("");
                  setDirtyParlay(false);
                  window.localStorage.setItem("bets", JSON.stringify([]));
                }}
              >
                <HStack justify="center">
                  <BsTrash />
                  <Text>{strings[language].betSlip.removeAll}</Text>
                </HStack>
              </Box>
            )}
          </Box>
          {parlayDisabled && (
            <Box
              mx="-2"
              mb="10"
              px="12"
              py="2"
              bg={`${org?.primaryColor}60`}
              textAlign="center"
            >
              <HStack>
                <TiWarning size="50px" color="#DD0D0D" />
                <Text color="#575757">
                  {" "}
                  {strings[language].betSlip.parlayWarning}
                </Text>
              </HStack>
            </Box>
          )}
          <Box position="absolute" bottom="8vh" w="100%" bg="#FFFFFF">
            {showParlay && !parlayDisabled && !isStraight && (
              <Box
                background="#FFFFFF"
                borderTop="1px solid #00000029"
                borderBottom="1px solid #00000029"
              >
                <Flex
                  align="center"
                  bg={
                    parlayRisk === "" || parlayRisk === "0"
                      ? "#F6F6F6"
                      : org?.primaryColor
                  }
                  h="10"
                >
                  <Flex pl="4">
                    <Text
                      fontSize="md"
                      color={
                        parlayRisk === "" || parlayRisk === "0"
                          ? "#000000"
                          : "#FFFFFF"
                      }
                      fontWeight="bold"
                    >
                      {strings[language].betSlip.parlayHeader(betList.length)}
                    </Text>
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      color={
                        parlayRisk === "" || parlayRisk === "0"
                          ? org?.primaryColor
                          : "#F6F6F6"
                      }
                      pl="2"
                      position="absolute"
                      right="6"
                    >
                      {parlayDisabled
                        ? ""
                        : globalOddsFormatter(oddsDisplay, parlayOdds)}
                    </Text>
                  </Flex>
                </Flex>
                <Box pb="2">
                  <SimpleGrid columns={2} py="2" px="4">
                    <Box pr="2">
                      <Flex>
                        <Stack spacing={0}>
                          <Text fontSize="xs" color="#000000" pt="2">
                            {strings[language].betSlip.parlayWager}
                          </Text>
                          <InputGroup>
                            <NumberInput
                              borderColor="#D3D3D3"
                              bg={
                                parlayRisk !== "" && parlayRisk !== "0"
                                  ? `${org?.primaryColor}40`
                                  : "#FFFFFF"
                              }
                              color="#000000"
                              _hover={{
                                border: "1px solid #D3D3D3",
                              }}
                              errorBorderColor="red.500"
                              isInvalid={
                                ((+parlayRisk > (user?.betLimit ?? 1000) ||
                                  +parlayRisk < 5) &&
                                  dirtyParlay) ||
                                invalidParlay
                              }
                              onFocus={() => {
                                +parlayRisk === 0 && setParlayRisk("");
                                if (invalidParlay) {
                                  setInvalidParlay(false);
                                }
                              }}
                              onBlur={() => setDirtyParlay(true)}
                              value={parlayRisk || ""}
                              onChange={(e) => {
                                setParlayRisk(e);
                                setParlayWin((+e * parlayOdds).toFixed(2));
                              }}
                            >
                              <NumberInputField
                                fontSize="xl"
                                fontWeight="bold"
                                borderRadius="2px"
                                placeholder="0.00"
                                _placeholder={{
                                  color: "gray",
                                  fontWeight: "normal",
                                }}
                              />
                            </NumberInput>
                          </InputGroup>
                        </Stack>
                      </Flex>
                    </Box>

                    <Box pl="2">
                      <Flex>
                        <Stack spacing={0}>
                          <Text fontSize="xs" color="#000000" pt="2">
                            {strings[language].betSlip.parlayWin}
                          </Text>
                          <InputGroup>
                            <NumberInput
                              borderColor="#D3D3D3"
                              bg={
                                parlayRisk !== "" && parlayRisk !== "0"
                                  ? `${org?.primaryColor}40`
                                  : "#FFFFFF"
                              }
                              color="#000000"
                              errorBorderColor="red.500"
                              isInvalid={
                                ((+parlayRisk > (user?.betLimit ?? 1000) ||
                                  +parlayRisk < 5) &&
                                  dirtyParlay) ||
                                invalidParlay
                              }
                              _hover={{
                                border: "1px solid #D3D3D3",
                              }}
                              onFocus={() => {
                                +parlayWin === 0 && setParlayWin("");
                                if (invalidParlay) {
                                  setInvalidParlay(false);
                                }
                              }}
                              onBlur={() => setDirtyParlay(true)}
                              value={parlayWin || ""}
                              onChange={(e) => {
                                setParlayWin(e);
                                setParlayRisk((+e / parlayOdds).toFixed(2));
                              }}
                            >
                              <NumberInputField
                                fontSize="xl"
                                fontWeight="bold"
                                borderRadius="2px"
                                placeholder="0.00"
                                _placeholder={{
                                  color: "gray",
                                  fontWeight: "normal",
                                }}
                              />
                            </NumberInput>
                          </InputGroup>
                        </Stack>
                      </Flex>
                    </Box>
                  </SimpleGrid>
                  {invalidParlay && (
                    <Box w="100%" bg="red.400" mt="1">
                      <Text textAlign="center">
                        {strings[language].betSlip.emptyParlayBetWarning}
                      </Text>
                    </Box>
                  )}
                  {+parlayRisk < 5 && dirtyParlay && !invalidParlay && (
                    <Box w="100%" bg="red.400" mt="1">
                      <Text textAlign="center">
                        {`${strings[language].betSlip.minimumWagerWarning}(${denominationSymbol}5)`}
                      </Text>
                    </Box>
                  )}
                  {+parlayRisk > (user?.betLimit ?? 1000) && (
                    <Box w="100%" bg="red.400" mt="1">
                      <Text textAlign="center">
                        {`${strings[language].betSlip.maximumWagerWarning}(${
                          denominationSymbol + (user?.betLimit ?? 1000)
                        })`}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            {betList.length == 1 && !isStraight && (
              <Box w="100%" bg="red.400" mt="1">
                <Text textAlign="center">
                  {strings[language].betSlip.parlayBetsWarning}
                </Text>
              </Box>
            )}

            {betList.length !== 0 && (
              <HStack justify="space-between" px={4} py={1}>
                <Flex>
                  <Text mr={1}>{strings[language].betSlip.oddsChange}</Text>
                  <IoIosInformationCircleOutline
                    fontSize="24px"
                    cursor="pointer"
                    onClick={() => oddsInfoOnOpen()}
                  />
                </Flex>
                <Switch
                  onChange={() => setAcceptOddsChange(!acceptOddsChange)}
                />
              </HStack>
            )}

            {betList.length !== 0 && isStraight && (
              <Stack>
                <Box px="4" mb="4" py="4" background="#FFFFFF">
                  <Button
                    w="100%"
                    background={org?.primaryColor}
                    color="#fff"
                    _hover={{
                      background: org?.primaryColor,
                      color: "#FFFFFF",
                    }}
                    isDisabled={
                      window.localStorage.loggedIn !== "true" ||
                      props.bets.length > 25
                    }
                    isLoading={props.placeBetLoading}
                    onClick={() => {
                      checkEmptyBets(props);
                      if (straightBetsInvalid) {
                        return 0;
                      }
                      setBetType(BetType.straight);
                      // setGeoComplyData(null); -- GEOLOCATION CHECK DISABLED
                      // checkLocation(); -- GEOLOCATION CHECK DISABLED
                      onOpen();
                    }}
                  >
                    {window.localStorage.loggedIn !== "true"
                      ? strings[language].betSlip.login
                      : strings[language].betSlip.placeStraightBet(
                          props.bets.length
                        )}
                  </Button>
                </Box>
              </Stack>
            )}

            {betList.length !== 0 && !isStraight && (
              <Stack>
                <Box px="4" mb="4" py="4" background="#FFFFFF">
                  <Button
                    w="100%"
                    background={org?.primaryColor}
                    color="#fff"
                    _hover={{
                      background: org?.primaryColor,
                      color: "#FFFFFF",
                    }}
                    isDisabled={
                      window.localStorage.loggedIn !== "true" ||
                      props.bets.length < 2 ||
                      props.bets.length > 25
                    }
                    isLoading={props.placeBetLoading}
                    onClick={() => {
                      if (parlayRisk === "" || +parlayRisk === 0) {
                        setInvalidParlay(true);
                        return 0;
                      }
                      if (
                        +parlayRisk < 5 ||
                        +parlayRisk > (user?.betLimit ?? 0)
                      ) {
                        return 0;
                      }
                      setBetType(BetType.parlay);
                      // setGeoComplyData(null); -- GEOLOCATION CHECK DISABLED
                      // checkLocation(); -- GEOLOCATION CHECK DISABLED
                      onOpen();
                    }}
                  >
                    {window.localStorage.loggedIn !== "true"
                      ? strings[language].betSlip.login
                      : strings[language].betSlip.placeParlayBet(
                          props.bets.length
                        )}
                  </Button>
                </Box>
              </Stack>
            )}
          </Box>
          {betList.length === 0 && (
            <Flex pt="40" align="center" justify="center">
              <Stack>
                <Image src="/empty.png" h="100px" />
                <Text pt="5" fontWeight="semibold" color="#000000">
                  {strings[language].betSlip.emptyBetSlip}
                </Text>
              </Stack>
            </Flex>
          )}
        </div>
      </Box>
      <Modal isOpen={oddsInfoIsOpen} onClose={oddsInfoOnClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {strings[language].betSlip.oddsChangeHeader}
          </ModalHeader>
          <ModalBody>
            <Text>{strings[language].betSlip.oddsChangeBody}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              bg={org?.secondaryColor}
              color="white"
              _hover={{ bg: `${org?.secondaryColor}90` }}
              onClick={() => oddsInfoOnClose()}
            >
              {strings[language].betSlip.okay}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConfirmBetModal
        cancelRef={cancelRef}
        onClose={onClose}
        betType={betType}
        acceptOddsChange={acceptOddsChange}
        betConfirm={betConfirm}
        parlayWinAmount={+parlayWin}
        parlayOdds={parlayOdds}
        parlayRisk={parlayRisk}
        parlayDisabled={parlayDisabled}
        confirmInput={confirmInput}
        setConfirmInput={setConfirmInput}
        timeoutID={timeoutID}
        setTimeoutID={setTimeoutID}
        locationLoading={locationLoading}
        setLocationLoading={setLocationLoading}
        geoComplyData={geoComplyData}
        setGeoComplyData={setGeoComplyData}
        isOpen={isOpen}
        setPlaceBetLoading={props.setPlaceBetLoading!}
        bets={props.bets}
        placeBet={props.placeBet!}
      />
    </Box>
  );
};
