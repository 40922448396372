import { ChakraProvider, extendTheme, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const sizes = {
  sizes: {
    containers: {
      xl: "1512px",
    },
  },
};

const breakpoints = {
  "2xl": "1536px",
  "3xl": "1680px",
};

const theme = extendTheme({
  breakpoints,
  sizes: { container: { xl: "1512px" } },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ForceDarkMode>
        <App />
      </ForceDarkMode>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

function ForceDarkMode(props: { children: JSX.Element }) {
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === "light") return;
    toggleColorMode();
  }, [colorMode]);

  return props.children;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
