import {
  Box,
  ButtonGroup,
  Center,
  Stack,
  IconButton,
  Text,
  Flex,
  Badge,
  SimpleGrid,
  Image,
  useToast,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useQuery, useMutation } from "react-query";
import { globalOddsFormatter } from "utils/oddsCalc";
import {
  Bet,
  BetTicketDTO,
  OddsChangeData,
  SportBettingTypes,
} from "models/Bets";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import API from "services/API";
import { strings, LanguageKey } from "utils/languageStrings";
import { BetSlip } from "../dashboard/betslip/BetSlip";
import { Footer } from "components/shared/Footer";
import { useRecoilValue } from "recoil";
import {
  oddsDisplayState,
  denominationSymbolState,
  languageState,
  fantasyBetsStore,
  fantasyEnabledState,
  orgState,
} from "store/Store";
import { MobileBetSlip } from "components/dashboard/betslip/MobileBetSlip";
import { relativeTime } from "utils/dateCalcs";
import OddsChangeModal from "components/dashboard/betslip/OddsChangeModal";
import { FantasyBetSlip } from "components/fantasy/FantasyBetSlip";
import { FantasyMobileBetSlip } from "components/fantasy/FantasyMobileBetSlip";
import { Filters } from "./Filters";
import { Pagination } from "components/shared/Pagination";
import { BetStatus } from "./BetStatus";

interface MyBetsProps {
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

export const MyBets = (props: MyBetsProps) => {
  const [pageLimit, setPageLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const language = useRecoilValue<LanguageKey>(languageState);
  const fantasyEnabled = useRecoilValue(fantasyEnabledState);
  const fantasyBets = useRecoilValue(fantasyBetsStore);
  const [statusFilters, setStatusFilters] = useState(
    strings[language].myBets.all
  );
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const [bets, setBets] = useState<Bet[]>([]);
  const org = useRecoilValue(orgState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);
  const [oddsChangeData, setOddsChangeData] = useState<OddsChangeData>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Get current selected bets
  var betArr: [];
  var stringBets = window.localStorage.getItem("bets");
  stringBets !== null ? (betArr = JSON.parse(stringBets!)) : (betArr = []);

  var [totalPages] = useState(0);

  var [totalItems] = useState(0);

  const toast = useToast();

  const getBetsResponse = useQuery(
    ["getBetTickets", pageLimit, currentPage, totalPages, statusFilters],
    () => {
      return API.getBetTickets(
        pageLimit,
        currentPage,
        totalPages,
        statusFilters
      );
    }
  );

  useEffect(() => {
    setBets(betArr);
  }, []);

  const { mutate: placeBet } = useMutation(API.placeBet, {
    onSuccess: (data) => {
      setBets([]);

      getBetsResponse.refetch();

      toast({
        title: `Bet successfully placed!`,
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
    onError: (error: any) => {
      if (error.response.status == "409") {
        setOddsChangeData(error.response.data);
        onOpen();
      }

      toast({
        title: `Error placing bet`,
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
  });

  var tickets: BetTicketDTO[] = [];
  if (getBetsResponse.status == "success") {
    tickets = getBetsResponse.data.data;
    totalPages = getBetsResponse.data.meta.total_pages;
    totalItems = getBetsResponse.data.meta.total_count;
  }

  var ticketsList = tickets.map((ticket) => {
    var betsList = ticket.bets.map((bet) => {
      var betType = "";
      if (bet.bettingType === SportBettingTypes.moneyline) {
        betType = "Money Line";
      }

      if (ticket.ticketType === "Straight") {
        return (
          <Box
            key={bet.id}
            h="100%"
            borderY={"1px solid #ddd"}
            py="5"
            px={{ base: 10, sm: 14 }}
            w="100%"
          >
            <SimpleGrid columns={2} spacing="6" mb="2">
              <Box width="150%">
                <Stack direction={{ base: "column", lg: "row" }} spacing="1">
                  <Text
                    color={org?.secondaryColor}
                    fontSize="md"
                    fontWeight="bold"
                  >
                    {ticket.ticketType.toUpperCase()} BET
                  </Text>
                  <Text color="black" fontSize="xl" fontWeight="bold">
                    {betType}
                  </Text>
                </Stack>
              </Box>

              {ticket.ticketType === "Straight" && (
                <Box overflow="hidden" textAlign="right">
                  <BetStatus betStatus={bet.betStatus} />
                </Box>
              )}
            </SimpleGrid>

            <SimpleGrid columns={2}>
              <Box>
                {bet.sport !== null && bet.sport !== undefined && (
                  <Image
                    src={`/icons/${bet.sport.name.toLowerCase()}.svg`}
                    h="40px"
                    ml="-3"
                    mb="-3"
                    zIndex={1}
                    position="relative"
                    display={{ base: "none", sm: "inline-block" }}
                    filter="invert(44%) sepia(11%) saturate(2568%) hue-rotate(330deg) brightness(96%) contrast(85%)"
                  />
                )}
                <Text
                  color="#000000"
                  fontSize="xl"
                  fontWeight="bold"
                  display="inline-block"
                >
                  {bet.outcomeName}
                </Text>
              </Box>
              <Box>
                <Text
                  textAlign="right"
                  color={org?.primaryColor}
                  fontSize="2xl"
                  fontWeight="semibold"
                >
                  {globalOddsFormatter(oddsDisplay, bet.odds)}
                </Text>
              </Box>
            </SimpleGrid>

            <SimpleGrid columns={2}>
              <Box pl={{ base: 0, sm: 7 }}>
                <Text color="gray.500" textAlign="left">
                  {bet.eventName}
                </Text>
              </Box>
              <Box>
                <Text color="gray.500" textAlign="right">
                  {relativeTime(bet.startTime, language)}
                </Text>
              </Box>
            </SimpleGrid>

            {ticket.ticketType === "Straight" && (
              <SimpleGrid
                columns={{ base: 1, sm: 2 }}
                spacing={{ base: 4, sm: 20 }}
                pt="2"
              >
                <Box
                  border="2px solid #D3D3D3"
                  borderRadius="2px"
                  px="5"
                  py="3"
                >
                  <Text fontSize="sm" color="#434343">
                    {strings[language].myBets.wager}
                  </Text>
                  <Text color="black" fontSize="xl" fontWeight="semibold">
                    {denominationSymbol + bet.riskAmount.toFixed(2)}
                  </Text>
                </Box>

                <Box
                  border="2px solid #D3D3D3"
                  borderRadius="2px"
                  px="5"
                  py="3"
                >
                  <Text fontSize="sm" color="#434343">
                    {strings[language].myBets.payout}
                  </Text>
                  <Text color="black" fontSize="xl" fontWeight="semibold">
                    {denominationSymbol + bet.expectedProfit.toFixed(2)}
                  </Text>
                </Box>
              </SimpleGrid>
            )}
          </Box>
        );
      } else if (ticket.ticketType === "Fantasy") {
        return (
          <Box
            key={ticket.id}
            h="100%"
            borderY={"1px solid #ddd"}
            py="1"
            px={{ base: 6, sm: 14 }}
            w="100%"
          >
            <SimpleGrid columns={2} spacing="6" mb={{ base: 2, sm: 1 }}>
              <Box width="150%"></Box>
              <Box overflow="hidden" textAlign="right">
                <BetStatus betStatus={bet.betStatus} />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2}>
              <Box mt={{ base: 0, sm: -4 }}>
                {bet.sport !== null && bet.sport !== undefined && (
                  <Image
                    src={`/icons/${bet.sport.name.toLowerCase()}.svg`}
                    h="40px"
                    ml="-3"
                    mb="-3"
                    zIndex={1}
                    position="relative"
                    display={{ base: "none", sm: "inline-block" }}
                    filter="invert(44%) sepia(11%) saturate(2568%) hue-rotate(330deg) brightness(96%) contrast(85%)"
                  />
                )}
                <Text
                  color="#000000"
                  fontSize="xl"
                  fontWeight="bold"
                  display="inline-block"
                >
                  {bet.outcomeName}
                </Text>
              </Box>
            </SimpleGrid>

            <SimpleGrid columns={2}>
              <Box pl={{ base: 0, sm: 7 }} mt={0}>
                <HStack fontWeight="bold">
                  <Text color={bet.fantasyOver ? "green" : "red"}>
                    {bet.fantasyOver ? "More" : "Less"}
                  </Text>
                  <Text> {bet.paramFloat1.toFixed(1)}</Text>
                </HStack>
                <Text color="gray.500" textAlign="left">
                  {bet.eventName}
                </Text>
              </Box>
              <Box mt={4}>
                <Text color="gray.500" textAlign="right">
                  {relativeTime(bet.startTime, language)}
                </Text>
              </Box>
            </SimpleGrid>
          </Box>
        );
      } else {
        return (
          <Box
            key={ticket.id}
            h="100%"
            borderY={"1px solid #ddd"}
            py="1"
            px={{ base: 6, sm: 14 }}
            w="100%"
          >
            <SimpleGrid columns={2} spacing="6" mb={{ base: 2, sm: 1 }}>
              <Box width="150%"></Box>
              <Box overflow="hidden" textAlign="right">
                <BetStatus betStatus={bet.betStatus} />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2}>
              <Box mt={{ base: 0, sm: -4 }}>
                {bet.sport !== null && bet.sport !== undefined && (
                  <Image
                    src={`/icons/${bet.sport.name.toLowerCase()}.svg`}
                    h="40px"
                    ml="-3"
                    mb="-3"
                    zIndex={1}
                    position="relative"
                    display={{ base: "none", sm: "inline-block" }}
                    filter="invert(44%) sepia(11%) saturate(2568%) hue-rotate(330deg) brightness(96%) contrast(85%)"
                  />
                )}
                <Text
                  color="#000000"
                  fontSize="xl"
                  fontWeight="bold"
                  display="inline-block"
                >
                  {bet.outcomeName}
                </Text>
              </Box>
              <Box>
                <Text
                  textAlign="right"
                  color={org?.primaryColor}
                  fontSize="2xl"
                  fontWeight="semibold"
                >
                  {globalOddsFormatter(oddsDisplay, bet.odds)}
                </Text>
              </Box>
            </SimpleGrid>

            <SimpleGrid columns={2}>
              <Box pl={{ base: 0, sm: 7 }} mt={{ base: 0, sm: -4 }}>
                <Text color="gray.500" textAlign="left">
                  {bet.eventName}
                </Text>
              </Box>
              <Box>
                <Text color="gray.500" textAlign="right">
                  {relativeTime(bet.startTime, language)}
                </Text>
              </Box>
            </SimpleGrid>
          </Box>
        );
      }
    });

    return (
      <Box key={ticket.id} background="white">
        <Box
          p="4"
          h="68px"
          w="100%"
          display="flex"
          alignItems="center"
          background={`${org?.secondaryColor} 0% 0% no-repeat padding-box`}
          boxShadow="inset 0px 4px 4px #00000029"
        >
          <Box w="100%">
            <Flex alignItems="center">
              <SimpleGrid columns={{ base: 1, sm: 2 }} w="100%" px="10">
                <HStack ml="-1" justify={{ base: "center", sm: "left" }}>
                  <Text
                    color="#FFFFFF"
                    fontSize="lg"
                    fontWeight="semibold"
                    mt={1}
                  >
                    TICKET {ticket.id}
                  </Text>
                  <Box ml="2">
                    <BetStatus betStatus={ticket.ticketStatus} />
                  </Box>
                </HStack>
                <Box
                  overflow="hidden"
                  textAlign={{ base: "center", sm: "right" }}
                  fontWeight="bold"
                  ml="2"
                >
                  <Text color="#FFFFFF" mt={1}>
                    Placed {relativeTime(ticket.createdAt, language)}
                  </Text>
                </Box>
              </SimpleGrid>
            </Flex>
            {ticket.ticketType === "Straight" && <Box></Box>}
          </Box>
        </Box>
        <Box>
          {ticket.ticketType === "Parlay" && (
            <Box h="100%" borderY={"1px solid #ddd"} py="5" px="14" w="100%">
              <Box width="100%">
                <SimpleGrid
                  columns={{ base: 1, sm: 2 }}
                  spacing={{ base: 0, sm: 20 }}
                >
                  <Box>
                    <Text
                      color={org?.secondaryColor}
                      fontSize="md"
                      fontWeight="bold"
                      textAlign={{ base: "center", sm: "left" }}
                    >
                      {ticket.ticketType.toUpperCase()} BET
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      textAlign={{ base: "center", sm: "right" }}
                      color={org?.primaryColor}
                      fontSize="2xl"
                      fontWeight="semibold"
                    >
                      {globalOddsFormatter(oddsDisplay, ticket.odds)}
                    </Text>
                  </Box>
                </SimpleGrid>
              </Box>
              <SimpleGrid
                columns={{ base: 1, sm: 2 }}
                spacing={{ base: 4, sm: 20 }}
                pt="2"
              >
                <Box
                  border="2px solid #D3D3D3"
                  borderRadius="2px"
                  px="5"
                  py="3"
                >
                  <Text fontSize="sm" color="#434343">
                    {strings[language].myBets.wager}
                  </Text>
                  <Text color="black" fontSize="xl" fontWeight="semibold">
                    {denominationSymbol + ticket.riskAmount.toFixed(2)}
                  </Text>
                </Box>

                <Box
                  border="2px solid #D3D3D3"
                  borderRadius="2px"
                  px="5"
                  py="3"
                >
                  <Text fontSize="sm" color="#434343">
                    {strings[language].myBets.payout}
                  </Text>
                  <Text color="black" fontSize="xl" fontWeight="semibold">
                    {denominationSymbol + ticket.winAmount.toFixed(2)}
                  </Text>
                </Box>
              </SimpleGrid>
            </Box>
          )}
          {ticket.ticketType === "Fantasy" && (
            <Box h="100%" borderY={"1px solid #ddd"} py="5" px="14" w="100%">
              <Box width="100%">
                <SimpleGrid
                  columns={{ base: 1, sm: 2 }}
                  spacing={{ base: 0, sm: 20 }}
                >
                  <Box>
                    <Text
                      color={org?.secondaryColor}
                      fontSize="md"
                      fontWeight="bold"
                      textAlign={{ base: "center", sm: "left" }}
                    >
                      {ticket.ticketType.toUpperCase()} TICKET
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      textAlign={{ base: "center", sm: "right" }}
                      color={org?.primaryColor}
                      fontSize="2xl"
                      fontWeight="semibold"
                    >
                      x {ticket.bets.length}
                    </Text>
                  </Box>
                </SimpleGrid>
              </Box>
              <SimpleGrid
                columns={{ base: 1, sm: 2 }}
                spacing={{ base: 4, sm: 20 }}
                pt="2"
              >
                <Box
                  border="2px solid #D3D3D3"
                  borderRadius="2px"
                  px="5"
                  py="3"
                >
                  <Text fontSize="sm" color="#434343">
                    Entry
                  </Text>
                  <Text color="black" fontSize="xl" fontWeight="semibold">
                    {denominationSymbol + ticket.riskAmount.toFixed(2)}
                  </Text>
                </Box>

                <Box
                  border="2px solid #D3D3D3"
                  borderRadius="2px"
                  px="5"
                  py="3"
                >
                  <Text fontSize="sm" color="#434343">
                    To Win
                  </Text>
                  <Text color="black" fontSize="xl" fontWeight="semibold">
                    {denominationSymbol + ticket.winAmount.toFixed(2)}
                  </Text>
                </Box>
              </SimpleGrid>
            </Box>
          )}
          {betsList}
        </Box>
      </Box>
    );
  });

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Box
          pb="0"
          h="100vh"
          style={{ position: "relative", overflowY: "auto" }}
          width={{ base: "100%", lg: "calc(200% - 335px)" }}
        >
          <Box minH="100vh">
            <Box position="sticky" top="0" bg="#FFFFFF" zIndex={2}>
              <Flex pl="4" alignItems="center" h="5rem">
                <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                  {fantasyEnabled
                    ? "My Entries"
                    : strings[language].myBets.myBets}
                </Text>
              </Flex>

              <Box
                p="10"
                h="93px"
                w="100%"
                display="flex"
                alignItems="center"
                background="#FFFFFF 0% 0% no-repeat padding-box"
                boxShadow="inset 0px 4px 4px #00000029, 0px 4px 4px #00000029"
              >
                <Filters
                  selectedFilter={statusFilters}
                  setStatusFilter={setStatusFilters}
                  setCurrentPage={setCurrentPage}
                />
              </Box>
            </Box>

            {window.localStorage.loggedIn !== "true" && (
              <Box mb="5">
                {ticketsList.length == 0 && (
                  <Box mt="10" p="10" w="100%" background="white">
                    <Text color={org?.primaryColor} align="center">
                      Log In to View Bets
                    </Text>
                  </Box>
                )}
              </Box>
            )}

            {window.localStorage.loggedIn === "true" && (
              <Box mb="5">
                {ticketsList.length > 0 && ticketsList}

                {ticketsList.length == 0 && (
                  <Box mt="10" p="10" w="100%" background="white">
                    <Text color={org?.primaryColor} align="center">
                      No bets placed
                    </Text>
                  </Box>
                )}
              </Box>
            )}

            {ticketsList.length !== 0 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Box>
          <Box mb="60px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          left="100%"
          zIndex={1}
          hideBelow="lg"
        >
          {!fantasyEnabled ? (
            <BetSlip
              placeBetLoading={placeBetLoading}
              setPlaceBetLoading={setPlaceBetLoading}
              bets={bets}
              setBets={setBets}
              placeBet={placeBet}
            />
          ) : (
            <FantasyBetSlip
              placeBetLoading={placeBetLoading}
              setPlaceBetLoading={setPlaceBetLoading}
              placeBet={placeBet}
            />
          )}
        </Box>
      </SimpleGrid>
      {!fantasyEnabled ? (
        <MobileBetSlip
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
          collapseSlip={props.collapseSlip}
          setCollapseSlip={props.setCollapseSlip}
        />
      ) : (
        <FantasyMobileBetSlip
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          placeBet={placeBet}
          collapseSlip={props.collapseSlip}
          setCollapseSlip={props.setCollapseSlip}
        />
      )}
      {oddsChangeData && (
        <OddsChangeModal
          isOpen={isOpen}
          onClose={onClose}
          oddsChangeData={oddsChangeData}
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
        />
      )}
    </Box>
  );
};
