import {
  Box,
  Flex,
  HStack,
  Image,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Navbar } from "components/shared/Navbar";
import { GeoComplyContext, GeoContextType } from "context/geoComplyContext";
import { OrgDTO } from "models/Orgs";
import React, { memo, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import API from "services/API";
import {
  denominationSymbolState,
  fantasyEnabledState,
  languageState,
  layoutState,
  oddsDisplayState,
  orgState,
  userState,
} from "store/Store";
import BaseRoutes from "./BaseRoutes";
import { MobileBottomBar } from "./shared/MobileBottomBar";
import Sidebar from "./shared/Sidebar";

function Base() {
  const history = useHistory();
  const [user, setUser] = useRecoilState(userState);
  const setOddsDisplay = useSetRecoilState(oddsDisplayState);
  const [fantasyEnabled, setFantasyEnabled] =
    useRecoilState(fantasyEnabledState);
  const [collapseSlip, setCollapseSlip] = useState<boolean>(true);
  const [loading, setLoading] = React.useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [layout, setLayout] = useRecoilState(layoutState);
  const [language, setLanguage] = useRecoilState(languageState);
  const [orgLoading, setOrgLoading] = useState(false);
  const [defaultOrg, setDefaultOrg] = useState<OrgDTO | null>();
  const [org, setOrg] = useRecoilState(orgState);
  const [denominationSymbol, setDenominationSymbol] = useRecoilState(
    denominationSymbolState
  );

  const { setGeoComplyData }: GeoContextType = GeoComplyContext();

  var userTermsDate: string = "";
  var latestTermsDate: string = "";

  const toast = useToast();

  const minutes = 30;

  const logout = () => {
    //history.push("/login");
    window.localStorage.loggedIn = false;
    delete window.localStorage.email;
    delete window.localStorage.jwt;
    delete window.localStorage.bets;
    delete window.localStorage.oddsDisplay;
    delete window.localStorage.layout;
    delete window.localStorage.language;
    setGeoComplyData(null);
  };

  /** Logout the user after 30 minutes of inactivity */
  // const onIdle = () => {
  //   logout();
  // };

  // useIdleTimer({
  //   onIdle,
  //   timeout: 60000 * minutes,
  // });

  const ref = useRef<any>();

  /* QUERIES */

  const ipLocationCheck = () => {
    setLocationLoading(true);
    API.ipLocationCheck()
      .then((data) => {
        if (data === false) {
          history.push("/location-restricted");
        }
      })
      .catch(() => {
        history.push("/location-restricted");
        toast({
          title: `Error fetching location.`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLocationLoading(false);
      });
  };

  const fetchDefaultOrg = async (slug: string) => {
    setOrgLoading(true);
    API.getOrgBySlug(slug)
      .then((data) => {
        setDefaultOrg(data);
        setOrg(data);
      })
      .catch(() => {
        toast({
          title: `Error fetching default org.`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setOrgLoading(false);
      });
  };

  const fetchProfile = () => {
    API.getProfile()
      .then((data) => {
        setUser(data);
        if (data?.org) {
          setOrg(data.org);
          setDenominationSymbol(data?.org.denominationSymbol);
          setFantasyEnabled(data?.org.fantasy);
          setOddsDisplay(
            window.localStorage.oddsDisplay
              ? window.localStorage.oddsDisplay
              : data?.org.oddsDisplay
          );
          setLayout(
            window.localStorage.layout
              ? window.localStorage.layout
              : data?.org.layout
          );
          setLanguage(
            window.localStorage.language
              ? window.localStorage.language
              : data?.org.language
          );
        } else {
          setOrg(defaultOrg!);
          setDenominationSymbol(defaultOrg?.denominationSymbol || "$");
          setFantasyEnabled(defaultOrg?.fantasy || false);
          setOddsDisplay(
            window.localStorage.oddsDisplay
              ? window.localStorage.oddsDisplay
              : defaultOrg?.oddsDisplay || "American"
          );
          setLayout(
            window.localStorage.layout
              ? window.localStorage.layout
              : defaultOrg?.layout || "American"
          );
          setLanguage(
            window.localStorage.language
              ? window.localStorage.language
              : defaultOrg?.language || "en"
          );
        }
      })
      .catch((error) => {
        if (defaultOrg) {
          setOrg(defaultOrg);
          setDenominationSymbol(defaultOrg.denominationSymbol);
          setFantasyEnabled(defaultOrg.fantasy);
          setOddsDisplay(
            window.localStorage.oddsDisplay
              ? window.localStorage.oddsDisplay
              : defaultOrg.oddsDisplay
          );
          setLayout(
            window.localStorage.layout
              ? window.localStorage.layout
              : defaultOrg.layout
          );
          setLanguage(
            window.localStorage.language
              ? window.localStorage.language
              : defaultOrg.language
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadPageData = async () => {
    ipLocationCheck();
    if (process.env.REACT_APP_DEFAULT_ORG) {
      await fetchDefaultOrg(process.env.REACT_APP_DEFAULT_ORG);
    }
    fetchProfile();
  };

  useEffect(() => {
    loadPageData();
  }, []);

  console.log("RE RENDER BASE");

  /**
   * Compare latest terms & conditions creation date to
   * users' terms & conditions accepted date.
   * If users' date is less or null, setTCStatus(false) else setTCStatus(true)
   */
  /*const compareTermsDates = () => {
    if (getProfileData.isSuccess && getLatestTerms.isSuccess) {
      latestTermsDate = getLatestTerms.data.createdDate;

      if (getProfileData.data.acceptedTC !== null) {
        userTermsDate = getProfileData.data.acceptedTC.createdDate;

        let date1 = new Date(userTermsDate).getTime();
        let date2 = new Date(latestTermsDate).getTime();

        if (date1 < date2) {
          setTCStatus(false);
        } else {
          setTCStatus(true);
        }
      } else {
        setTCStatus(false);
      }
    }
  };*/

  /*useEffect(() => {
    compareTermsDates();
  }, [getLatestTerms, getProfileData]);

  useEffect(() => {
    if (tcStatus === false) {
      onOpen();
    } else {
      onClose();
    }
  }, [tcStatus]);*/

  if (loading || locationLoading || orgLoading) {
    return (
      <div className="loader-container">
        {" "}
        <Spinner color="black" />
      </div>
    );
  } else {
    return (
      <Flex h="100vh" flexDirection="column">
        {/* Responsible Gaming  */}
        {process.env.REACT_APP_AFRICAN_ENVIRONMENT !== "1" && (
          <>
            <Box
              zIndex={{ base: 3, lg: 1500 }}
              w="100%"
              bg={org?.secondaryColor}
            >
              <HStack justifyContent="end" mr="4">
                <Text fontSize="12px" color="white">
                  Gambling problem? Call 1-800-522-4700
                </Text>
                <Image
                  src="/responsible-gaming-logo.png"
                  alt="Responsible Gaming Logo"
                  w="25px"
                />
              </HStack>
            </Box>

            <Box mb={{ md: "0px", "2xl": "68px" }}>
              <Navbar />
            </Box>
          </>
        )}

        {process.env.REACT_APP_AFRICAN_ENVIRONMENT === "1" && (
          <Box mb={{ md: "0px", "2xl": "81px" }}>
            <Navbar />
          </Box>
        )}

        {/* <MobileTopBar /> */}

        <Flex flex="1" overflow="hidden" bg="#F6F6F6">
          <Flex h="100%" w="100%" mx="auto">
            <Flex flex="1" left="64" ref={ref}>
              <HStack m={0} w="100%">
                <Flex flex="1" background="#FFFFFF" h="100%">
                  <Box
                    h="100%"
                    w="full"
                    ml={{ md: "0px", "2xl": "0px" }}
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-track": {
                        width: "8px",
                        background: "#333",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#777",
                        borderRadius: "24px",
                      },
                    }}
                  >
                    <BaseRoutes />
                  </Box>
                </Flex>
              </HStack>
            </Flex>
          </Flex>
        </Flex>

        <MobileBottomBar
          collapseSlip={collapseSlip}
          setCollapseSlip={setCollapseSlip}
        />

        {/*
          <Box
            position="fixed"
            w="100%"
            bottom="62px"
            bg={org?.primaryColor}
            color="white"
            textAlign="center"
            zIndex="10000"
            fontWeight="bold"
            pt="3"
            pb="3"
          >
            Select One More Player Prop to Proceed
          </Box>*/}

        {/* Latest Terms and Conditions Modal */}
        {/* <TCModal
            isOpen={isOpen}
            onClose={onClose}
            tcStatus={tcStatus}
            setTcStatus={setTCStatus}
          /> */}
      </Flex>
    );
  }
}

export default memo(Base);
