import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useToast,
  Button,
  HStack,
  Grid,
  Image,
  Center,
  Alert,
  AlertIcon,
  Stack,
} from "@chakra-ui/react";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import BBSpinner from "components/shared/BBSpinner";
import { EventDTO } from "models/Events";
import {
  useQuery,
  useMutation,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import API from "services/API";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ParticipantDTO } from "models/Participant";
import { Footer } from "components/shared/Footer";
import { useRecoilState, useRecoilValue } from "recoil";
import { fantasyBetsStore, leagueIdState, orgState } from "store/Store";
import { Bet } from "models/Bets";
import FantasyLine from "./FantasyLine";
import { AdCarouselFantasy } from "components/shared/AdCarouselFantasy";
import { BettingLineDTO } from "models/BettingLine";
import { FantasyBetSlip } from "./FantasyBetSlip";
import { FantasyMobileBetSlip } from "./FantasyMobileBetSlip";
import { sportIconsFilter } from "utils/sportIconsFilter";

interface HomeProps {
  refetchProfile: () => void;
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

interface HomeParams {
  sportId: string;
}

export default function Fantasy(props: HomeProps) {
  const { sportId } = useParams<HomeParams>();
  const location = useLocation();
  var path = location.pathname.split("/");
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const org = useRecoilValue(orgState);
  const [bets, setBets] = useState<Bet[]>([]);
  const [leagueFilters, setLeagueFilters] = useState<number>(+path[3]);
  const [leagueId, setLeagueId] = useRecoilState(leagueIdState);
  const [bettingTypeFilter, setBettingTypeFilter] = useState("Points");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [fantasyBettingLines, setFantasyBettingLines] = useState<
    EventDTO[] | null
  >(null);
  const [fantasyBets, setFantasyBets] = useRecoilState(fantasyBetsStore);

  // Get current selected bets
  var betArr: [];
  var stringBets = window.localStorage.getItem("bets");
  stringBets !== null ? (betArr = JSON.parse(stringBets!)) : (betArr = []);

  const toast = useToast();

  const bettingTypes = [
    "Points",
    "Rebounds",
    "Steals",
    "Free Throws Made",
    "Field Goals Made",
  ];

  var events: JSX.Element[] = [];
  var leagues: JSX.Element[] = [];

  const getFantasyLeagues = useQuery("getLeagues", () => {
    return API.getFantasyLeagues();
  });

  const fetchFantasyBettingLines = () => {
    setLoading(true);
    API.getFantasyBettingLines(leagueFilters, bettingTypeFilter)
      .then((data) => {
        setFantasyBettingLines(data.events || null);
      })
      .catch((error) => {
        if (error.response.status == "500") {
          setFantasyBettingLines(null);
        }
        toast({
          title: `Error fetching fantasy events.`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { mutate: placeBet } = useMutation(API.placeBet, {
    onSuccess: (data) => {
      setBets([]);

      props.refetchProfile();

      toast({
        title: `Bet successfully placed!`,
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
    onError: (error: any) => {
      toast({
        title: `Error placing bet`,
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
  });

  if (getFantasyLeagues.status === "success") {
    leagues = getFantasyLeagues.data.map((league: any) => {
      let icon: string = sportIconsFilter(league.sport.name);
      var selected = false;
      if (leagueFilters === league.id) {
        selected = true;
      }

      return (
        <Button
          key={league.id}
          variant="outline"
          color={selected ? "white" : "darkgray"}
          backgroundColor={selected ? org?.primaryColor : org?.secondaryColor}
          border={
            selected ? "2px solid white" : `2px solid ${org?.primaryColor}`
          }
          _hover={{
            color: "white",
            border: "2px solid white",
            transition: "none",
          }}
          m="1"
          height="80px"
          minWidth="80px"
          onClick={() => {
            setLeagueFilters(league.id);
            setLeagueId(league.id);
            history.push(`/fantasy/leagues/${league.id}`);
          }}
        >
          <Stack align="center" justify="center">
            <Text className={selected ? "" : "svgIcon"}>
              <Image src={`/icons/${icon}`} boxSize={"45px"} />
            </Text>
            <Text fontSize={"md"}>{league.name}</Text>
          </Stack>
        </Button>
      );
    });
  }

  const selectFantasyLine = (
    newBet: BettingLineDTO,
    startTime: string,
    awayTeam: string
  ) => {
    if (fantasyBets.some((bet) => bet.id === newBet.id)) {
      setFantasyBets(fantasyBets.filter((bet) => bet.id !== newBet.id));
    } else {
      const updatedBet = {
        ...newBet,
        startTime: startTime,
        awayTeam: awayTeam,
      };
      setFantasyBets([...fantasyBets, updatedBet]);
    }
  };

  if (fantasyBettingLines) {
    let renderedCount = 0;

    events = fantasyBettingLines.flatMap((event) => {
      const bettingLines = event.bettingLines;

      const linesToRender = bettingLines.slice(
        0,
        Math.max(0, 50 - renderedCount)
      );
      renderedCount += linesToRender.length;

      const findOpposingTeam = (
        teamAbbreviation: string,
        eventName: string
      ) => {
        const teams = eventName.split(" vs ");
        return teams.find((team) => team !== teamAbbreviation);
      };

      const validLines = linesToRender.filter(
        (line) => line.paramParticipant1 !== null
      );

      return validLines.map((line) => (
        <FantasyLine
          key={line.id}
          event={event}
          line={line}
          selected={fantasyBets.some((bet) => bet.id === line.id)}
          clickHandler={() => {
            selectFantasyLine(
              line,
              event.startTime,
              findOpposingTeam(
                line.paramParticipant1.abbreviation,
                event.name
              ) || ""
            );
          }}
        />
      ));
    });
  }

  useEffect(() => {
    setLeagueFilters(+path[3]);
  }, [path]);

  useEffect(() => {
    setBets(betArr);
    fetchFantasyBettingLines();
  }, [leagueFilters, bettingTypeFilter]);

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Box
          pb="0"
          h="100vh"
          style={{ position: "relative", overflowY: "auto" }}
          width={{ base: "100%", lg: "calc(200% - 335px)" }}
        >
          <Box minH="100vh">
            <Box p={2} w="100%">
              <AdCarouselFantasy bets={bets} setBets={setBets} />
            </Box>
            <Flex pl="4" alignItems="center" h="5rem" hideBelow="sm">
              <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                Fantasy
              </Text>
            </Flex>

            {leagues.length !== 0 && (
              <Box
                p="4"
                h="100px"
                w="100%"
                display="flex"
                alignItems="center"
                background={`${org?.secondaryColor} 0% 0% no-repeat padding-box`}
                boxShadow="inset 0px 4px 4px #00000029, 0px 4px 4px #00000029"
                overflowX="auto"
                overflowY="hidden"
              >
                {getFantasyLeagues.status === "success" && (
                  <HStack ml={2}>
                    <Button
                      variant="outline"
                      color={"darkgray"}
                      backgroundColor={org?.secondaryColor}
                      border={`2px solid ${org?.primaryColor}`}
                      _hover={{
                        color: "white",
                        border: "2px solid white",
                        transition: "none",
                      }}
                      m="1"
                      height="80px"
                      minWidth="80px"
                      onClick={() => {
                        setLeagueId(0);
                        history.push("/fantasy");
                      }}
                    >
                      <Stack align="center" justify="center">
                        <Text className={"svgIcon2"}>
                          <Image src="/icons/trophy.svg" boxSize={"45px"} />
                        </Text>
                        <Text fontSize={"md"}>Upcoming</Text>
                      </Stack>
                    </Button>
                    <HStack>{leagues}</HStack>
                  </HStack>
                )}
              </Box>
            )}

            <Box
              h="68px"
              w="100%"
              display="flex"
              alignItems="center"
              background={`${org?.secondaryColor}30 0% 0% no-repeat padding-box`}
              boxShadow="inset 0px 4px 4px #00000029"
              overflowX="auto"
            >
              <HStack ml="2">
                {bettingTypes.map((type: string) => (
                  <Button
                    key={type}
                    background={
                      bettingTypeFilter === type ? org?.secondaryColor : "white"
                    }
                    textColor={bettingTypeFilter === type ? "white" : "black"}
                    onClick={() => setBettingTypeFilter(type)}
                  >
                    {type}
                  </Button>
                ))}
              </HStack>
            </Box>

            {loading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10%",
                  height: "100%",
                }}
              >
                <BBSpinner />
              </div>
            )}

            {events.length === 0 && !loading && (
              <Box mt="20" p="10" background="white" h="50vh">
                <Center>
                  <Box>
                    <Image src="/event.png" w="141px" />
                  </Box>
                </Center>
                <Center mt="5">
                  <Alert colorScheme="whiteAlpha" width="auto">
                    <AlertIcon color="#FCD28D" />
                    <Center>
                      <Text as="b" align="center" color="black">
                        No Events Available For This Sport!
                      </Text>
                    </Center>
                  </Alert>
                </Center>
              </Box>
            )}

            {events.length !== 0 && !loading && (
              <Box
                borderRadius="0px 0px 5px 5px"
                p="20px"
                maxW="100%"
                position="relative"
                overflowY="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Grid
                  templateColumns={`repeat(auto-fit, minmax(300px, 1fr))`}
                  gap={4}
                >
                  {events}
                </Grid>
              </Box>
            )}
          </Box>
          <Box mb="60px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          left="100%"
          zIndex={1}
          hideBelow="lg"
        >
          <FantasyBetSlip
            placeBetLoading={placeBetLoading}
            setPlaceBetLoading={setPlaceBetLoading}
            placeBet={placeBet}
          />
        </Box>
      </SimpleGrid>
      <FantasyMobileBetSlip
        placeBetLoading={placeBetLoading}
        setPlaceBetLoading={setPlaceBetLoading}
        placeBet={placeBet}
        collapseSlip={props.collapseSlip}
        setCollapseSlip={props.setCollapseSlip}
      />
    </Box>
  );
}
