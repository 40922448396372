import {
  Box,
  Checkbox,
  Divider,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BettingLineDTO } from "models/BettingLine";
import { EventDTO } from "models/Events";
import { FiBarChart2 } from "react-icons/fi";
import { useRecoilValue } from "recoil";
import { orgState } from "store/Store";
import { relativeTime } from "utils/dateCalcs";

interface FantasyLineProps {
  selected: boolean;
  line: BettingLineDTO;
  event: EventDTO;
  clickHandler: () => void;
}

export default function FantasyLine(props: FantasyLineProps) {
  const org = useRecoilValue(orgState);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const playerTeamAbbreviation = props.line.paramParticipant1.abbreviation;
  const eventName = props.event.name;

  let opponentTeamAbbreviation = "";

  // Split the event name into teams
  const teams = eventName.split(" vs ");

  // Check which team the player is playing for and assign the opponent team abbreviation
  if (playerTeamAbbreviation === teams[0]) {
    opponentTeamAbbreviation = teams[1];
  } else if (playerTeamAbbreviation === teams[1]) {
    opponentTeamAbbreviation = teams[0];
  }

  const fantasyLineModal = (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Player Stats</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={2}>
            <Box>
              <Image
                src={props.line.paramParticipant1.imageUrl}
                fallbackSrc="https://cdn.nba.com/headshots/nba/latest/260x190/1628966.png"
                w="100px"
                mb={2}
              />
              <Text fontSize="lg">{props.line.paramParticipant1.name}</Text>
              <Text
                textColor="#7F7F7F"
                mb={2}
              >{`${props.line.paramParticipant1.abbreviation} - ${props.line.paramParticipant1.position}`}</Text>
              <Text textColor="#7F7F7F">
                {relativeTime(props.event.startTime, "en")}
              </Text>
              <Text textColor="#7F7F7F">vs {opponentTeamAbbreviation}</Text>
            </Box>
            <Box mx="auto">
              <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                {props.line.paramFloat1.toFixed(1)}
              </Text>
              <Box bg={org?.primaryColor}>
                <Divider />
              </Box>
              <Text fontSize="lg" textAlign="center" textColor="#7F7F7F">
                {props.line.betCategory}
              </Text>
              {props.line.paramParticipant1.injuryStatus && (
                <Text
                  textColor="#7F7F7F"
                  mt={4}
                >{`Injury Status - ${props.line.paramParticipant1.injuryStatus}`}</Text>
              )}
            </Box>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  return (
    <Box
      py="20px"
      px="30px"
      mx="auto"
      border="1px solid gray"
      w="300px"
      maxWidth="100%"
      borderRadius="6px"
      cursor="pointer"
      onClick={() => {
        props.clickHandler();
      }}
      bg={props.selected ? org?.primaryColor : "white"}
    >
      <Stack
        spacing={4}
        fontWeight="semibold"
        color={props.selected ? "white" : "black"}
        textAlign="center"
      >
        <HStack fontSize="24px">
          <FiBarChart2
            onClick={(e) => {
              e.stopPropagation();
              onOpen();
            }}
          />
          <Spacer />
          <Checkbox size="lg" isChecked={props.selected} colorScheme="gray" />
        </HStack>
        <Image
          src={props.line.paramParticipant1.imageUrl}
          fallbackSrc="https://cdn.nba.com/headshots/nba/latest/260x190/1628966.png"
          w="100px"
          ml="auto"
          mr="auto"
        />
        <Stack textAlign="center" spacing={0}>
          <Text fontSize="24px" fontWeight="bold">
            {props.line.paramParticipant1.name}
          </Text>
          <Text fontSize="18px">
            {`${props.line.paramParticipant1.abbreviation} - ${props.line.paramParticipant1.position}`}
          </Text>
          <Text>{relativeTime(props.event.startTime, "en")}</Text>
          <Text>vs {opponentTeamAbbreviation}</Text>
        </Stack>
        <Box
          color="white"
          bg={org?.secondaryColor}
          p="3"
          borderRadius="10px"
          fontSize="18px"
        >
          <HStack spacing="10px" textAlign="center">
            <Spacer />
            <Text>{props.line.paramFloat1.toFixed(1)}</Text>
            <Text color={org?.primaryColor}>|</Text>
            <Text>{props.line.betCategory}</Text>
            <Spacer />
          </HStack>
        </Box>
      </Stack>
      {fantasyLineModal}
    </Box>
  );
}
