import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Link,
  useColorModeValue as mode,
  Stack,
  Text,
} from "@chakra-ui/react";
import { memo, useEffect, useMemo, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import API from "services/API";
import {
  fantasyEnabledState,
  leagueIdState,
  orgState,
  sportsState,
} from "store/Store";
import { sportIconsFilter } from "utils/sportIconsFilter";
import BBSpinner from "./BBSpinner";
import { SportDTO } from "models/Sports";

interface SidebarProps {
  display?: any;
  width?: string;
  height?: string;
  bg?: string;
  border?: string;
  onClose?: () => void;
}

const Sidebar = (props: SidebarProps) => {
  const org = useRecoilValue(orgState);
  const selectedLeagueId = useRecoilValue(leagueIdState);
  const [leagueId, setLeagueId] = useState<number | null>(null);
  const [sports, setSports] = useRecoilState(sportsState);

  const fetchSports = () => {
    API.getSports()
      .then((data) => {
        console.log(data);
        setSports(data);
      })
      .catch()
      .finally();
  };

  useEffect(() => {
    setLeagueId(selectedLeagueId);
  }, [selectedLeagueId]);

  useEffect(() => {
    fetchSports();
  }, []);

  console.log("RE RENDER SIDEBAR");

  const sportElements = useMemo(() => {
    return sports !== null ? (
      <Accordion allowMultiple>
        {sports.map((sport) => {
          const icon = sportIconsFilter(sport.name);
          return (
            <AccordionItem key={sport.id} border="none">
              <AccordionButton
                _hover={{ bg: "gray.50" }}
                p={4}
                _expanded={{ bg: "blue.100", borderRadius: "md" }}
              >
                <Flex align="center" flex="1" textAlign="left">
                  <Image
                    src={`/icons/${icon}`}
                    alt={sport.name}
                    boxSize="24px"
                    mr={3}
                  />
                  <Text fontWeight="bold" fontSize="lg">
                    {sport.name.charAt(0).toUpperCase() + sport.name.slice(1)}
                  </Text>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Accordion allowMultiple>
                  {sport.categories?.map((category) => (
                    <AccordionItem key={category.id} border="none">
                      <AccordionButton
                        _hover={{ bg: "gray.50" }}
                        pl={6}
                        pr={4}
                        _expanded={{ bg: "#D9D9D9", borderRadius: "md" }}
                      >
                        <Flex align="center" flex="1" textAlign="left">
                          <Text fontWeight="semibold" fontSize="md">
                            {category.name.charAt(0).toUpperCase() +
                              category.name.slice(1)}
                          </Text>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={2} pl={8}>
                        {category.leagues?.map((league) => (
                          <Link
                            key={league.id}
                            display="block"
                            py="2"
                            pl="10"
                            borderRadius="md"
                            fontSize="sm"
                            fontWeight="medium"
                            color={
                              league.id === leagueId &&
                              sport.id === +selectedLeagueId
                                ? "white"
                                : "gray.700"
                            }
                            background={
                              league.id === leagueId &&
                              sport.id === +selectedLeagueId
                                ? org?.primaryColor
                                : "transparent"
                            }
                            _hover={{
                              bg: "#525252",
                            }}
                            as={RouterLink}
                            to={{
                              pathname: `/`,
                              search: `?leagueId=${league.id}`,
                              state: { leagueId: league.id },
                            }}
                            onFocus={() => setLeagueId(league.id)}
                            onClick={props.onClose}
                          >
                            <Text>
                              {league.name.charAt(0).toUpperCase() +
                                league.name.slice(1)}
                            </Text>
                          </Link>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    ) : (
      <Center>
        <BBSpinner />
      </Center>
    );
  }, [sports]);

  return (
    <Box
      width={props.width || "300px"}
      bg="white"
      borderRadius="3xl"
      py={6}
      px={4}
      ml={2}
      boxShadow="lg"
    >
      <Box mt={1} mb={4} textAlign="center">
        <Text fontSize="2xl" fontWeight="bold" color="black">
          SPORTS
        </Text>
      </Box>
      <Divider borderColor="gray.300" mb={4} />
      <Flex direction="column" flex="1">
        <Stack spacing={6} as="nav" aria-label="Sidebar Navigation">
          {sportElements}
        </Stack>
      </Flex>
    </Box>
  );
};

export default memo(Sidebar);
