import {
  Box,
  Button,
  Show,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Bet, SportBettingTypes } from "models/Bets";
import { BettingLineDTO } from "models/BettingLine";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import API from "services/API";
import baseballAd from "../dashboard/assets/baseballAd.jpg";
import basketballAd from "../dashboard/assets/basketballAd.jpg";
import boxingAd from "../dashboard/assets/boxingAd.jpg";
import footballAd from "../dashboard/assets/footballAd.jpg";
import golfAd from "../dashboard/assets/golfAd.jpg";
import hockeyAd from "../dashboard/assets/hockeyAd.jpg";
import soccerAd from "../dashboard/assets/soccerAd.jpg";
import tennisAd from "../dashboard/assets/tennisAd.jpg";
import otherAd from "../dashboard/assets/otherAd.jpg";
import { globalOddsFormatter } from "utils/oddsCalc";
import { useRecoilValue } from "recoil";
import { oddsDisplayState, languageState, orgState } from "store/Store";
import { relativeTime } from "utils/dateCalcs";
import { LanguageKey } from "utils/languageStrings";
import { EventDTO } from "models/Events";

interface AdCarouselProps {
  bets: Bet[];
  setBets: React.Dispatch<React.SetStateAction<Bet[]>>;
}

const AdCarousel = (props: AdCarouselProps) => {
  const [bets, setBets] = useState<Bet[]>([]);
  const org = useRecoilValue(orgState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const [events, setEvents] = useState<EventDTO[] | null>(null);

  const toast = useToast();

  const imgSrc = {
    baseball: baseballAd,
    golf: golfAd,
    fighting: boxingAd,
    tennis: tennisAd,
    soccer: soccerAd,
    football: footballAd,
    basketball: basketballAd,
    hockey: hockeyAd,
    other: otherAd,
  };

  let slide: JSX.Element[] = [];

  const fetchUpcomingEvents = () => {
    API.getUpcomingEvents()
      .then((data) => {
        setEvents(data.data);
      })
      .catch()
      .finally();
  };

  useEffect(() => {
    setBets(props.bets);
  }, [props.bets]);

  useEffect(() => {
    fetchUpcomingEvents();
  }, []);

  slide = (events ?? []).reduce((result: any, item: any) => {
    let bettingLines: BettingLineDTO[] = [];

    // Get moneylines from bettingLines
    bettingLines = item.bettingLines.map((bettingLine: any) => {
      if (bettingLine.betType === "moneyline") {
        return bettingLine;
      }
    });

    const moneyLines: BettingLineDTO[] = bettingLines.filter(
      (line) =>
        line !== undefined &&
        line.name.toLowerCase() !== "draw" &&
        line.active === true
    );

    // Ignore event if no moneyline entries found
    if (moneyLines.length < 2) {
      return result;
    }

    let sportName: string = item.sport.name;
    let url: string = "";

    // Assign background image based off sport
    for (const [key, value] of Object.entries(imgSrc)) {
      if (key.toLowerCase() === sportName.toLowerCase()) {
        url = value;
      }
    }

    // Check if sportName is not in imgSrc object
    if (url === "") {
      url = imgSrc.other;
    }

    result.push(
      <div key={item}>
        <Box
          m={1}
          border={`1px ${org?.secondaryColor} solid`}
          borderRadius="md"
          style={{
            background: `linear-gradient(90deg, rgba(0,0,0,1) 24%, rgba(0,0,0,0.7344187675070029) 44%, rgba(0,0,0,0) 100%), url(${url})`,
            backgroundSize: "cover",
          }}
          h="170"
          color="white"
        >
          <Stack p="2" spacing={0}>
            <Box>
              <Text
                textAlign="start"
                fontWeight="semibold"
                textShadow="0 2px 3px rgba(0, 0, 0, 0.3)"
              >
                {item.league.name}
              </Text>
            </Box>
            <Box>
              <Text
                textAlign="start"
                fontWeight="semibold"
                textShadow="0 2px 3px rgba(0, 0, 0, 0.3)"
              >
                {item.name}
              </Text>
            </Box>
            <Box top={20} position="absolute">
              <Text
                textAlign="start"
                fontWeight="semibold"
                textShadow="0 2px 3px rgba(0, 0, 0, 0.3)"
              >
                {relativeTime(item.startTime, language)}
              </Text>
            </Box>
          </Stack>
          <SimpleGrid
            bg="transparent"
            pr="2"
            pl="1"
            pb="2"
            mb="4"
            w="98%"
            position="absolute"
            bottom={0}
            columns={2}
            spacing={0}
          >
            <Button
              mr={1}
              background={
                props.bets.some((e) => e.bettingLineId === moneyLines[0].id)
                  ? org?.primaryColor
                  : "#4A4A4A"
              }
              _hover={{ bgColor: org?.secondaryColor }}
              color="white"
              borderRadius="none"
              onClick={() => {
                if (bets.some((e) => e.bettingLineId === moneyLines[0].id)) {
                  var newBets = bets;
                  const index = bets.findIndex((betItem) => {
                    return betItem.bettingLineId === moneyLines[0].id;
                  });

                  if (index > -1) {
                    newBets.splice(index, 1);
                  }

                  setBets([...newBets]);
                  props.setBets([...newBets]);
                  const betString = JSON.stringify(bets);
                  window.localStorage.setItem("bets", betString);
                  return;
                }

                let bet: Bet = {
                  event: item,
                  riskAmount: 0,
                  winAmount: 0,
                  bettingLineId: moneyLines[0].id,
                  externalId: moneyLines[0].externalId,
                  bettingType: SportBettingTypes.moneyline,
                  category: moneyLines[0].name,
                  odds: moneyLines[0].odds,
                  participant: moneyLines[0].paramParticipant1,
                  sport: item.sport.name,
                };
                newBets = bets;

                newBets.push(bet);
                setBets([...newBets]);
                props.setBets([...newBets]);
                const betString = JSON.stringify(bets);
                window.localStorage.setItem("bets", betString);

                /** Display Error Toast if bet slip is greater than 25 */
                if (bets.length > 25) {
                  toast({
                    title: `Error`,
                    description: "Only 25 Bets Allowed On A Ticket",
                    position: "bottom-right",
                    status: "error",
                    isClosable: true,
                  });
                }
              }}
            >
              <Stack spacing={0}>
                <Text fontSize="xs" fontWeight="semibold">
                  {moneyLines[0].name}
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={
                    props.bets.some((e) => e.bettingLineId === moneyLines[0].id)
                      ? "#FFFFFF"
                      : org?.primaryColor
                  }
                >
                  {globalOddsFormatter(oddsDisplay, moneyLines[0].odds)}
                </Text>
              </Stack>
            </Button>
            <Button
              ml={1}
              background={
                props.bets.some((e) => e.bettingLineId === moneyLines[1].id)
                  ? org?.primaryColor
                  : "#4A4A4A"
              }
              _hover={{ bgColor: org?.secondaryColor }}
              color="white"
              borderRadius="none"
              onClick={() => {
                if (bets.some((e) => e.bettingLineId === moneyLines[1].id)) {
                  var newBets = bets;
                  const index = bets.findIndex((betItem) => {
                    return betItem.bettingLineId === moneyLines[1].id;
                  });

                  if (index > -1) {
                    newBets.splice(index, 1);
                  }

                  setBets([...newBets]);
                  props.setBets([...newBets]);
                  const betString = JSON.stringify(bets);
                  window.localStorage.setItem("bets", betString);
                  return;
                }

                let bet: Bet = {
                  event: item,
                  riskAmount: 0,
                  winAmount: 0,
                  bettingLineId: moneyLines[1].id,
                  externalId: moneyLines[1].externalId,
                  bettingType: SportBettingTypes.moneyline,
                  category: moneyLines[1].name,
                  odds: moneyLines[1].odds,
                  participant: moneyLines[1].paramParticipant1,
                  sport: item.sport.name,
                };
                newBets = bets;

                newBets.push(bet);
                setBets([...newBets]);
                props.setBets([...newBets]);
                const betString = JSON.stringify(bets);
                window.localStorage.setItem("bets", betString);

                /** Display Error Toast if bet slip is greater than 25 */
                if (bets.length > 25) {
                  toast({
                    title: `Error`,
                    description: "Only 25 Bets Allowed On A Ticket",
                    position: "bottom-right",
                    status: "error",
                    isClosable: true,
                  });
                }
              }}
            >
              <Stack spacing={0}>
                <Text fontSize="xs" fontWeight="semibold">
                  {moneyLines[1].name}
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={
                    props.bets.some((e) => e.bettingLineId === moneyLines[1].id)
                      ? "#FFFFFF"
                      : org?.primaryColor
                  }
                >
                  {globalOddsFormatter(oddsDisplay, moneyLines[1].odds)}
                </Text>
              </Stack>
            </Button>
          </SimpleGrid>
        </Box>
      </div>
    );

    return result;
  }, []);

  return (
    <>
      {events !== null && (
        <>
          <Show above="md">
            <Carousel
              key={events!.length}
              showThumbs={false}
              showStatus={false}
              showArrows={true}
              showIndicators={false}
              stopOnHover
              autoPlay
              infiniteLoop
              centerMode
              centerSlidePercentage={33}
            >
              {slide}
            </Carousel>
          </Show>
          <Box display={{ base: "none", sm: "block", md: "none" }}>
            <Carousel
              key={events!.length}
              showThumbs={false}
              showStatus={false}
              showArrows={true}
              showIndicators={false}
              stopOnHover
              autoPlay
              infiniteLoop
              centerMode
              centerSlidePercentage={50}
            >
              {slide}
            </Carousel>
          </Box>
          <Show below="sm">
            <Carousel
              key={events!.length}
              showThumbs={false}
              showStatus={false}
              showArrows={true}
              showIndicators={false}
              stopOnHover
              autoPlay
              infiniteLoop
              centerMode
              centerSlidePercentage={100}
            >
              {slide}
            </Carousel>
          </Show>
        </>
      )}
    </>
  );
};

export default AdCarousel;
