import { Box, Card, CardBody, CardHeader, Image, Text } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { orgState } from "store/Store";

const LocationRestricted = () => {
  const org = useRecoilValue(orgState);

  return (
    <Box
      h="100vh"
      w="100%"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      bg={org?.secondaryColor}
    >
      <Card bg="#f6f6f640" m={2}>
        <CardHeader>
          <Image
            src="https://betbooth-public.s3.ca-central-1.amazonaws.com/org-branding/bb_logo_blue_white_text.png"
            alt="Bet Booth Logo"
            height="65px"
            m="auto"
          />
        </CardHeader>
        <CardBody>
          <Text fontSize="xl" color="white" mb={8}>
            Access to this website is prohibited in this region.
          </Text>
        </CardBody>
      </Card>
    </Box>
  );
};

export default LocationRestricted;
