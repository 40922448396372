import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Td,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouteLink, useHistory } from "react-router-dom";
import { Link } from "components/shared/Link";
import { Bet, SportBettingTypes } from "models/Bets";
import { BettingLineDTO } from "models/BettingLine";
import { EventDTO } from "models/Events";
import { ParticipantDTO } from "models/Participant";
import { SportDTO } from "models/Sports";
import { globalOddsFormatter } from "utils/oddsCalc";
import { relativeTime } from "utils/dateCalcs";
import { IconContext } from "react-icons";
import { sportIconsFilter } from "utils/sportIconsFilter";
import { FaChevronRight } from "react-icons/fa";
import { useRecoilValue } from "recoil";
import { oddsDisplayState, languageState, orgState } from "store/Store";
import { strings, LanguageKey } from "utils/languageStrings";

interface EventProps {
  bets: Bet[];
  event: EventDTO;
  sport: SportDTO;
  clickHandler: (
    bettingLineId: string,
    externalId: string,
    odds: number,
    betType: SportBettingTypes,
    betDescription: string,
    participant: ParticipantDTO
  ) => void;
  moreWagersVisible: boolean;
}

function Event(props: EventProps) {
  let event = props.event;
  let sport = props.sport;
  let today = new Date();
  let startTime = event.startTime == null ? 0 : event.startTime;
  const boxHeight = "45px";

  const org = useRecoilValue(orgState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const language = useRecoilValue<LanguageKey>(languageState);

  const history = useHistory();

  let icon: string = sportIconsFilter(sport.name);

  // Parse teams

  let startTimeString =
    startTime == null ? "N/A" : relativeTime(startTime, language);

  // For individual sports (tennis, fighting), look for
  // individual participants, otherwise look for teams

  const teamA: ParticipantDTO | null =
    event.eventParticipants.filter((eventParticipant) => {
      return eventParticipant.teamSide === "Away";
    })[0]?.participant ?? null;

  const teamB: ParticipantDTO | null =
    event.eventParticipants.filter((eventParticipant) => {
      return eventParticipant.teamSide === "Home";
    })[0]?.participant ?? null;

  // Parse betting lines

  let moneyLineA: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return (
        bettingLine.betType === "moneyline" &&
        (bettingLine.paramParticipant1?.id === teamA?.id ||
          bettingLine.externalId.includes("away"))
      );
    }
  )[0];
  let moneyLineB: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return (
        bettingLine.betType === "moneyline" &&
        (bettingLine.paramParticipant1?.id === teamB?.id ||
          bettingLine.externalId.includes("home"))
      );
    }
  )[0];
  let spreadA: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return (
        bettingLine.betType === "spread" &&
        (bettingLine.paramParticipant1?.id === teamA?.id ||
          bettingLine.externalId.includes("away"))
      );
    }
  )[0];
  let spreadB: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return (
        bettingLine.betType === "spread" &&
        (bettingLine.paramParticipant1?.id === teamB?.id ||
          bettingLine.externalId.includes("home"))
      );
    }
  )[0];
  let totalOver: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return bettingLine.betCategory === "Total Score Over";
    }
  )[0];
  let totalUnder: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return bettingLine.betCategory === "Total Score Under";
    }
  )[0];

  // Show Selected Bets

  var moneyLineASelected = false;
  var moneyLineBSelected = false;
  var spreadASelected = false;
  var spreadBSelected = false;
  var overSelected = false;
  var underSelected = false;

  if (props.bets.some((e) => e.bettingLineId === (moneyLineA?.id ?? ""))) {
    moneyLineASelected = true;
  }
  if (props.bets.some((e) => e.bettingLineId === (moneyLineB?.id ?? ""))) {
    moneyLineBSelected = true;
  }
  if (props.bets.some((e) => e.bettingLineId === (spreadA?.id ?? ""))) {
    spreadASelected = true;
  }
  if (props.bets.some((e) => e.bettingLineId === (spreadB?.id ?? ""))) {
    spreadBSelected = true;
  }
  if (props.bets.some((e) => e.bettingLineId === (totalOver?.id ?? ""))) {
    overSelected = true;
  }
  if (props.bets.some((e) => e.bettingLineId === (totalUnder?.id ?? ""))) {
    underSelected = true;
  }

  if (teamA === null || teamB === null) {
    return <></>;
  }

  return (
    <Box>
      <Flex pt="2" justify={"space-between"}>
        <Box
          w="100%"
          cursor={props.moreWagersVisible ? "pointer" : "default"}
          onClick={() =>
            props.moreWagersVisible && history.push(`/events/${event.id}`)
          }
        >
          <Box display="flex" height={10} mb={1}>
            <IconContext.Provider
              value={{
                style: { marginRight: "8px" },
              }}
            >
              <Text className="svg1">
                <Image src={`/icons/${icon}`} h="50px" display="inline-block" />
              </Text>
            </IconContext.Provider>

            <Text
              color="black"
              fontSize="md"
              fontWeight="semibold"
              display="flex"
              alignItems="center"
              mt="2"
            >
              {teamA?.name}
            </Text>
          </Box>

          <Flex alignItems="center">
            <Text ml="18px" color="black">
              @
            </Text>
            <Divider mx="auto" h="1px" bgColor="#DFDFDF" />
          </Flex>

          <Box display="flex" mt="1">
            <IconContext.Provider
              value={{
                style: { marginRight: "8px" },
              }}
            >
              <Text className="svg2" mt="-3">
                <Image src={`/icons/${icon}`} h="50px" display="inline-block" />
              </Text>
            </IconContext.Provider>

            <Text color="black" mr="2" fontWeight="semibold">
              {teamB?.name}
            </Text>
          </Box>
        </Box>

        <Flex justify="right">
          <Box w="120px" ml="3" mr="3" mt="2" hideBelow="md">
            {spreadA != null && spreadB != null ? (
              <VStack spacing={2}>
                <Center
                  background={spreadASelected ? org?.primaryColor : "#FFFFFF"}
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  cursor="pointer"
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                  _hover={
                    spreadASelected
                      ? {}
                      : { background: `${org?.secondaryColor}30` }
                  }
                  onClick={() => {
                    props.clickHandler(
                      spreadA!.id,
                      spreadA!.externalId,
                      spreadA!.odds,
                      SportBettingTypes.spread,
                      spreadA!.name,
                      spreadA!.paramParticipant1
                    );
                  }}
                >
                  <VStack spacing={0}>
                    <Text color={spreadASelected ? "white" : "black"} mb="-1.5">
                      {spreadA!.paramFloat1 > 0 && "+"}
                      {spreadA!.paramFloat1}
                    </Text>

                    <Text color={spreadASelected ? "white" : org?.primaryColor}>
                      {`${
                        spreadA!.odds >= 2 && oddsDisplay === "American"
                          ? "+"
                          : ""
                      }${globalOddsFormatter(oddsDisplay, spreadA!.odds)}`}
                    </Text>
                  </VStack>
                </Center>

                <Box
                  background={spreadBSelected ? org?.primaryColor : "#FFFFFF"}
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  cursor="pointer"
                  _hover={
                    spreadBSelected
                      ? {}
                      : { background: `${org?.secondaryColor}30` }
                  }
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                  onClick={() => {
                    props.clickHandler(
                      spreadB!.id,
                      spreadB!.externalId,
                      spreadB!.odds,
                      SportBettingTypes.spread,
                      spreadB!.name,
                      spreadB!.paramParticipant1
                    );
                  }}
                >
                  <VStack spacing={0}>
                    <Text color={spreadBSelected ? "white" : "black"} mb="-1.5">
                      {spreadB!.paramFloat1 > 0 && "+"}
                      {spreadB!.paramFloat1}
                    </Text>

                    <Text color={spreadBSelected ? "white" : org?.primaryColor}>
                      {`${
                        spreadB!.odds >= 2 && oddsDisplay === "American"
                          ? "+"
                          : ""
                      }${globalOddsFormatter(oddsDisplay, spreadB!.odds)}`}
                    </Text>
                  </VStack>
                </Box>
              </VStack>
            ) : (
              <VStack>
                <Center
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                >
                  <Text mb="20%" fontSize="2xl" color="black">
                    _
                  </Text>
                </Center>
                <Center
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                >
                  <Text mb="20%" fontSize="2xl" color="black">
                    _
                  </Text>
                </Center>
              </VStack>
            )}
          </Box>

          <Box w="120px" ml="3" mr="3" mt="2">
            {moneyLineA != null && moneyLineB != null ? (
              <VStack>
                <Center
                  height={boxHeight}
                  width={"120px"}
                  background={
                    moneyLineASelected ? org?.primaryColor : "#FFFFFF"
                  }
                  borderRadius="md"
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  _hover={
                    moneyLineASelected
                      ? {}
                      : { background: `${org?.secondaryColor}30` }
                  }
                  fontWeight="bold"
                  cursor="pointer"
                  onClick={() => {
                    props.clickHandler(
                      moneyLineA!.id,
                      moneyLineA!.externalId,
                      moneyLineA!.odds,
                      SportBettingTypes.moneyline,
                      moneyLineA!.name,
                      moneyLineA!.paramParticipant1
                    );
                  }}
                >
                  <Text
                    color={moneyLineASelected ? "white" : org?.primaryColor}
                    align="right"
                  >
                    {`${
                      moneyLineA!.odds >= 2 && oddsDisplay === "American"
                        ? "+"
                        : ""
                    }${globalOddsFormatter(oddsDisplay, moneyLineA!.odds)}`}
                  </Text>
                </Center>

                <Center
                  background={
                    moneyLineBSelected ? org?.primaryColor : "#FFFFFF"
                  }
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  cursor="pointer"
                  borderWidth="1px"
                  _hover={
                    moneyLineBSelected
                      ? {}
                      : { background: `${org?.secondaryColor}30` }
                  }
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                  onClick={() => {
                    props.clickHandler(
                      moneyLineB!.id,
                      moneyLineB!.externalId,
                      moneyLineB!.odds,
                      SportBettingTypes.moneyline,
                      moneyLineB!.name,
                      moneyLineB!.paramParticipant1
                    );
                  }}
                >
                  <Text
                    color={moneyLineBSelected ? "white" : org?.primaryColor}
                    align="right"
                  >
                    {`${
                      moneyLineB!.odds >= 2 && oddsDisplay === "American"
                        ? "+"
                        : ""
                    }${globalOddsFormatter(oddsDisplay, moneyLineB!.odds)}`}
                  </Text>
                </Center>
              </VStack>
            ) : (
              <VStack>
                <Center
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                >
                  <Text mb="20%" fontSize="2xl" color="black">
                    _
                  </Text>
                </Center>
                <Center
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                >
                  <Text mb="20%" fontSize="2xl" color="black">
                    _
                  </Text>
                </Center>
              </VStack>
            )}
          </Box>

          <Box w="120px" ml="3" mr="3" mt="2" hideBelow="md">
            {totalOver != null && totalUnder != null ? (
              <VStack>
                <Center
                  background={overSelected ? org?.primaryColor : "#FFFFFF"}
                  borderRadius="md"
                  height={boxHeight}
                  cursor="pointer"
                  width={"120px"}
                  _hover={
                    overSelected
                      ? {}
                      : { background: `${org?.secondaryColor}30` }
                  }
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                  onClick={() => {
                    props.clickHandler(
                      totalOver!.id,
                      totalOver!.externalId,
                      totalOver!.odds,
                      SportBettingTypes.total,
                      totalOver!.name,
                      totalOver!.paramParticipant1
                    );
                  }}
                >
                  <VStack spacing={0}>
                    <Text color={overSelected ? "white" : "black"} mb="-1.5">
                      {`O ${totalOver!.paramFloat1}`}
                    </Text>

                    <Text color={overSelected ? "white" : org?.primaryColor}>
                      {`${
                        totalOver!.odds >= 2 && oddsDisplay === "American"
                          ? "+"
                          : ""
                      }${globalOddsFormatter(oddsDisplay, totalOver!.odds)}`}
                    </Text>
                  </VStack>
                </Center>

                <Center
                  background={underSelected ? org?.primaryColor : "#FFFFFF"}
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  cursor="pointer"
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  _hover={
                    underSelected
                      ? {}
                      : { background: `${org?.secondaryColor}30` }
                  }
                  fontWeight="bold"
                  onClick={() => {
                    props.clickHandler(
                      totalUnder!.id,
                      totalUnder!.externalId,
                      totalUnder!.odds,
                      SportBettingTypes.total,
                      totalUnder!.name,
                      totalUnder!.paramParticipant1
                    );
                  }}
                >
                  <VStack spacing={0}>
                    <Text color={underSelected ? "white" : "black"} mb="-1.5">
                      {`U ${totalUnder!.paramFloat1}`}
                    </Text>

                    <Text color={underSelected ? "white" : org?.primaryColor}>
                      {`${
                        totalUnder!.odds >= 2 && oddsDisplay === "American"
                          ? "+"
                          : ""
                      }${globalOddsFormatter(oddsDisplay, totalUnder!.odds)}`}
                    </Text>
                  </VStack>
                </Center>
              </VStack>
            ) : (
              <VStack>
                <Center
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                >
                  <Text mb="20%" fontSize="2xl" color="black">
                    _
                  </Text>
                </Center>
                <Center
                  borderRadius="md"
                  height={boxHeight}
                  width={"120px"}
                  borderWidth="1px"
                  borderColor={org?.primaryColor}
                  fontWeight="bold"
                >
                  <Text mb="20%" fontSize="2xl" color="black">
                    _
                  </Text>
                </Center>
              </VStack>
            )}
          </Box>
        </Flex>
      </Flex>
      <HStack justifyContent="space-between" px={4} pb={2}>
        <Text
          fontWeight="bold"
          display="block"
          color={org?.primaryColor}
          ml="35px"
        >
          {startTimeString}
        </Text>
        {props.moreWagersVisible && (
          <Text maxW="md" fontWeight="medium">
            <RouteLink to={`/events/${event.id}`}>
              <Link color={org?.secondaryColor} display="inline-flex">
                {strings[language].sportsbook.more}
                <FaChevronRight
                  color={org?.secondaryColor}
                  style={{ marginLeft: "4", marginTop: "5" }}
                />
              </Link>
            </RouteLink>
          </Text>
        )}
      </HStack>
      <Divider mt="0px" mb="5px" h="1px" bgColor="#A0A0A0" />
    </Box>
  );
}

export default Event;
