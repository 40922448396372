import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Text,
  useColorModeValue as mode,
  useDisclosure,
} from "@chakra-ui/react";
import { LoginForm } from "components/auth/LoginForm";
import { RegisterModal } from "components/auth/RegisterModal";
import { NavbarLink } from "components/shared/NavbarLink";
import { GeoComplyContext, GeoContextType } from "context/geoComplyContext";
import { FR, US } from "country-flag-icons/react/3x2";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FaChevronDown, FaCog } from "react-icons/fa";
import { FiCheckCircle, FiCircle, FiLogOut } from "react-icons/fi";
import { Link as RouteLink, useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  denominationSymbolState,
  fantasyEnabledState,
  languageState,
  layoutState,
  oddsDisplayState,
  orgState,
  userState,
} from "store/Store";
import { currencyFormatter } from "utils/formatters";
import { strings, LanguageKey } from "utils/languageStrings";

interface NavbarProps {
  width?: string;
  height?: string;
  bg?: string;
  border?: string;
}

export const Navbar = (props: NavbarProps) => {
  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();
  const {
    isOpen: isRegisterOpen,
    onOpen: onRegisterOpen,
    onClose: onRegisterClose,
  } = useDisclosure();
  const history = useHistory();
  const location = useLocation();
  const user = useRecoilValue(userState);
  const org = useRecoilValue(orgState);
  const fantasyEnabled = useRecoilValue(fantasyEnabledState);
  const { setGeoComplyData }: GeoContextType = GeoComplyContext();
  const [clock, setClock] = useState(dayjs().format("HH:mm:ss A"));
  const [oddsDisplay, setOddsDisplay] = useRecoilState(oddsDisplayState);
  const [layout, setLayout] = useRecoilState(layoutState);
  const [language, setLanguage] = useRecoilState<LanguageKey>(languageState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);

  var bettingActive = false;
  var mybetsActive = false;
  var accountActive = false;
  var helpActive = false;
  var fantasyActive = false;

  if (location.pathname.includes("history")) {
    mybetsActive = true;
  } else if (location.pathname.includes("account")) {
    accountActive = true;
  } else if (location.pathname.includes("help")) {
    helpActive = true;
  } else if (location.pathname.includes("fantasy")) {
    fantasyActive = true;
  } else {
    bettingActive = true;
  }

  useEffect(() => {
    setInterval(() => {
      setClock(dayjs().format("HH:mm:ss A"));
    }, 1000);
  }, []);

  return (
    <Box
      bg={"black"}
      height="82px"
      mt={process.env.REACT_APP_AFRICAN_ENVIRONMENT !== "1" ? 3 : 0}
      display={{ base: "none", "2xl": "flex" }}
      justifyContent={{ lg: "center" }}
      position="fixed"
      top="0"
      width="100%"
      zIndex="100"
      borderBottom="1px solid #c8c8c8"
    >
      <Flex flex="1">
        <Flex flex="1" pb="4" px="4">
          <RouteLink to={"/"}>
            <LinkBox as={Box} pt="3">
              <LinkOverlay>
                <Image
                  src={org?.logoUrl}
                  alt="Bet Booth Logo"
                  height="55px"
                  hideBelow="2xl"
                />
                <Image
                  src={org?.badgeUrl}
                  alt="Bet Booth Badge"
                  height="55px"
                  minWidth="65px"
                  hideFrom="2xl"
                />
              </LinkOverlay>
            </LinkBox>
          </RouteLink>
        </Flex>

        <HStack mr="6" pt="1">
          <HStack
            color="black"
            mr={{ base: "none", "2xl": "12" }}
            spacing="10"
            fontWeight="bold"
            fontSize="xl"
          >
            {!fantasyEnabled && (
              <NavbarLink
                active={bettingActive}
                label={strings[language].navbar.sportsbook}
                to="/"
              />
            )}
            {fantasyEnabled && (
              <NavbarLink
                active={fantasyActive}
                label={"Fantasy"}
                to="/fantasy"
              />
            )}
            <NavbarLink
              active={mybetsActive}
              label={
                fantasyEnabled ? "My Entries" : strings[language].navbar.myBets
              }
              to="/history"
            />
            <NavbarLink
              active={accountActive}
              label={strings[language].navbar.account}
              to="/account"
            />
            <NavbarLink
              active={helpActive}
              label={strings[language].navbar.help}
              to="/help"
            />
          </HStack>

          <Spacer />

          {window.localStorage.loggedIn &&
            window.localStorage.loggedIn !== "false" && (
              <>
                <Button
                  color="white"
                  background="rgba(255, 255, 255, 0.2)"
                  display="block"
                  fontWeight="md"
                  fontSize="xl"
                  py="2"
                  h="10"
                  w="130px"
                  onClick={() => {
                    history.push("/account");
                  }}
                >
                  <SimpleGrid columns={1} textAlign="left">
                    <Text fontSize="xs" mt="-1.5">
                      {strings[language].navbar.accountBalance}
                    </Text>
                    <Text fontSize="lg" fontWeight="semibold" color="white">
                      {denominationSymbol +
                        currencyFormatter.format(user?.balance ?? 0)}
                    </Text>
                  </SimpleGrid>
                </Button>

                <Button
                  background={org?.secondaryColor}
                  color={org?.secondaryColor ? "black" : "white"}
                  as={RouteLink}
                  to="/account"
                  hideBelow="2xl"
                >
                  {strings[language].navbar.deposit}
                </Button>

                <IconButton
                  onClick={() => {
                    delete localStorage.jwt;
                    delete localStorage.loggedIn;
                    delete window.localStorage.oddsDisplay;
                    delete window.localStorage.layout;
                    delete window.localStorage.language;
                    history.push("/");
                    window.location.reload();
                  }}
                  icon={<FiLogOut />}
                  aria-label="log out"
                  color="white"
                  background="rgba(255, 255, 255, 0.2)"
                />

                <Box w="110px" textAlign="center" color="white">
                  <Text fontWeight="semibold">{clock}</Text>
                </Box>
              </>
            )}

          {(!window.localStorage.loggedIn ||
            window.localStorage.loggedIn === "false") && (
            <>
              <Button
                background={
                  org?.tertiaryColor !== ""
                    ? org?.tertiaryColor
                    : org.primaryColor
                }
                color={org?.tertiaryColor !== "" ? "black" : "white"}
                onClick={onRegisterOpen}
                w="150px"
              >
                {strings[language].navbar.register}
              </Button>
              <Button
                color="white"
                background="rgba(255, 255, 255, 0.2)"
                w="120px"
                onClick={() => {
                  onLoginOpen();
                }}
              >
                {strings[language].navbar.login}
              </Button>
            </>
          )}

          {!fantasyEnabled && (
            <Menu>
              <MenuButton
                as={Button}
                color="white"
                background="rgba(255, 255, 255, 0.2)"
                rightIcon={<FaChevronDown />}
                leftIcon={<FaCog />}
              ></MenuButton>
              <MenuList>
                <MenuGroup title="Odds Format">
                  <MenuItem
                    closeOnSelect={false}
                    onClick={() => {
                      window.localStorage.oddsDisplay = "Decimal";
                      setOddsDisplay("Decimal");
                    }}
                  >
                    {oddsDisplay === "Decimal" && (
                      <FiCheckCircle color="green" />
                    )}
                    {oddsDisplay !== "Decimal" && <FiCircle />}
                    <Text ml="2">Decimal</Text>
                  </MenuItem>
                  <MenuItem
                    closeOnSelect={false}
                    onClick={() => {
                      window.localStorage.oddsDisplay = "American";
                      setOddsDisplay("American");
                    }}
                  >
                    {oddsDisplay === "American" && (
                      <FiCheckCircle color="green" />
                    )}
                    {oddsDisplay !== "American" && <FiCircle />}
                    <Text ml="2">American</Text>
                  </MenuItem>
                </MenuGroup>
                {/* <MenuDivider /> */}
                {/* <MenuGroup title="Layout">
                  <MenuItem
                    closeOnSelect={false}
                    onClick={() => {
                      window.localStorage.layout = "European";
                      setLayout("European");
                    }}
                  >
                    {layout === "European" && <FiCheckCircle color="green" />}
                    {layout !== "European" && <FiCircle />}
                    <Text ml="2">European</Text>
                  </MenuItem>
                  <MenuItem
                    closeOnSelect={false}
                    onClick={() => {
                      window.localStorage.layout = "American";
                      setLayout("American");
                    }}
                  >
                    {layout === "American" && <FiCheckCircle color="green" />}
                    {layout !== "American" && <FiCircle />}
                    <Text ml="2">American</Text>
                  </MenuItem>
                </MenuGroup> */}
              </MenuList>
            </Menu>
          )}

          <Menu>
            <MenuButton
              color="white"
              background="rgba(255, 255, 255, 0.2)"
              as={Button}
              rightIcon={<FaChevronDown />}
              leftIcon={
                language === "en" ? (
                  <US width="20px" height="30px" />
                ) : (
                  <FR width="20px" height="30px" />
                )
              }
            >
              {language === "en" ? "EN" : "FR"}
            </MenuButton>
            <MenuList>
              <MenuItem
                closeOnSelect={false}
                onClick={() => {
                  window.localStorage.language = "en";
                  setLanguage("en");
                }}
              >
                <US width="20px" height="30px" /> <Text ml="10px">English</Text>
              </MenuItem>
              <MenuItem
                closeOnSelect={false}
                onClick={() => {
                  window.localStorage.language = "fr";
                  setLanguage("fr");
                }}
              >
                <FR width="20px" height="30px" /> <Text ml="10px">French</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>

      <Modal isOpen={isLoginOpen} onClose={onLoginClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings[language].navbar.login}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="6">
            <LoginForm admin={false} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <RegisterModal isOpen={isRegisterOpen} onClose={onRegisterClose} />
    </Box>
  );
};
function layoutDisplayState(layoutDisplayState: any): [any, any] {
  throw new Error("Function not implemented.");
}
