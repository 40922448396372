export const strings = {
  en: {
    login: {
      emailPlaceholder: "Email or User Id",
      password: "Password",
      forgotPassword: "Forgot Password",
    },
    signup: {
      verifyEmail: "Verify Email",
      verifyPhone: "Verify Phone",
      country: "Country",
      email: "Email Address",
      oneTimePassword: "One Time Password",
      emailError: "Please enter a valid email address.",
      phoneError: "Please enter a valid phone number.",
      phoneMessage:
        "A text message was sent to your phone. Please enter the one time code provided in the message below to complete registration",
      emailMessage:
        "An email was sent to your account. Please enter the one time code provided in the email below to complete registration",
      passwordError: "Password must be at least 8 characters long",
      completeRegistration: "Complete Registration",
    },
    signupSuccess: {
      success: "Signup was successful",
      userId: "User Id",
      saveDetails: "Make sure to save your login details below",
      copyClipboard: "Copy Details to Clipboard",
      copiedClipboard: "Copied to Clipboard",
      saveFile: "Save to File",
      continue: "Continue to Bet Booth",
    },
    navbar: {
      sportsbook: "Sportsbook",
      sports: "Sports",
      myBets: "My Bets",
      account: "Account",
      help: "Help",
      register: "Register",
      login: "Log In",
      accountBalance: "Account Balance",
      deposit: "Make a Deposit",
    },
    sidebar: {
      home: "Home",
      allSports: "All Sports",
    },
    sportsbook: {
      spread: "Spread",
      moneyline: "Money Line",
      total: "Total",
      more: "More Wagers",
      upcomingEvents: "Upcoming Events",
      gameLines: "Game Lines",
      backToSport: "Back To ",
      backToEvents: "Back To Events",
      noAdditionalWagers: "No additional wagers available",
      noEventsLeague: "No events found for this league",
      noEventsSport: "No events found for this sport",
      noEventsUpcoming: "No upcoming events found",
    },
    betSlip: {
      betSlip: "Bet Slip",
      straight: "Straight",
      parlay: "Parlay",
      wager: "Wager",
      win: "To Win",
      parlayWager: "Parlay Wager",
      parlayWin: "Parlay Win",
      removeAll: "Remove All Selections",
      oddsChange: "Accept odds change",
      oddsChangeHeader: "When odds move, your potential winnings change",
      oddsChangeBody:
        "The odds on a bet may move in or out of your favor before the bet is placed. These movements can change your potential winnings. As a default, you’ll need to manually accept any decreased odds on your bets. If you’d rather automatically accept any and all odds movements, switch the toggle.",
      okay: "Okay",
      emptyBetSlip: "Your bet slip is empty!",
      emptyStraightBetsWarning: "Please enter a wager amount to place bet",
      emptyParlayBetWarning: "Please enter a parlay amount to place bet",
      parlayWarning:
        "Some of your bets cannot be combined into a single parlay.",
      parlayBetsWarning:
        "Parlays require a minimum of 2 bets selected. Please add additional bets to place a parlay.",
      minimumWagerWarning:
        "Your wager is less than the minimum required wager amount ",
      maximumWagerWarning: "Your wager is more than the maximum wager amount ",
      login: "Log In to Place Bets",
      placeStraightBet: (betListLength: number) =>
        `Place Straight Bet (${betListLength})`,
      placeParlayBet: (betListLength: number) =>
        `Place ${betListLength}-Leg Parlay Bet`,
      parlayHeader: (betListLength: number) => `${betListLength}-Leg Parlay`,
    },
    confirmBetModal: {
      odds: "Odds",
      name: "Name",
      total: "Total",
      subTotal: "Subtotal",
      totalOdds: "Total Odds",
      question: "Are you sure you want to place this bet?",
      confirm: "Confirm",
      cancel: "Cancel",
      placeBet: "Place Bet",
      confirmBet: "Confirm Bet",
      oddsQuestion: "Would you like to place your bet with these updated odds?",
    },
    myBets: {
      myBets: "My Bets",
      all: "All",
      won: "Won",
      lost: "Lost",
      unresolved: "Unresolved",
      voided: "Voided",
      refunded: "Refunded",
      wager: "Wager",
      payout: "Payout",
    },
    account: {
      addFunds: "Add Funds",
      accountInfo: "Account Info",
      transactionHistory: "Transaction History",
      depositMethod: "Choose a deposit method",
      creditCard: "Credit Card",
    },
    profile: {
      firstName: "First Name",
      lastName: "Last Name",
      gender: "Gender",
      phoneNumber: "Phone Number",
      addressLine1: "Address Line 1",
      addressLine2: "Address Line 2",
      city: "City",
      state: "State",
      postalCode: "Postal Code",
    },
    transactions: {
      noItems: "There are no transactions.",
      transId: "Trans ID",
      createdAT: "Created At",
      method: "Method",
      amount: "Amount",
      transactionId: "Transaction id",
      dateTime: "Date/time",
      betTicketId: "Bet Ticket ID",
    },
    depositForm: {
      deposit: "Deposit",
      depositSelector: "Choose the amount you wish to deposit",
      custom: "Custom",
      name: "Cardholder Name",
      number: "Card Number",
      expiryDate: "Expiry Date",
      securityCode: "Security Code",
      statement1: "Please enter your credit / debit card information.",
      statement2: "Will be deposited in your Bet Booth account",
      button: "Deposit Funds",
    },
    depositSuccess: {
      depositSuccessful: "Deposit Successful",
      depositAmount: "has been deposited in your Bet Booth account.",
      accountBalance: "Your account balance is now",
      referenceNumber: "Reference Number:",
      continue: "Continue to Sportsbook",
    },
    support: {
      support: "Please contact",
    },
    dateCalcs: {
      today: "Today",
      tomorrow: "Tomorrow",
      yesterday: "Yesterday",
      last: "Last",
    },
  },
  fr: {
    login: {
      emailPlaceholder: "E-mail ou Identifiant",
      password: "Mot de pas",
      forgotPassword: "Mot de passe oublié",
    },
    signup: {
      verifyEmail: "Vérifier l'adresse e-mail",
      verifyPhone: "Vérifier le numéro de téléphone",
      country: "Pays",
      email: "Adresse e-mail",
      oneTimePassword: "Mot de passe à usage unique",
      emailError: "Veuillez entrer une adresse e-mail valide.",
      phoneError: "Veuillez entrer un numéro de téléphone valide.",
      phoneMessage:
        "Un message texte a été envoyé à votre téléphone. Veuillez saisir le code à usage unique fourni dans le message ci-dessous pour finaliser l'inscription.",
      emailMessage:
        "Un e-mail a été envoyé à votre compte. Veuillez saisir le code à usage unique fourni dans l'e-mail ci-dessous pour finaliser l'inscription.",
      passwordError: "Le mot de passe doit comporter au moins 8 caractères.",
      completeRegistration: "Finaliser l'inscription",
    },
    signupSuccess: {
      success: `L'inscription a réussi`,
      userId: "Identifiant",
      saveDetails:
        "Assurez-vous de sauvegarder vos informations de connexion ci-dessous",
      copyClipboard: "Copier les détails dans le presse-papiers",
      copiedClipboard: `Copié dans le presse-papiers`,
      saveFile: "Enregistrer dans un fichier",
      continue: "Continuer vers Bet Booth",
    },
    navbar: {
      sportsbook: "Paris Sportifs",
      sports: "Paris Sportifs",
      myBets: "Mes Paris",
      account: "Mon Compte",
      help: "Assistance",
      register: "Inscription",
      login: "Se Connecter",
      accountBalance: "Compte Principal",
      deposit: "Faire un dépôt",
    },
    sidebar: {
      home: "Accueil",
      allSports: "Paris Sportifs",
    },
    sportsbook: {
      spread: "Handicap",
      moneyline: "Victoire",
      total: "Total",
      more: "Plus de paris",
      upcomingEvents: "Événements à venir",
      gameLines: "Options de jeu",
      backToSport: "Retour au ",
      backToEvents: "Retour aux Événements",
      noAdditionalWagers: "Aucun pari supplémentaire disponible",
      noEventsLeague: "Aucun événement trouvé pour cette ligue",
      noEventsSport: "Aucun événement trouvé pour ce sport",
      noEventsUpcoming: "Pas d'événements à venir trouvés",
    },
    betSlip: {
      betSlip: "Coupon De Pari",
      straight: "Simple",
      parlay: "Combiné",
      wager: "Mise",
      win: "Gagner",
      parlayWager: "Mise du combiné",
      parlayWin: "Gain du combiné",
      removeAll: "Supprimer toutes les sélections",
      oddsChange: "Accepter les changements de cotes",
      oddsChangeHeader:
        "Quand les cotes varient, vos gains potentiels fluctuent",
      oddsChangeBody:
        "Les cotes d'un pari peuvent évoluer en votre faveur ou non avant que le pari ne soit placé. Ces changements peuvent modifier vos gains potentiels. Par défaut, vous devrez manuellement accepter toute diminution des cotes sur vos paris. Si vous préférez accepter automatiquement tous les mouvements de cotes, activez le commutateur.",
      okay: "D'accord",
      emptyBetSlip: "Votre coupon de pari est vide",
      emptyStraightBetsWarning:
        "Veuillez saisir un montant de mise pour placer le pari",
      emptyParlayBetWarning:
        "Veuillez saisir un montant de pari combiné pour placer le pari",
      parlayWarning:
        "Certains de vos paris ne peuvent pas être combinés dans un seul pari combiné.",
      parlayBetsWarning:
        "Les paris combinés nécessitent un minimum de 2 sélections. Veuillez ajouter des paris supplémentaires pour placer un pari combiné.",
      minimumWagerWarning:
        "Votre mise est inférieure au montant minimum requis de mise de ",
      maximumWagerWarning: "Votre mise dépasse le montant maximum de mise ",
      login: "Connectez-vous pour placer des paris",
      placeStraightBet: (betListLength: number) =>
        `Placer un pari simple (${betListLength})`,
      placeParlayBet: (betListLength: number) =>
        `Placer un pari combiné à ${betListLength} sélections`,
      parlayHeader: (betListLength: number) =>
        `Combiné à ${betListLength} sélections`,
    },
    confirmBetModal: {
      odds: "Les cotes",
      name: "Nom",
      total: "Total",
      subTotal: "Sous-total",
      totalOdds: "Total des cotes",
      question: "Êtes-vous sûr de vouloir placer ce pari?",
      confirm: "Confirmer",
      cancel: "Annuler",
      placeBet: "Placer le pari",
      confirmBet: "Confirmer le pari",
      oddsQuestion:
        "Souhaitez-vous placer votre pari avec ces cotes actualisées?",
    },
    myBets: {
      myBets: "Historique Des Paris",
      all: "Tous",
      won: "Gagné",
      lost: "Perdu",
      unresolved: "Non résolu",
      voided: "Annulé",
      refunded: "Remboursé",
      wager: "Mise",
      payout: "Gain",
    },
    account: {
      addFunds: "Faire un dépôt",
      accountInfo: "Détails du compte",
      transactionHistory: "Historique des transactions",
      depositMethod: "Types de systèmes de paiement",
      creditCard: "Carte de crédit",
    },
    profile: {
      firstName: "Prenom",
      lastName: "Nom",
      gender: "Sexe",
      phoneNumber: "Téléphone",
      addressLine1: "Adresse de résidence permanente 1",
      addressLine2: "Adresse de résidence permanente 2",
      city: "Ville",
      state: "État",
      postalCode: "Code postal",
    },
    transactions: {
      noItems: `Il n'y a pas de transactions.`,
      transId: "Id de la trans",
      createdAT: "Créé à",
      method: "Méthode",
      amount: "Montant",
      transactionId: "Transaction id",
      dateTime: "Date/time",
      betTicketId: "Id du ticket de pari",
    },
    depositForm: {
      deposit: "Dépôt",
      depositSelector: "Choisissez le montant que vous souhaitez déposer",
      custom: "Personnalisé",
      name: "Nom du titulaire de la carte",
      number: "Numéro de carte",
      expiryDate: "Date d'expiration",
      securityCode: "Code de sécurité",
      statement1:
        "Veuillez entrer les informations de votre carte de crédit / débit",
      statement2: "Sera déposé dans votre compte Bet Booth",
      button: "Déposer",
    },
    depositSuccess: {
      depositSuccessful: "Dépôt réussi",
      depositAmount: "ont été déposés dans votre compte Bet Booth.",
      accountBalance: "Votre solde de compte est maintenant de",
      referenceNumber: "Numéro de référence:",
      continue: "Continuer vers les Paris Sportifs",
    },
    support: {
      support: "Veuillez contacter",
    },
    dateCalcs: {
      today: "Aujourd'hui",
      tomorrow: "Demain",
      yesterday: "Hier",
      last: "Dernier",
    },
  },
};

export type LanguageStrings = typeof strings;

export type LanguageKey = keyof LanguageStrings;
