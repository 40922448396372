import { Button, Box } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useRecoilValue } from "recoil";
import { languageState, orgState } from "store/Store";
import { LanguageKey, strings } from "utils/languageStrings";

interface FiltersProps {
  selectedFilter: string;
  setStatusFilter: Dispatch<SetStateAction<string>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const Filters = ({
  selectedFilter,
  setStatusFilter,
  setCurrentPage,
}: FiltersProps) => {
  const language = useRecoilValue<LanguageKey>(languageState);
  const org = useRecoilValue(orgState);

  var filterNames: string[] = [
    strings[language].myBets.all,
    strings[language].myBets.won,
    strings[language].myBets.lost,
  ];

  return (
    <Box ml="2">
      {filterNames.map((status: string, i) => (
        <Button
          key={i}
          background={
            selectedFilter === status ? org?.secondaryColor : "transparent"
          }
          borderWidth="1px"
          borderStyle="solid"
          w={{ base: "70px", sm: "85px", md: "142px", xl: "175px" }}
          borderColor={
            selectedFilter === status ? "white" : org?.secondaryColor
          }
          color={selectedFilter === status ? "white" : org?.secondaryColor}
          _hover={
            selectedFilter === status
              ? {
                  background: org?.secondaryColor,
                  borderColor: "white",
                  color: "white",
                }
              : {
                  background: "transparent",
                  borderColor: org?.secondaryColor,
                  color: org?.secondaryColor,
                }
          }
          m="1"
          mx={{ base: "2", md: "5" }}
          onClick={() => {
            setStatusFilter(status);
            setCurrentPage(1);
          }}
        >
          {status}
        </Button>
      ))}
    </Box>
  );
};
