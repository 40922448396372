import axios from "axios";
import { SportDTO } from "models/Sports";
import { PlaceBetTicketDTO } from "models/Bets";
import {
  UserLogin,
  UserProfileDTO,
  UserEmail,
  SetPasswordDTO,
  DepositFundsDTO,
  AcceptTermsDTO,
  ValidateMFA,
  UserLoginResponseMFADTO,
  UserLoginResponseDTO,
  RegisterOneClickRequest,
  RegisterOneClickResponse,
  RegisterUserRequest,
  VerifyUserRequest,
  ValidateLoginToken,
} from "models";
import { TransactionResponseDTO } from "models/Transactions";
import { EventDTO } from "models/Events";
import { OrgDTO } from "models/Orgs";
import { PlaceFantasyBetTicketDTO } from "models/Fantasy";

export default class API {
  static login = async (data: UserLogin) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/login/mobile`,
      data
    );
    return response;
  };

  static loginMFA = async (data: UserLogin) => {
    const { data: response } = await axios.post<UserLoginResponseMFADTO>(
      `${process.env.REACT_APP_API_URL}/users/login/2fa`,
      data
    );
    return response;
  };

  static validateLoginToken = async (data: ValidateLoginToken) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/kiosks/${data.kioskId}/validateLoginToken`,
      data,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return response;
  };

  static registerOneClick = async (data: RegisterOneClickRequest) => {
    const { data: response } = await axios.post<RegisterOneClickResponse>(
      `${process.env.REACT_APP_API_URL}/users/signup/mobile`,
      data
    );
    return response;
  };

  static registerUser = async (data: RegisterUserRequest) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/signup/mobile`,
      data
    );
    return response;
  };

  static verifyUser = async (data: VerifyUserRequest) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/signup/mobile/verify`,
      data
    );
    return response;
  };

  static validateMFA = async (data: ValidateMFA) => {
    const { data: response } = await axios.post<UserLoginResponseDTO>(
      `${process.env.REACT_APP_API_URL}/users/login/2fa/validate`,
      data
    );
    return response;
  };

  static resetPassword = async (data: UserEmail) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/resetPassword`,
      data
    );
    return response;
  };

  static setPassword = async (data: SetPasswordDTO) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/setPassword`,
      data
    );
    return response;
  };

  static updateUser = async (data: AcceptTermsDTO) => {
    const { data: response } = await axios.put(
      `${process.env.REACT_APP_API_URL}/users/profile`,
      data,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return response;
  };

  static ipLocationCheck = async () => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/ipLocationCheck`
    );
    return response;
  };

  static getProfile = async () => {
    const { data } = await axios.get<UserProfileDTO>(
      `${process.env.REACT_APP_API_URL}/users/profile`,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return data;
  };

  static getOrgBySlug = async (slug: string) => {
    const { data } = await axios.get<OrgDTO>(
      `${process.env.REACT_APP_API_URL}/orgs/slug/${slug}`
    );
    return data;
  };

  static getSports = async () => {
    const { data } = await axios.get<SportDTO[]>(
      `${process.env.REACT_APP_API_URL}/sports`,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return data;
  };

  static getSport = async (sportId: number) => {
    const { data } = await axios.get<SportDTO>(
      `${process.env.REACT_APP_API_URL}/sports/${sportId}`
    );
    return data;
  };

  static getEvent = async (eventId: string) => {
    const { data } = await axios.get<EventDTO>(
      `${process.env.REACT_APP_API_URL}/events/${eventId}`,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return data;
  };

  static getEvents = async (
    page: number,
    limit: number,
    sportId?: number,
    leagueId?: number,
    categoryId?: number
  ) => {
    const params = new URLSearchParams({
      page: page.toString(),
      limit: limit.toString(),
    });
  
    if (leagueId !== undefined && leagueId !== 0) {
      params.append('leagueId', leagueId.toString());
    }
  
    if (categoryId !== undefined && categoryId !== 0) {
      params.append('categoryId', categoryId.toString());
    }

    if (sportId !== undefined && sportId !== 0) {
      params.append('sportId', sportId.toString());
    }
  
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/events/upcoming?${params.toString()}`,
      {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.jwt,
        },
      }
    );
    return data;
  };
  


  static getUpcomingEvents = async () => {
    const { data } = await axios.get<{ data: EventDTO[] }>(
      `${process.env.REACT_APP_API_URL}/events/upcoming?page=1&limit=20`,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );

    return data;
  };

  static placeBet = async (bets: PlaceBetTicketDTO) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/bets`,
      bets,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return response;
  };

  static getBetTickets = async (
    pageLimit: number,
    currentPage: number,
    totalPages: number,
    status: string
  ) => {
    var url = `${process.env.REACT_APP_API_URL}/bet-tickets?`;

    url += `limit=${pageLimit}&`;

    if (totalPages !== 1) {
      url += `page=${currentPage}`;
    } else {
      url += `page=${totalPages}`;
    }

    if (status === "Gagné") {
      status = "Won";
    }

    if (status === "Perdu") {
      status = "Lost";
    }

    if (status !== "All" && status !== "Tous") {
      url += `&status=${status.toLowerCase()}`;
    }

    console.log(url);

    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + window.localStorage.jwt,
      },
    });
    return response;
  };

  /* FANTASY */

  static getFantasyBettingLines = async (
    leagueId: number,
    category: string
  ) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/leagues/${leagueId}/fantasy?category=${category}`,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return data;
  };

  static getUpcomingFantasyEvents = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/events/latest/fantasy`,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return data;
  };

  static getFantasyLeagues = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/leagues/fantasy`,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return data;
  };

  static placeFantasyBet = async (bets: PlaceFantasyBetTicketDTO) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/bets`,
      bets,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return response;
  };

  /* TRANSACTIONS */

  static allTransactions = async (
    pageLimit: number,
    currentPage: number,
    totalPages: number
  ) => {
    var url = `${process.env.REACT_APP_API_URL}/transactions/all?`;

    url += `limit=${pageLimit}&`;

    if (totalPages !== 1) {
      url += `page=${currentPage}`;
    } else {
      url += `page=${totalPages}`;
    }

    console.log(url);

    const { data: response } = await axios.get<TransactionResponseDTO>(url, {
      headers: {
        Authorization: "Bearer " + window.localStorage.jwt,
      },
    });
    return response;
  };

  static transactions = async (pageLimit: number, currentPage: number) => {
    var url = `${process.env.REACT_APP_API_URL}/transactions?`;

    url += `limit=${pageLimit}&`;
    url += `page=${currentPage}`;

    console.log(url);

    const { data: response } = await axios.get<TransactionResponseDTO>(url, {
      headers: {
        Authorization: "Bearer " + window.localStorage.jwt,
      },
    });
    return response;
  };

  static chargeDeposit = async (payment: DepositFundsDTO) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/chargeDeposit`,
      payment,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return response;
  };

  /* DOCUMENTS */

  static getLatestDocument = async (slug: string) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/document_types/${slug}/latest`,
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.jwt,
        },
      }
    );
    return data;
  };
}
