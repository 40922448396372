import { Container, useDisclosure } from "@chakra-ui/react";
import { Account } from "components/account/Account";
import { Deposit } from "components/account/Deposit";
import { Help } from "components/help/Help";
import { MyBets } from "components/mybets/MyBets";
import { OrgDTO } from "models/Orgs";
import React, { memo, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import API from "services/API";
import {
  denominationSymbolState,
  fantasyEnabledState,
  languageState,
  layoutState,
  loginTime,
  oddsDisplayState,
  orgState,
  tcAccepted,
  userState,
} from "store/Store";
import Events from "./dashboard/Events";
import Home from "./dashboard/Home";
import MoreWagers from "./dashboard/MoreWagers";
import EuropeanEvents from "./dashboard/european/Events";
import EuropeanHome from "./dashboard/european/Home";
import EuropeanMoreWagers from "./dashboard/european/MoreWagers";
import Fantasy from "./fantasy/Fantasy";
import UpcomingFantasyEvents from "./fantasy/UpcomingFantasyEvents";
import { HouseRules } from "./help/HouseRules";

function BaseRoutes() {
  const history = useHistory();
  const [user, setUser] = useRecoilState(userState);
  const [oddsDisplay, setOddsDisplay] = useRecoilState(oddsDisplayState);
  const [fantasyEnabled, setFantasyEnabled] =
    useRecoilState(fantasyEnabledState);
  const [collapseSlip, setCollapseSlip] = useState<boolean>(true);
  const [loading, setLoading] = React.useState(true);
  const [layout, setLayout] = useRecoilState(layoutState);
  const [language, setLanguage] = useRecoilState(languageState);
  const [defaultOrg, setDefaultOrg] = useState<OrgDTO | null>();
  const [org, setOrg] = useRecoilState(orgState);
  const [denominationSymbol, setDenominationSymbol] = useRecoilState(
    denominationSymbolState
  );

  const fetchProfile = () => {
    API.getProfile()
      .then((data) => {
        setUser(data);
        if (data?.org) {
          setOrg(data.org);
          setDenominationSymbol(data?.org.denominationSymbol);
          setFantasyEnabled(data?.org.fantasy);
          setOddsDisplay(
            window.localStorage.oddsDisplay
              ? window.localStorage.oddsDisplay
              : data?.org.oddsDisplay
          );
          setLayout(
            window.localStorage.layout
              ? window.localStorage.layout
              : data?.org.layout
          );
          setLanguage(
            window.localStorage.language
              ? window.localStorage.language
              : data?.org.language
          );
        } else {
          setOrg(defaultOrg!);
          setDenominationSymbol(defaultOrg?.denominationSymbol || "$");
          setFantasyEnabled(defaultOrg?.fantasy || false);
          setOddsDisplay(
            window.localStorage.oddsDisplay
              ? window.localStorage.oddsDisplay
              : defaultOrg?.oddsDisplay || "American"
          );
          setLayout(
            window.localStorage.layout
              ? window.localStorage.layout
              : defaultOrg?.layout || "American"
          );
          setLanguage(
            window.localStorage.language
              ? window.localStorage.language
              : defaultOrg?.language || "en"
          );
        }
      })
      .catch((error) => {
        if (defaultOrg) {
          setOrg(defaultOrg);
          setDenominationSymbol(defaultOrg.denominationSymbol);
          setFantasyEnabled(defaultOrg.fantasy);
          setOddsDisplay(
            window.localStorage.oddsDisplay
              ? window.localStorage.oddsDisplay
              : defaultOrg.oddsDisplay
          );
          setLayout(
            window.localStorage.layout
              ? window.localStorage.layout
              : defaultOrg.layout
          );
          setLanguage(
            window.localStorage.language
              ? window.localStorage.language
              : defaultOrg.language
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Container maxW="container.xl">
      <Switch>
        <Route
          path="/fantasy"
          exact
          render={(props) => (
            <UpcomingFantasyEvents
              refetchProfile={fetchProfile}
              collapseSlip={collapseSlip}
              setCollapseSlip={setCollapseSlip}
            />
          )}
        />

        <Route
          path="/history"
          exact
          render={(props) => (
            <MyBets
              collapseSlip={collapseSlip}
              setCollapseSlip={setCollapseSlip}
            />
          )}
        />

        <Route
          path="/account"
          exact
          render={(props) => (
            <Account
              collapseSlip={collapseSlip}
              setCollapseSlip={setCollapseSlip}
            />
          )}
        />

        <Route
          path="/help"
          exact
          render={(props) => (
            <Help
              collapseSlip={collapseSlip}
              setCollapseSlip={setCollapseSlip}
            />
          )}
        />

        <Route path="/house-rules" exact render={(props) => <HouseRules />} />

        <Route path="/deposit" exact render={(props) => <Deposit />} />

        {layout === "American" ? (
          <Route
            path="/"
            render={(props) => (
              <EuropeanEvents
                refetchProfile={fetchProfile}
                leagueFilter={history.location.state as number}
                collapseSlip={collapseSlip}
                setCollapseSlip={setCollapseSlip}
              />
            )}
          />
        ) : (
          <Route
            path="/"
            render={(props) => (
              <EuropeanEvents
                refetchProfile={fetchProfile}
                leagueFilter={history.location.state as number}
                collapseSlip={collapseSlip}
                setCollapseSlip={setCollapseSlip}
              />
            )}
          />
        )}

        <Route
          path="/fantasy/leagues/:leagueId"
          render={(props) => (
            <Fantasy
              refetchProfile={fetchProfile}
              collapseSlip={collapseSlip}
              setCollapseSlip={setCollapseSlip}
            />
          )}
        />

        {layout === "American" ? (
          <Route
            path="/events/:eventId"
            render={(props) => (
              <EuropeanMoreWagers
                refetchProfile={fetchProfile}
                collapseSlip={collapseSlip}
                setCollapseSlip={setCollapseSlip}
              />
            )}
          />
        ) : (
          <Route
            path="/events/:eventId"
            render={(props) => (
              <EuropeanMoreWagers
                refetchProfile={fetchProfile}
                collapseSlip={collapseSlip}
                setCollapseSlip={setCollapseSlip}
              />
            )}
          />
        )}

        {layout === "American" ? (
          <Route
            path="/home"
            render={(props) => (
              <Home
                refetchProfile={fetchProfile}
                collapseSlip={collapseSlip}
                setCollapseSlip={setCollapseSlip}
              />
            )}
          />
        ) : (
          <Route
            path="/home"
            render={(props) => (
              <EuropeanHome
                refetchProfile={fetchProfile}
                collapseSlip={collapseSlip}
                setCollapseSlip={setCollapseSlip}
              />
            )}
          />
        )}

        {layout === "American" ? (
          <Route
            path="/"
            exact
            render={(props) => (
              <EuropeanHome
                refetchProfile={fetchProfile}
                collapseSlip={collapseSlip}
                setCollapseSlip={setCollapseSlip}
              />
            )}
          />
        ) : (
          <Route
            path="/"
            exact
            render={(props) => (
              <EuropeanHome
                refetchProfile={fetchProfile}
                collapseSlip={collapseSlip}
                setCollapseSlip={setCollapseSlip}
              />
            )}
          />
        )}
      </Switch>
    </Container>
  );
}

export default memo(BaseRoutes);
