// Calculate the payout based on Metric odds
export function calcPayout(risk: number, odds: number) {
  return risk * odds;
}

// Calculate the risk based on Metric odds
export function calcRisk(winAmount: number, odds: number) {
  return winAmount / odds;
}

export function metricToAmericanOdds(odds: number) {
  if (odds >= 2) {
    return (odds - 1) * 100;
  } else {
    return -100 / (odds - 1);
  }
}

export function globalOddsFormatter(oddsDisplay: string, odds: number) {
    if (oddsDisplay === "American") {
      return metricToAmericanOdds(odds).toFixed(0);
    } else {
      return odds.toFixed(2).replace('.', ',');
    }
}
