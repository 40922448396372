import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { MobileNavLink } from "components/shared/MobileNavLink";
import { Dispatch, SetStateAction } from "react";
import { AiOutlineTrophy } from "react-icons/ai";
import { FiHelpCircle, FiUser } from "react-icons/fi";
import { IoReceiptOutline } from "react-icons/io5";
import { PiPencilLine } from "react-icons/pi";
import { useRecoilValue } from "recoil";
import { strings, LanguageKey } from "../../utils/languageStrings";
import {
  betsState,
  fantasyBetsStore,
  fantasyEnabledState,
  languageState,
  orgState,
} from "store/Store";

interface MobileBottomBarProps {
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

export const MobileBottomBar = (props: MobileBottomBarProps) => {
  const bets = useRecoilValue(betsState);
  const fantasyEnabled = useRecoilValue(fantasyEnabledState);
  const fantasyBets = useRecoilValue(fantasyBetsStore);
  const org = useRecoilValue(orgState);
  const language = useRecoilValue<LanguageKey>(languageState);

  return (
    <Flex
      align="center"
      justify="space-between"
      px={{ base: 1, md: 14, xl: 20 }}
      background={org?.secondaryColor}
      boxShadow="0 -2px 4px rgba(0, 0, 0, 0.1)"
      display={{ base: "flex", "2xl": "none" }}
      position="fixed"
      w="100%"
      h="64px"
      zIndex={1000}
      bottom="-2px"
    >
      {!fantasyEnabled && (
        <MobileNavLink
          label={strings[language].navbar.sports}
          href="/"
          icon={<AiOutlineTrophy />}
          active={false}
          onClick={() => {
            props.setCollapseSlip!(true);
          }}
        />
      )}

      {fantasyEnabled && (
        <MobileNavLink
          label={"Fantasy"}
          href="/fantasy"
          icon={<AiOutlineTrophy />}
          active={false}
          onClick={() => {
            props.setCollapseSlip!(true);
          }}
        />
      )}

      <MobileNavLink
        label={fantasyEnabled ? "My Entries" : strings[language].navbar.myBets}
        href="/history"
        icon={<PiPencilLine />}
        active={false}
        onClick={() => {
          props.setCollapseSlip!(true);
        }}
      />

      <Box
        mb={4}
        p={4}
        border={`4px solid ${org?.primaryColor}`}
        borderRadius="50%"
        bg={org?.secondaryColor}
        zIndex={1000}
        onClick={() => {
          props.setCollapseSlip!(!props.collapseSlip);
        }}
        position="relative"
      >
        <HStack>
          <IoReceiptOutline color="white" fontSize="25px" />
          {bets + fantasyBets.length > 0 && (
            <Flex
              boxSize="5"
              borderRadius="50%"
              bg="red"
              color="white"
              ml="-6"
              mt="-6"
              justify="center"
              align="center"
              position="absolute"
              right="5px"
            >
              <Text>{bets + fantasyBets.length}</Text>
            </Flex>
          )}
        </HStack>
      </Box>

      <MobileNavLink
        label={strings[language].navbar.account}
        href="/account"
        icon={<FiUser />}
        active={false}
        onClick={() => {
          props.setCollapseSlip!(true);
        }}
      />

      <MobileNavLink
        label={strings[language].navbar.help}
        href="/help"
        icon={<FiHelpCircle />}
        active={false}
        onClick={() => {
          props.setCollapseSlip!(true);
        }}
      />
    </Flex>
  );
};
