import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  HStack,
  Divider,
} from "@chakra-ui/react";
import {
  Bet,
  OddsChangeData,
  PlaceBetDTO,
  PlaceBetTicketDTO,
} from "models/Bets";
import { Dispatch, SetStateAction } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { UseMutateFunction } from "react-query";
import { useRecoilValue } from "recoil";
import { languageState, oddsDisplayState, orgState } from "store/Store";
import { strings, LanguageKey } from "utils/languageStrings";
import { globalOddsFormatter } from "utils/oddsCalc";
import { BetType } from "./BetSlip";
import { BettingLineDTO } from "models/BettingLine";

interface OddsChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  oddsChangeData: OddsChangeData;
  placeBetLoading: boolean;
  setPlaceBetLoading: Dispatch<SetStateAction<boolean>>;
  bets: Bet[];
  setBets: Dispatch<SetStateAction<Bet[]>>;
  placeBet: UseMutateFunction<any, unknown, PlaceBetTicketDTO, unknown>;
}

const OddsChangeModal = (props: OddsChangeModalProps) => {
  const language = useRecoilValue<LanguageKey>(languageState);
  const org = useRecoilValue(orgState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);

  const { message, req, staleLines, updatedLines } = props.oddsChangeData;

  var updatedBettingLines: JSX.Element[] = [];
  const updatedBettingLineOdds: Bet[] = [];

  const clickPlaceBet = () => {
    props.setPlaceBetLoading(true);

    var dto: PlaceBetTicketDTO = {
      bets: [],
      riskAmount: req.riskAmount,
      geoPacket: req.geoPacket,
      ticketType: req.ticketType === BetType.parlay ? "Parlay" : "Straight",
    };

    props.bets.forEach((userBet) => {
      const outdatedLines = staleLines.find(
        (staleLine) => staleLine.externalId === userBet.externalId
      );

      if (outdatedLines) {
        const correspondingUpdatedLine = updatedLines.find(
          (updatedLine) => updatedLine.externalId === userBet.externalId
        );

        userBet.odds = correspondingUpdatedLine!.odds;

        updatedBettingLineOdds.push(userBet);
      } else {
        updatedBettingLineOdds.push(userBet);
      }
    });

    dto.bets = updatedBettingLineOdds.map((bet) => {
      var dto: PlaceBetDTO = {
        riskAmount: bet.riskAmount,
        expectedProfit: bet.winAmount,
        bettingLineId: bet.bettingLineId,
        externalId: bet.externalId,
        odds: bet.odds,
      };

      return dto;
    });
    props.placeBet(dto);
    props.onClose();
  };

  updatedBettingLines = updatedLines.map((line: BettingLineDTO) => {
    const outdatedLine = staleLines.find(
      (staleLine) => staleLine.externalId === line.externalId
    );

    return (
      <Box>
        <HStack justifyContent="space-between" px={4}>
          <Box>
            <Text>{line.name}</Text>
          </Box>
          <HStack>
            <Text>{globalOddsFormatter(oddsDisplay, outdatedLine!.odds)}</Text>
            <Text color={org?.primaryColor}>
              <BiSolidRightArrow />
            </Text>
            <Text color={org?.primaryColor}>
              {globalOddsFormatter(oddsDisplay, line.odds)}
            </Text>
          </HStack>
        </HStack>
        <Divider my={1} />
      </Box>
    );
  });

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg="white" mt="10%">
          <ModalHeader
            bg={org?.primaryColor}
            borderTopRadius="md"
            color="white"
            textAlign="center"
          >
            {strings[language].confirmBetModal.confirmBet}
          </ModalHeader>
          <ModalBody color="black">
            <Box>
              <Text align="center" color="black" fontWeight="semibold" my={2}>
                {message}
              </Text>
              <Text align="center" mb={4}>
                {strings[language].confirmBetModal.oddsQuestion}
              </Text>
              {updatedBettingLines}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              color="#000000"
              w="100%"
              onClick={() => {
                props.onClose();
              }}
            >
              {strings[language].confirmBetModal.cancel}
            </Button>
            <Button
              _hover={{ background: org?.primaryColor, opacity: 0.5 }}
              color="white"
              w="100%"
              background={org?.primaryColor}
              onClick={() => {
                clickPlaceBet();
              }}
              ml={3}
            >
              {strings[language].confirmBetModal.placeBet}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default OddsChangeModal;
