import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AdCarousel from "components/shared/AdCarousel";
import BBSpinner from "components/shared/BBSpinner";
import { Footer } from "components/shared/Footer";
import { EventDTO } from "models/Events";
import { EventsApiResponse } from "models/EventsResponse";
import { ParticipantDTO } from "models/Participant";
import { SportDTO } from "models/Sports";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import API from "services/API";
import { languageState, orgState } from "store/Store";
import { LanguageKey, strings } from "utils/languageStrings";
import { Bet, OddsChangeData, SportBettingTypes } from "../../../models/Bets";
import { BetSlip } from "../betslip/BetSlip";
import { MobileBetSlip } from "../betslip/MobileBetSlip";
import OddsChangeModal from "../betslip/OddsChangeModal";
import Event from "./Event";
import Sidebar from "components/shared/Sidebar";

interface EventsProps {
  refetchProfile: () => void;
  leagueFilter?: number;
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

interface EventsParams {
  sportId: string;
}

export default function Events(props: EventsProps) {
  const { sportId } = useParams<EventsParams>();
  const location = useLocation();

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const leagueIdParam = searchParams.get("leagueId");
  const categoryIdParam = searchParams.get("categoryId");
  const sportsIdParam = searchParams.get("sportId");

  const leagueIdNum = leagueIdParam ? parseInt(leagueIdParam, 10) : 0;
  const categoryIdNum = categoryIdParam ? parseInt(categoryIdParam, 10) : 0;
  const sportsIdNum = sportsIdParam ? parseInt(sportsIdParam, 10) : 0;
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const [bets, setBets] = useState<Bet[]>([]);
  const [sportFilters, setSportFilters] = useState(0);
  const [leagueId, setLeagueId] = useState(0);
  const [sports, setSports] = useState<SportDTO[] | null>(null);
  const [events, setEvents] = useState<EventDTO[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [oddsChangeData, setOddsChangeData] = useState<OddsChangeData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const org = useRecoilValue(orgState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const history = useHistory();
  const toast = useToast();
  const ref = useRef<null | HTMLDivElement>(null);

  // Pagination state
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10); // Use 10 events at a time
  const [totalPages, setTotalPages] = useState<number>(1);

  // Get current selected bets
  const stringBets = window.localStorage.getItem("bets");
  const betArr: Bet[] = stringBets ? JSON.parse(stringBets) : [];

  const fetchEvents = () => {
    setLoading(true);
    API.getEvents(
      page,
      limit,
      sportsIdNum !== 0 ? sportsIdNum : 0,
      leagueIdNum !== 0 ? leagueIdNum : undefined,
      categoryIdNum !== 0 ? categoryIdNum : undefined
    )
      .then((response: EventsApiResponse) => {
        setEvents(response.data);
        setTotalPages(response.meta.total_pages);
      })
      .catch(() => {
        toast({
          title: `Error fetching events.`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSports = () => {
    API.getSports()
      .then((data) => setSports(data))
      .catch();
  };

  useEffect(() => {
    setLeagueId(props.leagueFilter || 0);
    setSportFilters(+sportId);
    setBets(betArr);
  }, [sportId, props.leagueFilter]);

  /* Auto scroll to selected league */
  useEffect(() => {
    if (!ref.current) {
      return;
    } else {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, [leagueId]);

  useEffect(() => {
    fetchSports();
    fetchEvents();
  }, [sportId, leagueIdNum, categoryIdNum, page, sportsIdNum]);

  const { mutate: placeBet } = useMutation(API.placeBet, {
    onSuccess: (data) => {
      setBets([]);

      props.refetchProfile();

      toast({
        title: `Bet successfully placed!`,
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
    onError: (error: any) => {
      if (error.response.status == "409") {
        setOddsChangeData(error.response.data);
        onOpen();
      }

      toast({
        title: `Error placing bet`,
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
  });

  // Group events by league
  const leaguesMap = new Map<number, EventDTO[]>(); // Key type is number
  if (events) {
    events.forEach((event) => {
      const leagueId = event.league?.id ?? "";
      if (leaguesMap.has(leagueId)) {
        leaguesMap.get(leagueId)!.push(event);
      } else {
        leaguesMap.set(leagueId, [event]);
      }
    });
  }

  // Create league components
  const leagueComponents = Array.from(leaguesMap.entries()).map(
    ([leagueId, leagueEvents]) => {
      const league = leagueEvents[0].league; // All events have the same league
      const eventRows = leagueEvents.map((event) => {
        // Ensure the oddsOverride field is overriding the odds field
        const eventOverride: EventDTO = {
          ...event,
          bettingLines: event.bettingLines.map((bl) => {
            return {
              ...bl,
              odds: bl.oddsOverride != null ? bl.oddsOverride : bl.odds,
            };
          }),
        };
        return (
          <Box bg="white" key={event.id}>
            <Box>
              <Event
                bets={bets}
                event={eventOverride}
                sport={event.sport} // Pass the correct sport
                moreWagersVisible={true}
                clickHandler={(
                  bettingLineId: string,
                  externalId: string,
                  odds: number,
                  betType: SportBettingTypes,
                  category: string,
                  participant: ParticipantDTO
                ) => {
                  if (bets.some((e) => e.bettingLineId === bettingLineId)) {
                    const newBets = bets.filter(
                      (betItem) => betItem.bettingLineId !== bettingLineId
                    );

                    setBets([...newBets]);
                    const betString = JSON.stringify(newBets);
                    window.localStorage.setItem("bets", betString);
                    return;
                  }

                  let bet: Bet = {
                    event: event,
                    riskAmount: 0,
                    winAmount: 0,
                    bettingLineId: bettingLineId,
                    externalId: externalId,
                    bettingType: betType,
                    category: category,
                    odds: odds,
                    participant: participant,
                    sport: event.sport.name,
                  };
                  const newBets = [...bets, bet];
                  setBets(newBets);
                  const betString = JSON.stringify(newBets);
                  window.localStorage.setItem("bets", betString);

                  /** Display Error Toast if bet slip is greater than 25 */
                  if (newBets.length > 25) {
                    toast({
                      title: `Error`,
                      description: "Only 25 Bets Allowed On A Ticket",
                      position: "bottom-right",
                      status: "error",
                      isClosable: true,
                    });
                  }
                }}
              />
            </Box>
          </Box>
        );
      });

      return (
        <Box
          ref={(league?.id ?? "") === leagueId ? ref : null}
          key={league?.id ?? ""}
        >
          <Box
            h="68px"
            w="100%"
            display="flex"
            alignItems="center"
            background={`${org?.secondaryColor}30 0% 0% no-repeat padding-box`}
            boxShadow="inset 0px 4px 4px #00000029"
          >
            <Text color="#000000" fontWeight="bold" ml="40px">
              {league?.name ?? ""}
            </Text>
          </Box>
          <Box>{eventRows}</Box>
        </Box>
      );
    }
  );

  const sportName = events && events.length > 0 ? events[0].sport.name : "";

  return (
    <Box>
      <Box flexDirection="row" display="flex" overflowY="scroll" h="100vh">
        <Sidebar display={{ base: "none", "2xl": "flex" }} />
        <Box flex="1" pb="0" w="0px" style={{ position: "relative" }}>
          <Box minH="100vh">
            <Box p={2} w="100%">
              <AdCarousel bets={bets} setBets={setBets} />
            </Box>

            <Flex pl="4" alignItems="center" h="5rem" hideBelow="sm">
              <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                {sportName.charAt(0).toUpperCase() + sportName.slice(1)}{" "}
              </Text>
            </Flex>
            <Box p={4}>
              <HStack spacing={4} mb={4} gap="6">
                <Button
                  width="50%"
                  variant="outline"
                  color={"#000"}
                  borderColor="#000"
                  isDisabled
                >
                  Live
                </Button>
                <Button
                  width="50%"
                  variant="solid"
                  backgroundColor="#000"
                  color={"#fff"}
                >
                  Upcoming
                </Button>
              </HStack>
            </Box>
            {sports && sports.length > 0 && (
              <Box
                p="2"
                display="flex"
                alignItems="center"
                overflowX="auto"
                overflowY="hidden"
              >
                <HStack spacing={2}>
                  {sports.map((sport) => (
                    <Button
                      key={sport.id}
                      size="sm"
                      variant="solid"
                      backgroundColor={
                        sportFilters === sport.id ? "#469B49" : "gray.200"
                      }
                      color="black"
                      borderRadius="md"
                      _hover={{ bg: "gray.300" }}
                      onClick={() => {
                        setSportFilters(sport.id);
                        history.push(`/?sportId=${sport.id}`);
                      }}
                      leftIcon={
                        <Image
                          src={`${sport.image}`}
                          alt={sport.name}
                          boxSize="16px"
                        />
                      }
                    >
                      {sport.name}
                    </Button>
                  ))}
                </HStack>
              </Box>
            )}

            {loading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30%",
                  height: "100%",
                }}
              >
                <BBSpinner />
              </div>
            )}

            {leagueComponents.length !== 0 && (
              <Box
                borderRadius="0px 0px 5px 5px"
                maxW="100%"
                position="relative"
                overflowY="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {leagueComponents}
              </Box>
            )}
            {!loading && events && events.length === 0 && (
              <Box mt="20" p="10" background="white" h="50vh">
                <Center>
                  <Box>
                    <Image src="/event.png" w="141px" />
                  </Box>
                </Center>
                <Center mt="5">
                  <Alert colorScheme="whiteAlpha" width="auto">
                    <AlertIcon color="#FCD28D" />
                    <Center>
                      <Text as="b" align="center" color="black">
                        {strings[language].sportsbook.noEventsSport}!
                      </Text>
                    </Center>
                  </Alert>
                </Center>
              </Box>
            )}

            {/* Pagination Controls */}
            {!loading && totalPages > 1 && (
              <Box mt={4} display="flex" justifyContent="center">
                <Button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 1))
                  }
                  isDisabled={page === 1}
                >
                  Previous
                </Button>
                <Text mx={2} alignSelf="center">
                  Page {page} of {totalPages}
                </Text>
                <Button
                  onClick={() =>
                    setPage((prevPage) => Math.min(prevPage + 1, totalPages))
                  }
                  isDisabled={page === totalPages}
                >
                  Next
                </Button>
              </Box>
            )}
          </Box>
          <Box mb="60px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          zIndex={1}
          hideBelow="lg"
        >
          <BetSlip
            placeBetLoading={placeBetLoading}
            setPlaceBetLoading={setPlaceBetLoading}
            bets={bets}
            setBets={setBets}
            placeBet={placeBet}
          />
        </Box>
      </Box>
      <MobileBetSlip
        placeBetLoading={placeBetLoading}
        setPlaceBetLoading={setPlaceBetLoading}
        bets={bets}
        setBets={setBets}
        placeBet={placeBet}
        collapseSlip={props.collapseSlip}
        setCollapseSlip={props.setCollapseSlip}
      />
      {oddsChangeData && (
        <OddsChangeModal
          isOpen={isOpen}
          onClose={onClose}
          oddsChangeData={oddsChangeData}
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
        />
      )}
    </Box>
  );
}
