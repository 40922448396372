import { Button, ButtonGroup } from "@chakra-ui/button";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Box, Center, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import {
  SimpleGrid,
  FormControl,
  FormLabel,
  useToast,
  Image,
} from "@chakra-ui/react";
import { Card } from "components/shared/Card";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";
import {
  denominationSymbolState,
  languageState,
  orgState,
  userState,
} from "store/Store";
import APIService from "services/API";
import { DepositFundsDTO } from "models";
import { LanguageKey, strings } from "utils/languageStrings";

export const Deposit = () => {
  const user = useRecoilValue(userState);
  const org = useRecoilValue(orgState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const [cardholderName, setCardholderName] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const [updatedBalance, setUpdatedBalance] = useState(0);
  const [stripeTransId, setStripeTransId] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [success, setSuccess] = useState(false);

  const history = useHistory();
  const toast = useToast();

  const { register, handleSubmit, setValue } = useForm<DepositFundsDTO>({
    mode: "onChange",
  });

  const { mutate, isLoading } = useMutation(APIService.chargeDeposit, {
    onSuccess: (data) => {
      console.log(data);
      setStripeTransId(data.transaction.stripeTransId);
      setUpdatedBalance(data.transaction.user.balance);
      setSuccess(true);
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Error Depositng Funds",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (user) {
      setCardholderName(user.firstName + " " + user.lastName);
    }
  }, []);

  const onSubmit = (data: DepositFundsDTO) => {
    // Convert dollars to cents
    let convertedAmount = depositAmount * 100;

    const payment = {
      ...data,
      amount: convertedAmount,
    };
    mutate(payment);
  };

  return (
    <Box
      bg="#F6F6F6"
      h="90vh"
      pt={{ base: 0, md: 6 }}
      pb={{ base: 14, md: 6 }}
      overflowY="auto"
    >
      {!success ? (
        <Box>
          <Box>
            <Text color="black" fontSize="4xl" textAlign="center">
              {strings[language].depositForm.deposit}
            </Text>
          </Box>

          <Box p="2">
            <Box textAlign="center" pb="5">
              <Text color="black" fontSize="lg">
                {strings[language].depositForm.depositSelector}
              </Text>
            </Box>

            <Divider />

            <Center>
              <Stack spacing={3}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Center>
                    <Card bg="white" w="55%" minWidth="350px">
                      <ButtonGroup size="lg" isAttached>
                        <SimpleGrid
                          columns={{ base: 3, md: 5 }}
                          columnGap={{ base: 2, md: 0 }}
                          spacing={{ base: 2, md: 0 }}
                        >
                          <Button
                            color="black"
                            _hover={{ bgColor: org?.primaryColor }}
                            variant={
                              depositAmount === 10 && inputFocused === false
                                ? "solid"
                                : "outline"
                            }
                            onClick={() => setDepositAmount(10)}
                          >
                            10
                          </Button>
                          <Button
                            color="black"
                            _hover={{ bgColor: org?.primaryColor }}
                            variant={
                              depositAmount === 25 && inputFocused === false
                                ? "solid"
                                : "outline"
                            }
                            onClick={() => setDepositAmount(25)}
                          >
                            25
                          </Button>
                          <Button
                            color="black"
                            _hover={{ bgColor: org?.primaryColor }}
                            variant={
                              depositAmount === 100 && inputFocused === false
                                ? "solid"
                                : "outline"
                            }
                            onClick={() => setDepositAmount(100)}
                          >
                            100
                          </Button>
                          <Button
                            color="black"
                            _hover={{ bgColor: org?.primaryColor }}
                            variant={
                              depositAmount === 250 && inputFocused === false
                                ? "solid"
                                : "outline"
                            }
                            onClick={() => setDepositAmount(250)}
                          >
                            250
                          </Button>
                          <Button
                            color="black"
                            w="110px"
                            _hover={{ bgColor: org?.primaryColor }}
                            variant={inputFocused ? "solid" : "outline"}
                            onClick={() =>
                              inputRef.current !== null &&
                              inputRef.current.focus()
                            }
                          >
                            {strings[language].depositForm.custom}
                          </Button>
                        </SimpleGrid>
                      </ButtonGroup>
                      <InputGroup mt="4">
                        <InputLeftElement
                          pointerEvents="none"
                          color="black"
                          fontSize="1.2em"
                          children={denominationSymbol}
                        />
                        <Input
                          borderColor="black"
                          _hover={{ border: "1px solid black" }}
                          color="black"
                          placeholder="Enter custom amount"
                          _placeholder={{ color: "inherit" }}
                          ref={inputRef}
                          onFocus={() => setInputFocused(true)}
                          value={depositAmount}
                          onChange={(e) => setDepositAmount(+e.target.value)}
                        />
                      </InputGroup>
                    </Card>
                  </Center>
                  <Box>
                    <Text color="black" textAlign="center" my="2">
                      {strings[language].depositForm.statement1}
                    </Text>
                  </Box>
                  <Center>
                    <Card bg="white" w="55%" minWidth="350px">
                      <Center mb="2">
                        <Image
                          src="/icons/cc-visa.svg"
                          h="30px"
                          display="inline-block"
                          mx={1}
                        />
                        <Image
                          src="/icons/cc-amex.svg"
                          h="30px"
                          display="inline-block"
                          mx={1}
                        />
                        <Image
                          src="/icons/cc-mastercard.svg"
                          h="30px"
                          display="inline-block"
                          mx={1}
                        />
                      </Center>
                      <FormControl isRequired>
                        <InputGroup display="block">
                          <SimpleGrid columns={2} spacing={2}></SimpleGrid>
                          <FormLabel color="black">
                            {strings[language].depositForm.name}
                          </FormLabel>
                          <Input
                            borderColor="black"
                            _hover={{ border: "1px solid black" }}
                            color="black"
                            _placeholder={{ color: "inherit" }}
                            value={cardholderName}
                            onChange={(e) => setCardholderName(e.target.value)}
                          />
                          <FormLabel color="black">
                            {strings[language].depositForm.number}
                          </FormLabel>
                          <Input
                            borderColor="black"
                            _hover={{ border: "1px solid black" }}
                            color="black"
                            _placeholder={{ color: "inherit" }}
                            {...register("createPaymentMethodInfo.number")}
                          />
                          <SimpleGrid columns={2} spacing={2}>
                            <Box>
                              <FormLabel color="black">
                                {strings[language].depositForm.expiryDate}
                              </FormLabel>
                              <SimpleGrid columns={2} spacing={2}>
                                <Input
                                  borderColor="black"
                                  _hover={{ border: "1px solid black" }}
                                  color="black"
                                  _placeholder={{ color: "inherit" }}
                                  placeholder="MM"
                                  {...register(
                                    "createPaymentMethodInfo.exp_month"
                                  )}
                                />
                                <Input
                                  borderColor="black"
                                  _hover={{ border: "1px solid black" }}
                                  color="black"
                                  _placeholder={{ color: "inherit" }}
                                  placeholder="YY"
                                  {...register(
                                    "createPaymentMethodInfo.exp_year"
                                  )}
                                />
                              </SimpleGrid>
                            </Box>
                            <Box>
                              <FormLabel color="black">
                                {strings[language].depositForm.securityCode}
                              </FormLabel>
                              <Input
                                borderColor="black"
                                _hover={{ border: "1px solid black" }}
                                color="black"
                                _placeholder={{ color: "inherit" }}
                                {...register("createPaymentMethodInfo.cvc")}
                              />
                            </Box>
                          </SimpleGrid>
                          <Box mt="4">
                            <Text
                              color={org?.secondaryColor}
                              textAlign="center"
                              fontSize="xl"
                              fontWeight="semibold"
                            >
                              {denominationSymbol + depositAmount.toFixed(2)}
                            </Text>
                            <Text
                              color="black"
                              textAlign="center"
                              fontSize="12px"
                            >
                              {strings[language].depositForm.statement2}
                            </Text>
                          </Box>
                          <Box mt="6">
                            <Button
                              type="submit"
                              bgColor={org?.secondaryColor}
                              color="white"
                              _hover={{ bgColor: org?.primaryColor }}
                              w="100%"
                            >
                              {strings[language].depositForm.button}
                            </Button>
                          </Box>
                        </InputGroup>
                      </FormControl>
                    </Card>
                  </Center>
                </form>
              </Stack>
            </Center>
          </Box>
        </Box>
      ) : (
        <Box bg="#F6F6F6" pt={{ base: 4, md: 20 }}>
          <Card bg="white" w="50%" mx="auto" minWidth="350px">
            <Box pt="4">
              <Text color="black" fontSize="4xl" textAlign="center">
                {strings[language].depositSuccess.depositSuccessful}
              </Text>
            </Box>

            <Box p="2" mt="6">
              <Flex justify="center" pb="5">
                <AiOutlineCheckCircle size="6em" color="green" />
              </Flex>

              <Center>
                <Stack spacing={6} textAlign="center">
                  <Box>
                    <Text color="black" display="inline">
                      <Text
                        fontWeight="bold"
                        color="black"
                        display="inline"
                      >{`${
                        denominationSymbol + depositAmount.toFixed(2)
                      } `}</Text>
                      {strings[language].depositSuccess.depositAmount}
                    </Text>
                  </Box>
                  <Box>
                    <Text color="black" display="inline">
                      {strings[language].depositSuccess.accountBalance}
                      <Text
                        fontWeight="bold"
                        color="black"
                        display="inline"
                      >{` ${
                        denominationSymbol + updatedBalance.toFixed(2)
                      }`}</Text>
                    </Text>
                  </Box>
                  <Box>
                    <Text color="black" display="inline">
                      {strings[language].depositSuccess.referenceNumber}
                      <Text color="black" fontWeight="bold" display="inline">
                        {` ${stripeTransId}`}
                      </Text>
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      _hover={{ bgColor: org?.primaryColor }}
                      w="100%"
                      onClick={() => history.push("/")}
                    >
                      {strings[language].depositSuccess.continue}
                    </Button>
                  </Box>
                </Stack>
              </Center>
            </Box>
          </Card>
        </Box>
      )}
    </Box>
  );
};
