import {
  Box,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import API from "services/API";
import { userState } from "store/Store";
import { LoginForm } from "./LoginForm";

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ValidateLoginToken = () => {
  const [user, setUser] = useRecoilState(userState);
  const [loading, setLoading] = useState(false);
  const [tokenValidated, setTokenValidated] = useState(false);
  const toast = useToast();
  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();

  let query = useQueryParams();

  const token = query.get("token");
  const kioskId = query.get("kioskId");

  const getProfileData = useQuery("getProfile", API.getProfile, {
    retry: false,
  });

  const { mutate } = useMutation(API.validateLoginToken, {
    onSuccess: (data) => {
      setLoading(false);
      setTokenValidated(true);
    },
    onError: () => {
      setLoading(false);
      toast({
        title: "Error",
        description:
          "Could not verify token, please return to kiosk and retry.",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });
    },
  });

  if (getProfileData.isSuccess) {
    setUser(getProfileData.data);
  }

  useEffect(() => {
    if (user?.id) {
      onLoginClose();
      if (token && kioskId) {
        setLoading(true);
        mutate({ token, kioskId });
      }
    } else {
      onLoginOpen();
    }
  }, [user]);

  return (
    <Flex bg="white" minH="100vh" pt="10%">
      {tokenValidated ? (
        <Box maxW="md" mx="auto">
          <img
            src="/Bet-Booth-Stacked-Logo.png"
            style={{
              width: "213px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <Center>
            <Stack textAlign="center">
              <Heading m={4}>Successfully Validated Login Token!</Heading>
              <Text fontSize="2xl">Please return to kiosk.</Text>
            </Stack>
          </Center>
        </Box>
      ) : (
        <Box maxW="md" mx="auto">
          <img
            src="/Bet-Booth-Stacked-Logo.png"
            style={{
              width: "213px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <Heading m={4}>Validate Login Token</Heading>
          {loading && (
            <Center p={4}>
              <Stack>
                <Text fontSize="2xl">Validating Token</Text>
                <Spinner color="black" m="auto" mt={4} size="xl" />
              </Stack>
            </Center>
          )}
        </Box>
      )}
      <Modal isOpen={isLoginOpen} onClose={onLoginClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Log In</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="6">
            <LoginForm admin={false} validateLoginToken={true} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ValidateLoginToken;
