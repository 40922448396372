import {
  Box,
  HStack,
  Show,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Bet } from "models/Bets";
import { BettingLineDTO } from "models/BettingLine";
import { FantasyBettingLine } from "models/Fantasy";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useRecoilState, useRecoilValue } from "recoil";
import API from "services/API";
import { fantasyBetsStore, oddsDisplayState, orgState } from "store/Store";
import baseballAd from "../dashboard/assets/baseballAd.jpg";
import basketballAd from "../dashboard/assets/basketballAd.jpg";
import boxingAd from "../dashboard/assets/boxingAd.jpg";
import footballAd from "../dashboard/assets/footballAd.jpg";
import golfAd from "../dashboard/assets/golfAd.jpg";
import hockeyAd from "../dashboard/assets/hockeyAd.jpg";
import otherAd from "../dashboard/assets/otherAd.jpg";
import soccerAd from "../dashboard/assets/soccerAd.jpg";
import tennisAd from "../dashboard/assets/tennisAd.jpg";
import { relativeTime } from "utils/dateCalcs";

interface AdCarouselProps {
  bets: Bet[];
  setBets: React.Dispatch<React.SetStateAction<Bet[]>>;
}

export const AdCarouselFantasy = (props: AdCarouselProps) => {
  const [bets, setBets] = useState<Bet[]>([]);
  const org = useRecoilValue(orgState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const [fantasyLines, setFantasyLines] = useState<FantasyBettingLine[] | null>(
    null
  );
  const [fantasyBets, setFantasyBets] = useRecoilState(fantasyBetsStore);

  const toast = useToast();

  const imgSrc = {
    baseball: baseballAd,
    golf: golfAd,
    fighting: boxingAd,
    tennis: tennisAd,
    soccer: soccerAd,
    football: footballAd,
    basketball: basketballAd,
    hockey: hockeyAd,
    other: otherAd,
  };

  let slide: JSX.Element[] = [];

  const selectFantasyLine = (
    newBet: BettingLineDTO,
    startTime: string,
    awayTeam: string
  ) => {
    if (fantasyBets.some((bet) => bet.id === newBet.id)) {
      setFantasyBets(fantasyBets.filter((bet) => bet.id !== newBet.id));
    } else {
      const updatedBet = {
        ...newBet,
        startTime: startTime,
        awayTeam: awayTeam,
      };
      setFantasyBets([...fantasyBets, updatedBet]);
    }
  };

  useEffect(() => {
    setBets(props.bets);
  }, [props.bets]);

  const fetchFantasyLines = async () => {
    const resp = await API.getUpcomingFantasyEvents();

    let fantasyLines: FantasyBettingLine[] = [];
    let count = 0;

    for (let bbEvent of resp ?? []) {
      for (let line of bbEvent.bettingLines) {
        if (line.paramParticipant1 && line.paramParticipant1.imageUrl) {
          if (count === 10) break;

          fantasyLines.push({
            bettingLine: line,
            event: bbEvent,
          } as FantasyBettingLine);

          count++;
        }
      }
      if (count === 10) break;
    }

    setFantasyLines(fantasyLines);
  };

  useEffect(() => {
    fetchFantasyLines();
  }, []);

  slide = fantasyLines?.reduce((result: any, line: FantasyBettingLine) => {
    let playerTeamAbbreviation =
      line.bettingLine.paramParticipant1.abbreviation;
    let opponentTeamAbbreviation = "";
    let eventName = line.event.name;

    let teams = eventName.split(" vs ");

    // Check which team the player is playing for and assign the opponent team abbreviation
    if (playerTeamAbbreviation === teams[0]) {
      opponentTeamAbbreviation = teams[1];
    } else if (playerTeamAbbreviation === teams[1]) {
      opponentTeamAbbreviation = teams[0];
    }

    result.push(
      <div key={line.bettingLine.id}>
        <Box
          cursor="pointer"
          onClick={() => {
            selectFantasyLine(
              line.bettingLine,
              line.event.startTime,
              opponentTeamAbbreviation
            );
          }}
          m={1}
          border={`1px ${org?.secondaryColor} solid`}
          borderRadius="md"
          style={{
            background: `linear-gradient(90deg, rgba(0,0,0,1) 24%, rgba(0,0,0,0.7344187675070029) 44%, rgba(0,0,0,0) 100%), url(${line.bettingLine.paramParticipant1.imageUrl})`,
            backgroundSize: "cover",
          }}
          h="170"
          color="white"
        >
          <Stack p="2" spacing={0}>
            <Box>
              <Text
                textAlign="start"
                fontWeight="semibold"
                textShadow="0 2px 3px rgba(0, 0, 0, 0.3)"
              >
                {line.bettingLine.paramParticipant1.name}
              </Text>
            </Box>
            <Box>
              <Text
                textAlign="start"
                fontWeight="semibold"
                textShadow="0 2px 3px rgba(0, 0, 0, 0.3)"
              >
                NBA {line.bettingLine.paramParticipant1.abbreviation} -{" "}
                {line.bettingLine.paramParticipant1.position}
              </Text>
            </Box>
            <Box>
              <Text
                textAlign="start"
                fontWeight="semibold"
                textShadow="0 2px 3px rgba(0, 0, 0, 0.3)"
              >
                vs{" "}
                {
                  line.event.eventParticipants.find(
                    (x) =>
                      x.participant.abbreviation !==
                      line.bettingLine.paramParticipant1.abbreviation
                  )?.participant.abbreviation
                }
              </Text>
            </Box>
            <Box>
              <Text
                textAlign="start"
                fontWeight="semibold"
                textShadow="0 2px 3px rgba(0, 0, 0, 0.3)"
              >
                {relativeTime(line.event.startTime, "en")}
              </Text>
            </Box>
          </Stack>
          <SimpleGrid
            bg="transparent"
            pr="2"
            pl="1"
            pb="2"
            mb="4"
            w="98%"
            position="absolute"
            bottom={0}
            columns={2}
            spacing={0}
          >
            <Box
              color="white"
              bg={org?.secondaryColor}
              p="2"
              borderRadius="10px"
              fontSize="14px"
            >
              <HStack spacing="10px">
                <Spacer />
                <Text>{line.bettingLine.paramFloat1.toFixed(1)}</Text>
                <Text color={org?.primaryColor}>|</Text>
                <Text>{line.bettingLine.betCategory}</Text>
                <Spacer />
              </HStack>
            </Box>
          </SimpleGrid>
        </Box>
      </div>
    );

    return result;
  }, []);

  return (
    <>
      <Show above="md">
        <Carousel
          key={1}
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          showIndicators={false}
          stopOnHover
          autoPlay
          infiniteLoop
          centerMode
          centerSlidePercentage={33}
        >
          {slide}
        </Carousel>
      </Show>
      <Box display={{ base: "none", sm: "block", md: "none" }}>
        <Carousel
          key={2}
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          showIndicators={false}
          stopOnHover
          autoPlay
          infiniteLoop
          centerMode
          centerSlidePercentage={50}
        >
          {slide}
        </Carousel>
      </Box>
      <Show below="sm">
        <Carousel
          key={3}
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          showIndicators={false}
          stopOnHover
          autoPlay
          infiniteLoop
          centerMode
          centerSlidePercentage={100}
        >
          {slide}
        </Carousel>
      </Show>
    </>
  );
};
