import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  FormControl,
  HStack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  denominationSymbolState,
  fantasyBetsStore,
  languageState,
  orgState,
  userState,
} from "store/Store";
import { currencyFormatter } from "utils/formatters";
import { strings, LanguageKey } from "utils/languageStrings";
import { PlaceFantasyBetDTO, PlaceFantasyBetTicketDTO } from "models/Fantasy";
import API from "services/API";

interface ConfirmBetModalProps {
  isOpen: boolean;
  cancelRef: any;
  betConfirm: JSX.Element[];
  fantasyRisk: string;
  fantasyWin: string;
  onClose: () => void;
  setCollapseSlip?: Dispatch<SetStateAction<boolean>>;
}

export default function ConfirmBetModal({
  isOpen,
  cancelRef,
  betConfirm,
  fantasyRisk,
  fantasyWin,
  onClose,
  setCollapseSlip,
}: ConfirmBetModalProps) {
  const [fantasyBets, setFantasyBets] = useRecoilState(fantasyBetsStore);
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const [confirmInput, setConfirmInput] = useState<boolean>(false);
  const org = useRecoilValue(orgState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const [user, setUser] = useRecoilState(userState);
  const toast = useToast();
  var taxRate = 0;

  if (user?.org) {
    taxRate = user?.org.taxRate;
  }

  var fantasyTaxAmount = taxRate * +fantasyRisk;
  var fantasyTotal = fantasyTaxAmount + +fantasyRisk;

  const fetchProfile = () => {
    API.getProfile().then((data) => {
      setUser(data);
    });
  };

  const betModalTimeout = () => {
    // Timeout the bet modal after 5 minutes if no bet placed.
    setTimeout(() => {
      onClose();
      setConfirmInput(false);
    }, 300000);
  };

  const placeBet = (data: PlaceFantasyBetTicketDTO) => {
    setPlaceBetLoading(true);
    API.placeFantasyBet(data)
      .then((data) => {
        setFantasyBets([]);

        fetchProfile();

        toast({
          title: `Bet successfully placed!`,
          position: "bottom-right",
          status: "success",
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: `Error placing bet`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        if (setCollapseSlip) {
          setCollapseSlip(true);
        }
        onClose();
        setPlaceBetLoading(false);
      });
  };

  const clickPlaceBet = () => {
    setPlaceBetLoading(true);

    var dto: PlaceFantasyBetTicketDTO = {
      bets: [],
      riskAmount: +fantasyRisk,
      ticketType: "Fantasy",
    };

    dto.bets = fantasyBets.map((bet) => {
      var dto: PlaceFantasyBetDTO = {
        bettingLineId: bet.id,
        externalId: bet.externalId,
        fantasyOver: bet.fantasyOver!,
      };

      return dto;
    });

    placeBet(dto);
  };

  useEffect(() => {
    betModalTimeout();
  }, []);

  return (
    <Box pb="2" minWidth="500px">
      <AlertDialog
        size="xl"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        closeOnOverlayClick={false}
        onClose={onClose}
      >
        <AlertDialogOverlay backdropFilter="blur(10px)">
          <AlertDialogContent mt="8vh" bg="whiteAlpha.900">
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="black">
              Review&nbsp;
              <Text as={"span"} color={org?.primaryColor}>
                Fantasy
              </Text>
              &nbsp;Ticket
            </AlertDialogHeader>

            <AlertDialogBody>
              <Table size={{ base: "xs", sm: "sm", md: "md" }}>
                <Thead>
                  <Tr>
                    <Th>
                      <Text color="#000000">
                        {strings[language].confirmBetModal.name}
                      </Text>
                    </Th>
                    <Th>
                      <Text color="#000000">Value</Text>
                    </Th>
                    <Th>
                      <Text color="#000000">More/Less</Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{betConfirm}</Tbody>
              </Table>
              <>
                <Divider w="100%" mb="5" borderColor="#000000" />
                <VStack mb="20px" px={{ base: 0, sm: 4, md: 6 }}>
                  {taxRate == 0 && (
                    <HStack w="100%" justify={"space-between"}>
                      <Text color="#000000" fontWeight="bold">
                        Entry
                      </Text>
                      <Text color="#000000">
                        {denominationSymbol +
                          currencyFormatter.format(+fantasyRisk)}
                      </Text>
                    </HStack>
                  )}
                  {taxRate > 0 && (
                    <>
                      <HStack w="100%" justify={"space-between"}>
                        <Text color="#000000" fontWeight="bold">
                          {strings[language].confirmBetModal.subTotal}
                        </Text>
                        <Text color="#000000">
                          {denominationSymbol +
                            currencyFormatter.format(+fantasyRisk)}
                        </Text>
                      </HStack>
                      <HStack w="100%" justify={"space-between"}>
                        <Text color="#000000" fontWeight="bold">{`Taxes (${
                          taxRate * 100
                        }%)`}</Text>
                        <Text color="#000000">
                          {denominationSymbol +
                            currencyFormatter.format(fantasyTaxAmount)}
                        </Text>
                      </HStack>
                      <HStack w="100%" justify={"space-between"}>
                        <Text color="#000000" fontWeight="bold">
                          {strings[language].confirmBetModal.total}
                        </Text>
                        <Text color="#000000">
                          {denominationSymbol +
                            currencyFormatter.format(fantasyTotal)}
                        </Text>
                      </HStack>
                    </>
                  )}
                  <HStack w="100%" justify={"space-between"}>
                    <Text color="#000000" fontWeight="bold">
                      To Win
                    </Text>
                    <Text color="#000000">
                      {denominationSymbol +
                        currencyFormatter.format(+fantasyWin)}
                    </Text>
                  </HStack>
                </VStack>
              </>

              <Divider mb="5" borderColor="black" />
            </AlertDialogBody>

            <AlertDialogBody>
              {
                <Box>
                  <Center>
                    <Text color="#000000" mb="5">
                      Are you sure you want to place this entry?
                    </Text>
                  </Center>

                  <FormControl>
                    <Center>
                      <Checkbox
                        isRequired
                        size={"lg"}
                        defaultChecked={confirmInput}
                        onChange={(e) => {
                          setConfirmInput(!confirmInput);
                        }}
                      >
                        <Text color="#000000">
                          {strings[language].confirmBetModal.confirm}
                        </Text>
                      </Checkbox>
                    </Center>
                  </FormControl>
                </Box>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                color="#000000"
                onClick={() => {
                  onClose();
                  setConfirmInput(false);
                }}
              >
                {strings[language].confirmBetModal.cancel}
              </Button>
              <Button
                _hover={{ background: org?.primaryColor, opacity: 0.5 }}
                isDisabled={!confirmInput}
                color="white"
                background={org?.primaryColor}
                onClick={() => {
                  clickPlaceBet();
                }}
                ml={3}
                w="200px"
              >
                Place Entry
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
