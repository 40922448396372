import { Spinner } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { orgState } from "store/Store";

function BBSpinner() {
  const org = useRecoilValue(orgState);

  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color={org?.primaryColor}
      size="xl"
    />
  );
}

export default BBSpinner;
